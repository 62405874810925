import { ValidatorFn, AbstractControl } from '@angular/forms';

export function ValidateUniqueName(uniqueRegex: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        try {
            if (!uniqueRegex.test(control.value)) {
                return null;
            }
            return {
                notUnique: true,
            };
        } catch (err) {
            return null;
        }
    };
}
