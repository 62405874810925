import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/configs/constants';
import { SDWanGatewayUtilService } from 'app/modules/sd-wan-gateway/common/services/sd-wan-gateway.util.service';

@Injectable({
    providedIn: 'root',
})
export class AlarmstatusService {
    endpointToUse: any;
    selectedConfiguration: any;
    deviceId;

    constructor(
        private http: HttpClient,
        private router: Router,
        private globalConstants: GlobalConstants,
        private sdWanGatewayUtilService: SDWanGatewayUtilService,
    ) {}

    getDeviceStatus() {
        if (this.router.url.includes('/organization-monitor/alarms')) {
            this.endpointToUse =
                this.globalConstants.status_grafana +
                this.deviceId +
                this.globalConstants.STATUS_GRAFANA_ALERT_HISTORY_DEVICE_REACHABILITY;
        } else {
            this.endpointToUse =
                this.globalConstants.status_grafana +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalConstants.STATUS_GRAFANA_ALERT_HISTORY_DEVICE_REACHABILITY;
        }
        return this.http.get(`${this.endpointToUse}`);
    }

    getWanStatus() {
        if (this.router.url.includes('/organization-monitor/alarms')) {
            this.endpointToUse =
                this.globalConstants.status_grafana +
                this.deviceId +
                this.globalConstants.STATUS_GRAFANA_ALERT_HISTORY_WAN_REACHABILITY;
        } else {
            this.endpointToUse =
                this.globalConstants.status_grafana +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalConstants.STATUS_GRAFANA_ALERT_HISTORY_WAN_REACHABILITY;
        }
        return this.http.get(`${this.endpointToUse}`);
    }
    getIPSecStatus() {
        if (this.router.url.includes('/organization-monitor/alarms')) {
            this.endpointToUse =
                this.globalConstants.status_grafana +
                this.deviceId +
                this.globalConstants.STATUS_GRAFANA_ALERT_HISTORY_IPSEC_REACHABILITY;
        } else {
            this.endpointToUse =
                this.globalConstants.status_grafana +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalConstants.STATUS_GRAFANA_ALERT_HISTORY_IPSEC_REACHABILITY;
        }
        return this.http.get(`${this.endpointToUse}`);
    }
    getPerformanceStatus() {
        if (this.router.url.includes('/organization-monitor/alarms')) {
            this.endpointToUse =
                this.globalConstants.status_grafana +
                this.deviceId +
                this.globalConstants.STATUS_GRAFANA_ALERT_HISTORY_PERFORMANCE_REACHABILITY;
        } else {
            this.endpointToUse =
                this.globalConstants.status_grafana +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalConstants.STATUS_GRAFANA_ALERT_HISTORY_PERFORMANCE_REACHABILITY;
        }
        return this.http.get(`${this.endpointToUse}`);
    }

    getInterfaceList() {
        if (this.router.url.includes('/organization-monitor/alarms')) {
            this.endpointToUse =
                this.globalConstants.device_grafana +
                this.deviceId +
                this.globalConstants.DEVICE_GRAFANA_INTERFACE_LIST;
        } else {
            this.endpointToUse =
                this.globalConstants.device_grafana +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalConstants.DEVICE_GRAFANA_INTERFACE_LIST;
        }
        return this.http.get(this.endpointToUse);
    }
}
