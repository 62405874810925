<div style="width: 100%;">
    <form [formGroup]="form">
        <small (click)="clearAll()" *ngIf="DataAndValidity.data.length > 1">Clear All</small>
        <mat-form-field style="width: 100%;">
            <mat-chip-list [disabled]="inpDisabled"
                style="max-height: 150px; min-height: 50px; overflow-y: auto; overflow-x: hidden;"
                class="form-control form-control-sm col-11" [ngClass]="{
                    'is-invalid':
                        (form.controls.data.invalid || requiredValidator) &&
                        (form.controls.data.touched || form.controls.data.dirty)
                }" #chipList1>
                <mat-chip style="margin-top: 3px;" *ngFor="let ip of DataAndValidity.data" [removable]="true"
                    (removed)="remove_item(ip)" required>
                    {{ ip }}
                    <mat-icon matChipRemove> cancel</mat-icon>
                </mat-chip>
                <input formControlName="data" [id]="uniqueName" [matChipInputFor]="chipList1"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add_item($event)" [placeholder]="placeholder" />
            </mat-chip-list>
        </mat-form-field>
    </form>
</div>