<style>
  .chartContainer {
      background-color: #fff;
      box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
      padding:20px;
  }

  canvas {
      margin: auto;
      width: 100% !important;
      height: 42vh !important;
  }
</style>


<div class="chartConatiner">
      <!-- <div *ngIf="loading" class="loaderRound "> </div> -->
  <div *ngIf="isEmpty" style="margin: auto; height: 12vh;">
      <p style="margin: 5vh !important; height: 100%">
          No data available yet!
      </p>
  </div>
  <!-- <div *ngIf="!isEmpty"> -->
    <div>
      <canvas [id]="htmlId"></canvas>
    </div>
  <!-- </div> -->
</div>