export const AVAILABLE_DASHBOARD_ACTION = 'AVAILABLE_DASHBOARD_ACTION'

export interface availableDashboardAction {
    type: typeof AVAILABLE_DASHBOARD_ACTION,
    payload: any;
}

export function createAction(payload: any): availableDashboardAction {
    return {
        type: AVAILABLE_DASHBOARD_ACTION,
        payload,
    }
}