import { Action } from '@ngrx/store';
import { DeviceList } from 'app/shared/model/device.list.model';
import { DeviceFilterType } from 'app/store/state/dashboard.state';

export const SetFilter = 'Set Filter';
export class SET_FILTER implements Action {
    readonly type = SetFilter;
    constructor(public filter: DeviceFilterType) {}
}

export type Actions = SET_FILTER;
