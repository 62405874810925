import { UserService } from 'app/shared/services/core/user.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { Subscription } from 'rxjs';
import { ChatService } from 'app/shared/services/comp';

@Component({
    selector: 'cosgrid-live-chat-support',
    templateUrl: './live-chat-support.component.html',
    styleUrls: ['./live-chat-support.component.scss'],
})
export class LiveChatSupportComponent implements OnInit, OnDestroy {
    constructor(
        private chat: ChatService,
        private dialogRef: MatDialogRef<LiveChatSupportComponent>,
        private dialog: MatDialog,
        private customerList: CustomerListService,
        private userService: UserService,
    ) {}
    subscription: Subscription;
    inputMessage = '';
    tenantID;
    loading = true;
    permissionGranted = false;
    channelInfo: any = {
        t_info: {
            ...this.userService.getUser(),
            t_name: this.customerList.getTenantName(),
            t_id: this.customerList.getTenantId().toString(),
        },
        messages: [
            {
                user: 'SuperAdmin',
                from: 'Support',
                body: 'Welcome to Live Chat Support.',
                source: 'received',
                timestamp: Date.now(),
            },
        ],
    };
    ngOnInit() {
        this.checkPermission();
        this.tenantID = this.customerList.getTenantId().toString();
        this.getMessages();
    }

    checkPermission() {
        this.permissionGranted = this.chat.checkPermission();
    }

    getMessages() {
        this.loading = true;
        this.chat.getMessages(this.tenantID).subscribe((res) => {
            if (res.exists) {
                this.loading = false;
                this.channelInfo = res.data();
                this.subscribeToChange();
            } else {
                this.chat.createChat(this.tenantID, this.channelInfo).then((res) => {
                    this.loading = false;
                    this.subscribeToChange();
                });
            }
        });
    }

    subscribeToChange() {
        try {
            this.subscription = this.chat.channelUpdated(this.tenantID).subscribe((res) => {
                this.channelInfo = res;

                this.chat.goToLatestChat();
            });
        } catch (err) {}
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    closeChatBox() {
        this.dialogRef.close();
    }

    sendMessage() {
        if (this.inputMessage !== '') {
            this.channelInfo.messages.push({
                user: this.userService.getUser().userName,
                from: this.customerList.getTenantName(),
                body: this.inputMessage,
                source: 'sent',
                timestamp: Date.now(),
            });
            this.inputMessage = '';
            setTimeout(() => {
                const chatBox = document.querySelector('.chatBox');
                chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight;
            }, 50);
        }
        this.sendMessageToDb();
    }

    sendMessageToDb() {
        this.chat.getDocRef(this.tenantID).set(this.channelInfo);
    }
}
