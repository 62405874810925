import { CHART_COLORS } from 'app/configs/constants';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as Chart from 'chart.js';

@Component({
    selector: 'Pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.css'],
})
export class PieChartComponent implements OnChanges {
    @Input() htmlId: string = 'test';
    @Input() data;
    @Input() chartConfig: chartConfig;
    @Input() loading: boolean = true;
    canvas: any;
    ctx: any;
    myChart;
    isEmpty: boolean = false;
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        try {
            this.renderChart(this.data);
        } catch (err) {
            console.error(err);
        }
    }

    renderChart(data: any) {
        try {
            let keys = [];
            let values = [];

            if (this.chartConfig.directValues) {
                keys = data.keys;
                values = data.values.map((inp) => this.roundOff(inp));
            } else {
                keys = data.map((inp) => inp[this.chartConfig.labelKey]);
                values = data.map((inp) => this.roundOff(inp[this.chartConfig.valueKey]));
            }

            if (!(keys.length && values.length)) {
                this.isEmpty = true;
            }

            if (this.myChart) {
                this.myChart.destroy(); // to destroy previously created chart
            }
            setTimeout(() => {
                this.canvas = document.getElementById(this.htmlId);
                this.ctx = this.canvas.getContext('2d');
                this.myChart = new Chart(this.ctx, {
                    type: 'doughnut',
                    data: {
                        labels: keys,
                        datasets: [
                            {
                                data: values,
                                backgroundColor: CHART_COLORS,
                                borderWidth: 1,
                            },
                        ],
                    },
                    options: {
                        legend: {
                            position: 'bottom',
                            labels: {
                                fontSize: 12,
                            },
                        },
                        // tooltips: {
                        //   callbacks: {
                        //     label: (tooltipItems, data) => (data.labels[tooltipItems.index] as string)
                        //   },
                        //   bodyFontSize: 12
                        // },
                        responsive: true,
                        maintainAspectRatio: false,
                    },
                });
            }, 200);
        } catch (err) {}
    }

    roundOff(val) {
        return Math.round(val * 100) / 100;
    }
}

interface chartConfig {
    directValues: boolean;
    labelKey: string;
    valueKey: string;
}
