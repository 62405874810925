import * as _ from 'lodash';
import * as SHARED_ACTIONS from 'app/store/actions/shared.actions';
import { SharedSlice } from 'app/store/state/shared.state';

const initialState: SharedSlice = {
    currentUser: undefined,
};

export function reducer(state = initialState, action: SHARED_ACTIONS.Actions): SharedSlice {
    switch (action.type) {
        case SHARED_ACTIONS.SetCurrentUser: {
            initialState.currentUser = action.currentUser;
            return initialState;
        }

        case SHARED_ACTIONS.SetTenantID: {
            initialState.tenantID = action.tenantID;
            return initialState;
        }

        case SHARED_ACTIONS.SetTenantName: {
            initialState.tenantName = action.tenantName;
            return initialState;
        }

        case SHARED_ACTIONS.SetTenants: {
            initialState.tenants = action.tenants;
            return initialState;
        }

        case SHARED_ACTIONS.SetSelectedTenant: {
            initialState.selectedTenant = action.tenant;
            return initialState;
        }

        default:
            return initialState;
    }
}
