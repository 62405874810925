<style>
    .client-server {
        background-color: #fff;
        /* box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5); */
        padding: 3px 6px 3px 6px;
    }

    .graphContainer {
        height: 280px !important;
        margin-top: 10px;
    }

    #tenantGraph {
        margin: auto;
        margin-top: 3px;
        height: 100% !important;
        width: 100% !important;
    }

    p {
        text-align: center;
        margin: 5vh !important;
        height: 100%
    }
</style>


<div class="client-server" *ngIf="!loading">
    <div class="d-flex justify-content-between">
        <!-- <config-select (change)="changeChart()" [(ngModel)]="currentChartId"
            [options]="[{view: 'Top Sites', value: 1 }, {view: 'Top Applications', value: 3}, {view: 'Top Talkers', value: 4}, {view: 'Top Sources', value: 5}, {view: 'Top Destinations', value: 6}, {view: 'Top Source Countries', value: 7}, {view: 'Top Destination Countries', value: 8}]"
            viewKey="view" valueKey="value" className="col-3"></config-select> -->
        <select (change)="changeChart()" [(ngModel)]="currentChartId" class="form-control form-control-sm col-3"
            style="height: 30px;">
            <option value=1>Top Sites</option>
            <!-- <option value=2>Top Protocols</option> -->
            <option value=3>Top Applications</option>
            <option value=4>Top Talkers</option>
            <option value=5>Top Sources</option>
            <option value=6>Top Destinations</option>
            <option value="7">Top Source Countries</option>
            <option value="8">Top Destination Countries</option>
        </select>
        <!-- <config-select className="col-3 float-right" (change)="changeChart()" [(ngModel)]="graphConfData.timeduration"
            [options]="[{view: '30 m',  value: 30}, {view: '1 hr',  value: 60}, {view: '3 hr',  value: 180}, {view: '12 hr',  value: 720}, {view: '24 hr',  value: 1440}]"
            viewKey="view" valueKey="value"></config-select> -->
        <select (change)="changeChart()" [(ngModel)]="graphConfData.timeduration"
            class="form-control form-control-sm col-2" style="height: 30px;">
            <option value="60">Last 1 hr</option>
            <option value="1440">Last 24 hrs</option>
            <option value="10080">Last 1 week</option>
            <!-- <option value="43200">Last 1 month</option> -->
        </select>
    </div>
    <div *ngIf="isEmpty" style="margin: auto; height: 12vh;">
        <p>
            No data available yet!
        </p>
    </div>
    <div *ngIf="!isEmpty && !loading && currentChartId == 4">
        <div class="graphContainer">
            <div id="talkers-container" style="height: 300px;"></div>
            <!-- <plotly-plot [data]="topDeviceData" [layout]="topDeviceLayout" [config]="topDeviceConf" [style]="{'height':'300px'}"  ></plotly-plot> -->
        </div>
    </div>

    <div class="graphContainer" *ngIf="!isEmpty && currentChartId != 4 && !loading"
        [ngStyle]="{'height' : currentChartId == 5 || currentChartId == 6 ? '300px !important': '100%'}">
        <canvas id="tenantGraph"></canvas>
    </div>
</div>

<div *ngIf="loading" style="height:320px; width: 100%;">
    <div class="loader">
    </div>
</div>