import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'rum',
    templateUrl: './rum.component.html',
    styleUrls: ['./rum.component.scss'],
})
export class RUMComponent {

    routeLinks: any[];
    activeLinkIndex: number = 0;

    constructor() {
        this.routeLinks = [
            // { label: 'Interfaces Global ', link: 'globalInterface', rumbackground: false },
            // { label: 'Alarms ', link: 'alarms', rumbackground: false },
            // // { label: 'BGP Global ', link: 'globalBGP' },
            // { label: 'IPSec VPN ', link: 'vpn-status', rumbackground: false },
            { label: 'Cosgrid Networks ', link: 'cosgrid-app', rumbackground: true },
            { label: 'Cosgrid website ', link: 'cosgrid-website', rumbackground: true },
        ];
    }
    // constructor(private sanitizer: DomSanitizer) {}

    // counter: number = 0;
    // cpugraph: any;
    // api = 'http://15.207.116.241:3003';
    // ngOnInit() {
    //     // this.cpugraph    = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.api}/dashboard-solo/db/${this.devicename}-metrics?orgId=1&refresh=5s&panelId=1`)
    //     this.cpugraph = this.sanitizer.bypassSecurityTrustResourceUrl(
    //         `${this.api}/d/eX34qINMk/murugavels-dashboard?orgId=1&refresh=5s&panelId=1`,
    //     );
    // }

    // clickeditem(temp: number) {
    //     this.counter = temp;
    // }

    // checkcounter(temp: number) {
    //     if (this.counter === temp) {
    //         return true;
    //     }

    //     return false;
    // }
}
