import { encryptStorage } from 'app/core/services/local-storage.service';
import { TemplateActionTypes, TemplateActions } from 'app/store/actions/templates.actions';
import { initialTemplateState } from 'app/store/state/template.state';

export function reducer(state = initialTemplateState, action: TemplateActions): any {
    switch (action.type) {
        case TemplateActionTypes.SetTemplates: {
            return {
                ...state,
                templates: action.payload,
            };
        }
        case TemplateActionTypes.SetSelectedTemplate: {
            // localStorage.setItem('template',JSON.stringify(action.payload))
            // localStorage.setItem('selectedTemplate', action.payload['id']) // for the urls to use the id. TODO: try to remove this if all the urls are changed in all the services where template id is got from localStorage

            encryptStorage.setItem('template', JSON.stringify(action.payload));
            encryptStorage.setItem('selectedTemplate', action.payload['id']); // for the urls to use the id. TODO: try to remove this if all the urls are changed in all the services where template id is got from localStorage
            return {
                ...state,
                selectedTemplate: action.payload,
            };
        }
        default:
            return state;
    }
}
