export enum DeviceFilterType {
    ALL = 'all',
    ONLINE = 'online',
    OFFLINE = 'offline',
    UNDEPLOYED = 'undeployed',
}

export interface DashboardSlice {
    deviceFilter: DeviceFilterType;
}

export const initialDashboardState: DashboardSlice = {
    deviceFilter: DeviceFilterType.ALL,
};
