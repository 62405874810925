<style>
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 50px;
           background-color: #0078be;
    }

    .modal-footer .btn-outline-secondary,
    .fa-times {
        /* color: lightgray; */
        border-color: lightgray;
    }

    .modal-content {
        /* color: #fff !important; */
        margin: auto;
        max-width: 25em;
    }

    .modal-content hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid grey;
        margin: 1em 0;
        padding: 0;
    }

    .modal-footer {
        border-top: 0px;
        display: flex;
        justify-content: center;
    }
    .fa-exclamation-triangle {
        color: #c82333;
        opacity: 0.8;
    }
    button{
        border-radius: 5px;
    }
    h4 {
        margin-left: 10px;
    }
    .modal {
        /* background: rgba(0, 0, 0, 0.8) !important; */
        opacity: 1 !important;
    }
</style>
<!-- Modal -->
<div class="d-block modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="header">
                <i class="fa fa-1x fa-exclamation-triangle text-center" style="color: white;" aria-hidden="true"></i>
                <h4 style="color: white;">
                    {{ content ? content : 'Delete' }}
                </h4>
            </div>

            <br />
            <h5 class="text-center">Are you sure you want to {{ content ? content : 'delete this item' }}?</h5>

            <div class="modal-footer">
                <button type="button" (click)="dialogRef.close('true')" class="btn" style="background-color: #ff560b;color: #fff;">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    Yes
                </button>

                <button id="no" type="button" (click)="dialogRef.close()" class="btn btn-dark" data-dismiss="modal">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    No
                </button>

            </div>
        </div>
    </div>
</div>

<!--<div class="modal-header">
    <h5  class="modal-title"> Confirm Deletion </h5>
    <button type="button" class="close" data-dismiss="modal" mat-dialog-close aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="overflow-y: visible;min-width:30em;" class="modal-body">

     <div style="padding:0px 20px" *ngIf ="configTempDevices.length > 0">
        <p>The following devices are attached to this template</p>
      <br>
        <mat-chip-list>
             <mat-chip style="border-radius:3px !important" *ngFor="let dev of configTempDevices">
                {{dev.name}}
             </mat-chip>
        </mat-chip-list>
    </div>
    <div style="padding:0px 20px" *ngIf ="NetworkDevices.length > 0">
        <p>The following devices are attached to this Network</p>
      <br>
        <mat-chip-list>
             <mat-chip style="border-radius:3px !important" *ngFor="let dev of NetworkDevices">
                {{dev.name}}
             </mat-chip>
        </mat-chip-list>
    </div>

    <p style="padding:20px">Are you sure you want to delete this?</p>

</div>

    <div class="modal-footer">

     <button type="button" (click)="dialogRef.close()" class="btn btn-secondary" data-dismiss="modal">Close</button>
     <button class="btn  btn-outline-danger" (click)="dialogRef.close(true)">Delete</button>

 </div>-->
