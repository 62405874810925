<div class="serverError" *ngIf="rows?.length === 0 && !response && error">
    <img src="https://cdn.cosgrid.com/website/COSGridNet/assets/noData/serverDown.png" alt="server down" />
    <p class="text-center">
        Network error, Please try again later
        <span (click)="reload()" class="retry">Click here to Retry</span>
        or Try Aganin after sometime.
    </p>
</div>

<div *ngIf="(rows?.length <= 0 && !response && !error) || requestLoading" style="height: 98%;margin-top: 20px;">

    <div class="loader-line " style="height: 60px ;"></div>
    <div class="loader-line"></div>
    <div class="loader-line"></div>
    <div class="loader-line"></div>
    <div class="loader-line"></div>
    <div class="loader-line"></div>
</div>
<div *ngIf="rows?.length > 0 && response && !error && !requestLoading">
    <ng-container *ngIf="vpnDashboard; else elseTemplate">
        <div class="noDevices" *ngIf="rows?.length === 0 && response && !error">
            <!-- <img src="../../../../../assets/noData/noStatus.png" width="20" height="20" /> -->
            <p class="text-center">No Devices Configured</p>
            <h5 class="mt-5">Create and enroll your device to begin using it in your network.</h5>
            <button class="btn btn-primary p-2 px-3 rounded-sm mt-3" routerLink="/getmicrozaccess">
                Click here to download MicroZAccess
            </button>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="noDevices" *ngIf="rows?.length === 0 && response && !error">
            <img src="https://cdn.cosgrid.com/website/COSGridNet/assets/noData/noStatus.png" alt="no status" />
            <p class="text-center">No Devices Configured</p>
        </div>
    </ng-template>

    <cosgrid-overlay-loader *ngIf="loading"></cosgrid-overlay-loader>

    <div *ngIf="rows?.length > 0">
        <div class="table-wrapper">
            <!-- <div *ngIf="commercialView" class="netTotal">
                    <span class="title">Net. Total -</span>
                    <span class="amount">₹ {{ netTotal }}</span>
                </div> -->

            <div class="bot">
                <div class="actions mb-2">
                    <div>
                        <ng-container *ngIf="vpnDashboard; else elseTemplate">
                            <div class="d-flex">
                                <span>
                                    <i style="color: #22aa00; border-radius: 5px;" class="fa fa-square"
                                        aria-hidden="true"></i>
                                    &nbsp; Connected &emsp;
                                </span>
                                <span>
                                    <i style="color: rgb(97, 106, 232);" class="fa fa-square" aria-hidden="true"></i>
                                    &nbsp; Online &emsp;
                                </span>
                                <span>
                                    <i style="color: red;" class="fa fa-square" aria-hidden="true"></i>
                                    &nbsp; Offline &emsp;
                                </span>
                                <span>
                                    <i style="color: #ffa608;" class="fa fa-check" aria-hidden="true"></i>
                                    &nbsp; Registered &emsp;
                                </span>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div style="margin-top: 10px; margin-left: 10px;">
                                <span>
                                    <i style="color: #22aa00;" class="fa fa-square" aria-hidden="true"></i>
                                    &nbsp; Running &emsp;
                                </span>
                                <span>
                                    <i style="color: #ffa608;" class="fa fa-square" aria-hidden="true"></i>
                                    &nbsp; Not Reachable &emsp;
                                </span>
                                <span>
                                    <i style="color: #ffa608;" class="fa fa-check" aria-hidden="true"></i>
                                    &nbsp; Registered &emsp;
                                </span>
                            </div>
                        </ng-template>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <div class="searchbar">
                            <i class="fas fa-search"></i>
                            <input [(ngModel)]="searchText" (keyup)="searchRows()" placeholder="Search"
                                style="padding-top: 5px;" />
                        </div>

                        <div class="action-buttons">
                            <div *ngIf="isCommercial" class="d-flex">
                                <strong>Commercial View </strong>
                                <ng-switchery class="ml-2" matTooltip="Toggle Commercial View"
                                    (change)="toggleCommercialView($event)" size="switchery-sm" name="switchery-check">
                                </ng-switchery>
                            </div>

                            <button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="filterMenu" type="button"
                                class="btn btn-light mr-2 border" style="padding-bottom: 0px;">
                                <i [ngStyle]="{ color: filterColor[deviceFilterType] }" class="fas fa-filter"></i>
                                Filter
                            </button>

                            <mat-menu #filterMenu xPosition="before">
                                <button (click)="updateDeviceFilterType('all')" mat-menu-item>
                                    <i style="color: #3185fa; margin-right: 10px" class="fas fa-filter"></i>
                                    <span>All Devices</span>
                                </button>
                                <button (click)="updateDeviceFilterType('online')" mat-menu-item>
                                    <i style="color: lawngreen; margin-right: 10px" class="fas fa-filter"></i>
                                    <span>Online Devices</span>
                                </button>
                                <button (click)="updateDeviceFilterType('offline')" mat-menu-item>
                                    <i style="color: red; margin-right: 10px" class="fas fa-filter"></i>
                                    <span>Offline Devices</span>
                                </button>
                                <button (click)="updateDeviceFilterType('undeployed')" mat-menu-item>
                                    <i style="color: #ffa608; margin-right: 10px" class="fas fa-filter"></i>
                                    <span>Undeployed Devices</span>
                                </button>
                            </mat-menu>

                            <button (click)="downloadAllRows()" type="button" class="btn btn-light mr-2 border"
                                style="padding-bottom: 0px;">
                                <i class="fas fa-cloud-download-alt"></i>
                                Download
                            </button>
                        </div>

                        <div *ngIf="!vpnDashboard" style="margin-left: 5px;display: flex;gap: 5px;">
                            <p style="background-color: #298c00; color: white;width: 63px;"
                                class="px-2 py-1 rounded mt-1 pt-2 text-center">
                                Up
                            </p>
                            <p style="background-color: #FF560B; color: white;width: 63px;"
                                class="px-2 py-1 rounded mt-1 pt-2 text-center">
                                Down
                            </p>
                            <p style="background-color: #ffa608; color: white;width: 63px;"
                                class="px-2 py-1 rounded mt-1 pt-2 text-center">
                                Unknown
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <br /> -->
                <div class="main">
                    <table class="table table-striped" id="table">
                        <thead style="background-color: #0078be;color: white !important;" class="h3">
                            <tr>
                                <th></th>
                                <th scope="col">
                                    <div style="display: flex;align-items: center;">
                                        <span class="">Device</span>
                                        <span class="sortContainer">
                                            <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                        </span>
                                    </div>
                                </th>
                                <ng-container *ngIf="!commercialView; else comViewHead">
                                    <th scope="col">
                                        <div style="display: flex;align-items: center;">
                                            <span class="">Last Seen</span>
                                            <span class="sortContainer">
                                                <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                                <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            </span>
                                        </div>
                                    </th>
                                    <th *ngIf="!vpnDashboard" scope="col">
                                        <div style="display: flex;align-items: center;justify-content: center;">
                                            <span class="">WAN 1</span>
                                            <span class="sortContainer">
                                                <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                                <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            </span>
                                        </div>
                                    </th>
                                    <th *ngIf="!vpnDashboard" scope="col">
                                        <div style="display: flex;align-items: center;justify-content: center;">
                                            <span class="">WAN 2</span>
                                            <span class="sortContainer">
                                                <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                                <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            </span>
                                        </div>
                                    </th>
                                    <th *ngIf="isWAN3 && !vpnDashboard" style="width: 10%;" scope="col">
                                        <div style="display: flex;align-items: center;justify-content: center;">
                                            <span class="" style="text-align: center;">WAN 3</span>
                                            <span class="sortContainer">
                                                <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                                <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            </span>
                                        </div>
                                    </th>
                                    <th *ngIf="vpnDashboard" scope="col">
                                        <div style="display: flex;align-items: center;">
                                            <span class="">{{ vpnDashboard ? 'MZA Private IP' : 'VPN IP'
                                                }}</span>
                                            <span class="sortContainer">
                                                <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                                <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            </span>
                                        </div>
                                    </th>
                                    <th *ngIf="vpnDashboard" scope="col">
                                        <div style="display: flex;align-items: center;">
                                            <span class="">OS</span>
                                            <span class="sortContainer">
                                                <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                                <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            </span>
                                        </div>
                                    </th>
                                    <th *ngIf="vpnDashboard" scope="col">
                                        <div style="display: flex;align-items: center;">
                                            <span class="">Location</span>
                                            <span class="sortContainer">
                                                <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                                <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            </span>
                                        </div>
                                    </th>
                                    <!-- <th *ngIf="vpnDashboard" scope="col">
                                        Bytes In/Bytes Out
                                        <span class="sortContainer">
                                            <div (click)="sortByLastSeen('asc', $event)" class="arrow-up"></div>
                                            <div (click)="sortByLastSeen('dsc', $event)" class="arrow-down"></div>
                                        </span>
                                    </th> -->
                                    <th *ngIf="vpnDashboard" scope="col">
                                        <div style="display: flex;align-items: center;">
                                            <span class="">User</span>
                                            <span class="sortContainer">
                                                <mat-icon (click)="sortByName('asc', $event)" svgIcon="upArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                                <mat-icon (click)="sortByName('dsc', $event)" svgIcon="downArrow"
                                                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                                            </span>
                                        </div>
                                    </th>
                                    <th *ngIf="!vpnDashboard" scope="col">
                                        <span class="">Active WAN(s)</span>
                                    </th>
                                    <th *ngIf="isVPN && !vpnDashboard" scope="col">VPN Status</th>
                                </ng-container>

                                <ng-template #comViewHead>
                                    <th>WAN 1</th>
                                    <th>WAN 2</th>
                                    <th *ngIf="isWAN3">WAN 3</th>
                                    <th>Total</th>
                                </ng-template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (dblclick)="rowClick(item)" *ngFor="let item of temp; let j = index">
                                <!-- COLUMN NAME : DEVICE -->
                                <ng-container *ngIf="vpnDashboard; else notvpn">
                                    <ng-container [ngSwitch]="item.deviceStatus">
                                        <td class="align-middle" style="width: 3%" *ngSwitchCase="'connected'">
                                            <span class="statusIcons">
                                                <i style="color: #22aa00;" class="fa fa-square fa-1x"
                                                    aria-hidden="true"></i>
                                            </span>
                                        </td>
                                        <td class="align-middle" style="width: 3%;" *ngSwitchCase="'online'">
                                            <span class="statusIcons">
                                                <i style="color: rgb(97, 106, 232);" class="fa fa-square fa-1x"
                                                    aria-hidden="true"></i>
                                            </span>
                                        </td>
                                        <td class="align-middle" style="width: 3%;" *ngSwitchCase="'offline'">
                                            <span class="statusIcons">
                                                <i style="color: red;" class="fa fa-square fa-1x"
                                                    aria-hidden="true"></i>
                                            </span>
                                        </td>
                                        <td class="align-middle" style="width: 3%;" *ngSwitchCase="'registered'">
                                            <span class="statusIcons">
                                                <i style="color: #ffa608;" class="fa fa-square fa-1x"
                                                    aria-hidden="true"></i>
                                            </span>
                                        </td>
                                        <td class="align-middle" style="width: 3%;" *ngSwitchDefault>
                                            <span class="statusIcons">
                                                <i style="color: #ffa608;" class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                        </td>
                                    </ng-container>
                                </ng-container>
                                <ng-template #notvpn>
                                    <td class="align-middle" style="width: 3%;" *ngIf="item.deviceStatus === 'running'">
                                        <span class="statusIcons">
                                            <i style="color: #22aa00;" class="fa fa-square fa-1x"
                                                aria-hidden="true"></i>
                                        </span>
                                    </td>

                                    <td class="align-middle" style="width: 3%;" *ngIf="item.deviceStatus === 'error'">
                                        <span class="statusIcons">
                                            <i style="color: #ffa608;" class="fa fa-square fa-1x"
                                                aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td class="align-middle" style="width: 3%;"
                                        *ngIf="item.deviceStatus === 'registered'">
                                        <span class="statusIcons">
                                            <i style="color: #ffa608;" class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                </ng-template>

                                <td class="align-middle"
                                    style="color: darkblue; font-weight: 400; align-self: center;width: 10%;">
                                    {{ item.name }}
                                </td>
                                <!-- END OF COLUMN NAME : DEVICE -->

                                <!--    COLUMN NAME : LAST SEEN -->
                                <td class="align-middle" *ngIf="!commercialView" style="width: 130px;">
                                    {{ item.lastSeenTime | date: ['d-MMM-yy, h:mm a'] }}
                                </td>
                                <!--    END  OF COLUMN NAME : LAST SEEN -->

                                <!--    COLUMN NAME : WAN 1 -->
                                <td class="align-middle text-center"
                                    *ngIf="!vpnDashboard && item?.wan_status?.length > 0; else else1">
                                    <div style="display: flex; align-items: center; justify-content: center;">
                                        <span class="wanContainer align-middle">
                                            <div>
                                                <!-- <p [ngStyle]="{
                                                        'background-color':
                                                            item.wan_status[0]?.status === 'up'
                                                                ? '#298c00'
                                                                : item.wan_status[0]?.status === 'down'
                                                                ? '#FF560B'
                                                                : '#ffa608'
                                                    }" (click)="showPopup($event, item, item.wan_status[0].name)"
                                                        class="wanName">
                                                        {{ item.wan_status[0].name }}
                                                    </p> -->
                                                <p [ngStyle]="{
                                                        'background-color':
                                                            item.wan_status[0]?.status === 'up'
                                                                ? '#298c00'
                                                                : item.wan_status[0]?.status === 'down'
                                                                ? '#FF560B'
                                                                : '#ffa608'
                                                    }" class="wanName">
                                                    {{ item.wan_status[0].name }}
                                                </p>
                                            </div>
                                        </span>

                                        <cosgrid-traffic-realtime-chart *ngIf="!commercialView" [data]="item"
                                            [wanIndex]="0"></cosgrid-traffic-realtime-chart>
                                        <div (click)="stopPropogation($event)" *ngIf="commercialView">
                                            <div class="d-flex">
                                                <div>
                                                    <strong>Amt.</strong>
                                                    <span *ngIf="
                                                            isEdit['id'] === item.id && isEdit['wan_index'] === 0;
                                                            else wan1Amount
                                                        " class="d-flex">
                                                        ₹
                                                        <input class="commercialAmount"
                                                            [(ngModel)]="tempEdit['amount']" />
                                                    </span>
                                                    <ng-template #wan1Amount>
                                                        <div>
                                                            {{ '₹ ' +
                                                            commercialData[item.id][item.wan_status[0].name].amount }}
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <div class="ml-3">
                                                    <strong>Date</strong>
                                                    <!-- <input *ngIf="isEdit['id'] === item.id && isEdit['wan_index'] === 0;else wan1Date" class="commercialDate" [(ngModel)]="tempEdit['date']"  > -->
                                                    <mat-form-field *ngIf="
                                                            isEdit['id'] === item.id && isEdit['wan_index'] === 0;
                                                            else wan1Date
                                                        " class="commercialDate" [floatLabel]="'never'">
                                                        <input [(ngModel)]="tempEdit['date']" class="datePicker"
                                                            matInput [matDatepicker]="picker" placeholder="dd/mm/yy" />
                                                        <mat-datepicker-toggle matSuffix
                                                            [for]="picker"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                    <ng-template #wan1Date>
                                                        <div>
                                                            {{
                                                            commercialData[item.id][item.wan_status[0].name]?.date
                                                            | date: 'd/M/yy'
                                                            }}
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <div class="editAmount">
                                                    <i *ngIf="!item.isWAN1Edit; else editWAN1"
                                                        (click)="editAmount($event, item, 'wan1')"
                                                        class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                    <ng-template #editWAN1>
                                                        <div class="updateActions">
                                                            <i (click)="cancelAmount(item, 'wan1')"
                                                                class="fas fa-times"></i>
                                                            <i (click)="saveAmount(item, 'wan1')"
                                                                class="fas fa-check"></i>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="lastUpdated">
                                                *Last Updated
                                                <span>
                                                    {{
                                                    commercialData[item.id][item.wan_status[0].name]?.lastUpdated
                                                    | date: 'd/M/yy'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <!--   END OF  COLUMN NAME : WAN 1 -->

                                <!--    COLUMN NAME : WAN 2 -->
                                <td class="align-middle text-center"
                                    *ngIf="!vpnDashboard && item?.wan_status?.length > 1; else else1">
                                    <div style="display: flex; align-items: center; justify-content: center;">
                                        <span class="wanContainer">
                                            <div>
                                                <!-- <p [ngStyle]="{
                                                        'background-color':
                                                            item.wan_status[1].status === 'up'
                                                                ? '#298c00'
                                                                : item.wan_status[1].status === 'down'
                                                                ? '#FF560B'
                                                                : '#ffa608'
                                                    }" (click)="showPopup($event, item, item.wan_status[1].name)"
                                                        class="wanName">
                                                        {{ item.wan_status[1].name }}
                                                    </p> -->
                                                <p [ngStyle]="{
                                                        'background-color':
                                                            item.wan_status[1].status === 'up'
                                                                ? '#298c00'
                                                                : item.wan_status[1].status === 'down'
                                                                ? '#FF560B'
                                                                : '#ffa608'
                                                    }" class="wanName">
                                                    {{ item.wan_status[1].name }}
                                                </p>
                                            </div>
                                        </span>

                                        <cosgrid-traffic-realtime-chart *ngIf="!commercialView" [data]="item"
                                            [wanIndex]="1"></cosgrid-traffic-realtime-chart>
                                        <div (click)="stopPropogation($event)" *ngIf="commercialView">
                                            <div class="d-flex">
                                                <div>
                                                    <strong>Amt.</strong>
                                                    <span *ngIf="
                                                            isEdit['id'] === item.id && isEdit['wan_index'] === 1;
                                                            else wan2Amount
                                                        " class="d-flex">
                                                        ₹
                                                        <input class="commercialAmount"
                                                            [(ngModel)]="tempEdit['amount']" />
                                                    </span>
                                                    <ng-template #wan2Amount>
                                                        <div>
                                                            {{
                                                            '₹ ' +
                                                            commercialData[item.id][item.wan_status[1].name]?.amount
                                                            }}
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <div class="ml-3">
                                                    <strong>Date</strong>
                                                    <!-- <input *ngIf="isEdit['id'] === item.id && isEdit['wan_index'] === 0;else wan1Date" class="commercialDate" [(ngModel)]="tempEdit['date']"  > -->
                                                    <mat-form-field *ngIf="
                                                            isEdit['id'] === item.id && isEdit['wan_index'] === 1;
                                                            else wan2Date
                                                        " class="commercialDate" [floatLabel]="'never'">
                                                        <input [(ngModel)]="tempEdit['date']" class="datePicker"
                                                            matInput [matDatepicker]="picker" placeholder="dd/mm/yy" />
                                                        <mat-datepicker-toggle matSuffix
                                                            [for]="picker"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                    <ng-template #wan2Date>
                                                        <div>
                                                            {{
                                                            commercialData[item.id][item.wan_status[1].name]?.date
                                                            | date: 'd/M/yy'
                                                            }}
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <div class="editAmount">
                                                    <i *ngIf="!item.isWAN2Edit; else editWAN2"
                                                        (click)="editAmount($event, item, 'wan2')"
                                                        class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                    <ng-template #editWAN2>
                                                        <div class="updateActions">
                                                            <i (click)="cancelAmount(item, 'wan2')"
                                                                class="fas fa-times"></i>
                                                            <i (click)="saveAmount(item, 'wan2')"
                                                                class="fas fa-check"></i>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="lastUpdated">
                                                *Last Updated
                                                <span>
                                                    {{
                                                    commercialData[item.id][item.wan_status[1].name]?.lastUpdated
                                                    | date: 'd/M/yy'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <!--    COLUMN NAME : WAN 2 -->

                                <!--    COLUMN NAME : WAN 3 -->
                                <td class="align-middle" *ngIf="isWAN3 && !vpnDashboard">
                                    <div *ngIf="item?.wan_status?.length > 2; else else1">
                                        <span class="wanContainer">
                                            <div style="display: flex;align-items: center;justify-content: center;">
                                                <p [ngStyle]="{
                                                        'background-color':
                                                            item.wan_status[2].status === 'up'
                                                                ? '#298c00'
                                                                : item.wan_status[2].status === 'down'
                                                                ? '#FF560B'
                                                                : '#ffa608'
                                                    }" class="wanName">
                                                    {{ item.wan_status[2].name }}
                                                </p>
                                            </div>
                                        </span>
                                        <cosgrid-traffic-realtime-chart *ngIf="!commercialView" [data]="item"
                                            [wanIndex]="2"></cosgrid-traffic-realtime-chart>

                                        <div (click)="stopPropogation($event)" *ngIf="commercialView">
                                            <div class="d-flex">
                                                <div>
                                                    <strong>Amt.</strong>
                                                    <span *ngIf="
                                                            isEdit['id'] === item.id && isEdit['wan_index'] === 2;
                                                            else wan3Amount
                                                        " class="d-flex">
                                                        ₹
                                                        <input class="commercialAmount"
                                                            [(ngModel)]="tempEdit['amount']" />
                                                    </span>
                                                    <ng-template #wan3Amount>
                                                        <div>
                                                            {{ '₹' +
                                                            commercialData[item.id][item.wan_status[2].name].amount
                                                            }}
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <div class="ml-3">
                                                    <strong>Date</strong>
                                                    <mat-form-field *ngIf="
                                                            isEdit['id'] === item.id && isEdit['wan_index'] === 2;
                                                            else wan3Date
                                                        " class="commercialDate" [floatLabel]="'never'">
                                                        <input [(ngModel)]="tempEdit['date']" class="datePicker"
                                                            matInput [matDatepicker]="picker" placeholder="dd/mm/yy" />
                                                        <mat-datepicker-toggle matSuffix
                                                            [for]="picker"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                    <ng-template #wan3Date>
                                                        <div>
                                                            {{
                                                            commercialData[item.id][item.wan_status[2].name]?.date
                                                            | date: 'd/M/yy'
                                                            }}
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <div class="editAmount">
                                                    <i *ngIf="!item.isWAN3Edit; else editComm"
                                                        (click)="editAmount($event, item, 'wan3')"
                                                        class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                    <ng-template #editComm>
                                                        <div class="updateActions">
                                                            <i (click)="cancelAmount(item, 'wan3')"
                                                                class="fas fa-times"></i>
                                                            <i (click)="saveAmount(item, 'wan3')"
                                                                class="fas fa-check"></i>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="lastUpdated">
                                                *Last Updated
                                                <span>
                                                    {{
                                                    commercialData[item.id][item.wan_status[2].name]?.lastUpdated
                                                    | date: 'd/M/yy'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <!--    COLUMN NAME : WAN 3 -->

                                <!--    COLUMN NAME : Active WANS -->
                                <td class="align-middle" *ngIf="
                                        !commercialView && item?.traffic && item.traffic?.length > 0 && !vpnDashboard;
                                        else else1
                                    " style="width: 12%;">
                                    <span *ngFor="let tf of item.traffic; let i = index" style="font-weight: 400;">
                                        <span *ngIf="i !== item?.traffic?.length - 1"> {{ tf }},&nbsp; </span>
                                    </span>
                                    <span style="font-weight: 400;">
                                        {{ item.traffic[item?.traffic?.length - 1] }}
                                    </span>
                                </td>
                                <!--    COLUMN NAME : END OF Active WANS -->

                                <!--    COLUMN NAME : VPN STATUS -->
                                <td class="align-middle" style="text-align: center;"
                                    *ngIf="!commercialView && isVPN && !vpnDashboard">
                                    <div *ngIf="item.lb_status.status === 'up'; else noLockDown">
                                        <span *ngIf="item.lb_status.mpls?.status === 'up'; else onlyTunnel">
                                            <div class="alignStatus"
                                                *ngIf="item.lb_status.tunnel?.status === 'up'; else onlyMpls">
                                                <mat-icon style="color: #22aa00;" [svgIcon]="'secureTunnel'"></mat-icon>
                                                <mat-icon style="color: #22aa00;" [svgIcon]="'noLockTunnel'"></mat-icon>
                                                {{
                                                getUnique(item.lb_status.tunnel.interface,
                                                item.lb_status.mpls.interface)
                                                .toString()
                                                .replace(',', ', ')
                                                }}
                                            </div>
                                            <ng-template #onlyMpls>
                                                <div class="alignStatus">
                                                    <mat-icon style="color: #22aa00;"
                                                        [svgIcon]="'noLockTunnel'"></mat-icon>
                                                    {{ item.lb_status.mpls.interface.toString().replace(',', ', ') }}
                                                    <mat-icon style="color: red;" [svgIcon]="'secureTunnel'"></mat-icon>
                                                </div>
                                            </ng-template>
                                        </span>

                                        <ng-template #onlyTunnel>
                                            <div class="alignStatus"
                                                *ngIf="item.lb_status.tunnel?.status === 'up'; else tunnelDown">
                                                <!-- TO add space after comma -->
                                                <mat-icon style="color: #22aa00;" [svgIcon]="'secureTunnel'"></mat-icon>
                                                {{ item.lb_status.tunnel.interface.toString().replace(',', ', ') }}
                                            </div>
                                        </ng-template>

                                        <ng-template #tunnelDown>
                                            <div class="alignStatus">
                                                <mat-icon style="color: red;" [svgIcon]="'secureTunnel'"></mat-icon>
                                            </div>
                                        </ng-template>
                                    </div>

                                    <ng-template #noLockDown>
                                        <div class="alignStatus">
                                            <mat-icon style="color: red;" [svgIcon]="'noLockTunnel'"></mat-icon>
                                        </div>
                                    </ng-template>
                                </td>

                                <!--    COLUMN NAME : END OF VPN STATUS -->

                                <td class="align-middle" *ngIf="!commercialView && vpnDashboard" style="width: 130px;">
                                    {{ item.vpnIp }}
                                </td>
                                <td class="align-middle" *ngIf="!commercialView && vpnDashboard" style="width: 130px;">
                                    <i *ngIf="item?.os?.toLowerCase().includes('windows')" class="fab fa-windows fa-lg"
                                        style="color: #00a4ef; margin-right: 5px;"></i>
                                    <i *ngIf="item?.os?.toLowerCase().includes('macos')" class="fab fa-apple fa-lg"
                                        style="color: grey; margin-right: 5px;"></i>
                                    <i *ngIf="item?.os?.toLowerCase().includes('linux') || item?.os?.includes('gnu/linux')"
                                        class="fab fa-linux fa-lg" style="margin-right: 5px;"></i>
                                    {{ item.os }}
                                </td>
                                <td class="align-middle" *ngIf="!commercialView && vpnDashboard" style="width: 130px;"
                                    [innerHTML]="item.location == null ? '--': item.location">
                                    <!-- {{ item.location }} -->
                                </td>
                                <!-- <td class="align-middle" *ngIf="!commercialView && vpnDashboard" width="130px">
                                    {{ item.bytesIn }}/{{ item.bytesOut }}
                                </td> -->
                                <td class="align-middle" *ngIf="!commercialView && vpnDashboard" style="width: 130px;">
                                    {{ item?.createdBy?.split('@')[0] }}
                                </td>

                                <ng-template #else1>
                                    <td *ngIf="!vpnDashboard" class="text-center">
                                        <p style="padding: 3px;">---</p>
                                    </td>
                                </ng-template>

                                <ng-template #comViewBody></ng-template>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="isPagination" class="pagination-container">
                    <ul class="pagination" *ngIf="pager?.pages && pager.pages?.length">
                        <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
                            <a class="page-link" (click)="setPage(1)">First</a>
                        </li>
                        <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
                            <a class="page-link" (click)="setPage(pager.currentPage - 1)">
                                <i class="fas fa-arrow-left"></i>
                            </a>
                        </li>
                        <li class="page-item" *ngFor="let page of pager.pages"
                            [ngClass]="{ active: pager.currentPage === page }">
                            <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                            <a class="page-link" (click)="setPage(pager.currentPage + 1)">
                                <i class="fas fa-arrow-right"></i>
                            </a>
                        </li>
                        <li class="page-item" [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                            <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                        </li>
                    </ul>
                    <div class="steps">
                        <select (change)="setPage(pager.currentPage)" [(ngModel)]="pager.pageSize"
                            class="form-control form-control-sm">
                            <option *ngFor="let pageSize of pageSizes" [value]="pageSize">
                                {{ pageSize }}
                            </option>
                            <option [value]="rows?.length">None</option>
                        </select>
                    </div>
                </div>

                <!--<div class="bot">
                    <div style="float: left;">
                        <span>
                            <i style="color: #22aa00;" class="fa fa-circle" aria-hidden="true"></i> &nbsp; Running &emsp;
                        </span>
                        <span>
                            <i style="color: #ffa608" class="fa fa-circle" aria-hidden="true"></i> &nbsp; Not Reachable
                            &emsp;
                        </span>
                        <span>
                            <i style="color: #ffa608" class="fa fa-check" aria-hidden="true"></i> &nbsp; Registered &emsp;
                        </span>
                    </div>
        
        
                    <div style="float: right;">
                        <span>
                            <i style="color: lawngreen;" class="fas fa-square" aria-hidden="true"></i> &nbsp; Up &emsp;
                        </span>
                        <span>
                            <i style="color: #FD7567" class="fas fa-square" aria-hidden="true"></i> &nbsp; Down &emsp;
                        </span>
                        <span>
                            <i style="color:#ffa608" class="fas fa-square"></i> &nbsp; Unknown &emsp;
                        </span>
                    </div>
                </div>-->
            </div>
        </div>
    </div>