import { Injectable, EventEmitter } from '@angular/core';
declare var $: any;

@Injectable({
    providedIn: 'root',
})
export class TableService {
    upOrder = 'asc';

    constructor() {}

    downloadCSV(csv, filename) {
        let csvFile;
        let downloadLink;

        // CSV file
        csvFile = new Blob([csv], { type: 'text/csv' });

        // Download link
        downloadLink = document.createElement('a');

        // File name
        downloadLink.download = filename;

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Hide download link
        downloadLink.style.display = 'none';

        // Add the link to DOM
        document.body.appendChild(downloadLink);

        // Click download link
        downloadLink.click();
    }

    exportTableToCSV(filename, isConfig, tableID) {
        const csv = [];
        const rows = document.querySelectorAll(tableID + ' tr');
        const rowLength = rows.length;
        let colLength;
        for (let i = 0; i < rowLength; i++) {
            const row = [],
                cols: any = rows[i].querySelectorAll('td, th');
            if (isConfig) {
                colLength = cols.length - 1;
            } else {
                colLength = cols.length;
            }
            for (let j = 0; j < colLength; j++) {
                let status = '';
                // replace icons with statuses
                if (cols[j].innerHTML.toString().includes('lawngreen')) {
                    status = ' - Up';
                } else if (cols[j].innerHTML.toString().includes('red')) {
                    status = ' - Down';
                }
                row.push(cols[j].innerText.replace(/,/g, ' ') + status); // remove all the commans and replace with spaces as commas will conflict with csv
            }

            csv.push(row.join(','));
        }
        // Download CSV file
        if (!filename) {
            filename = 'data';
        }
        this.downloadCSV(csv.join('\n'), filename + '.csv');
    }

    // pagination

    getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
        // calculate total pages
        const totalPages = Math.ceil(totalItems / pageSize);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems,
            currentPage,
            pageSize,
            totalPages,
            startPage,
            endPage,
            startIndex,
            endIndex,
            pages,
        };
    }

    aciveIcon(ev) {
        document.querySelectorAll('.sortContainer div').forEach((inp: HTMLElement) => {
            if (inp.className.includes('up')) {
                inp.style.borderBottomColor = 'black';
            } else {
                inp.style.borderTopColor = 'black';
            }
        });
        if (ev.srcElement.className.includes('up')) {
            ev.srcElement.style.borderBottomColor = 'lawngreen';
        } else {
            ev.srcElement.style.borderTopColor = 'lawngreen';
        }
    }

    sortRows(key, order, ev, table) {
        //
        // setTimeout(() => {
        //   this.aciveIcon(ev)
        // }, 100);
        return table.sort((a, b) => {
            try {
                if (order === this.upOrder) {
                    return a[key].localeCompare(b[key]);
                } else {
                    return b[key].localeCompare(a[key]);
                }
            } catch (err) {}
        });
    }

    hasData(table: any[]): boolean {
        try {
            if (table.length === 0) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            return false;
        }
    }

    needFunctionality(eventEmitter: EventEmitter<any>) {
        return eventEmitter.observers.length > 0;
    }
}
