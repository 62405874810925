<div class="modal-body">
  <!-- <button type="button" class="close" data-dismiss="modal" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button> -->
  <!-- <div *ngIf="loading" class="loaderRound "> </div>
  <div class="alert alert-danger" *ngIf="interfaces.length < 1 && !loading">
    No Data available

  </div> -->
  <div class="metaDataContainer">
    <h6><strong>Device </strong> {{data.item.name}}</h6>
    <h6><strong>Interface </strong> {{data.iface}}</h6>
  </div>

  <div class="row upper">
    <div style="background-color: white;height:10px;width: 100%">
    </div>
    <iframe [ngStyle]="{'padding-top.px':isPanel1n2Available ? '0' : '7'}"
      *ngIf="isPanel3Available;else interfaceFallBackUI" disabled [src]="panel3" height="{{interfaceGraphHeight}}px"
      width="{{interfaceGraphWidth}}px" frameborder="0"></iframe>
    <div class="whiteSpace" *ngIf="isPanel1n2Available"
      style="top:-68px;">
    </div>
  </div>

  <div class="row lower">
    <div class="col-6-sm">
      <iframe *ngIf="isPanel1n2Available;" disabled [src]="panel1" height="{{pingGraphHeight}}px"
        width="{{pingGraphWidth}}px" frameborder="0"></iframe>
    </div>
    <div class="col-6-sm">
      <iframe *ngIf="isPanel1n2Available;" disabled [src]="panel2" height="{{pingGraphHeight}}px"
        width="{{pingGraphWidth}}px" frameborder="0"></iframe>
    </div>
    <div *ngIf="isPanel1n2Available"  class="whiteSpace"
      style="top:-14px;">
    </div>
  </div>
  <ng-template #pingFallBackUI>
    <div [ngStyle]="{'height.px': pingGraphHeight, 'width.px': pingGraphWidth}"
      style="background-color: white;margin-bottom: 5px">
      <p>No Data Available</p>
    </div>
  </ng-template>

  <ng-template #interfaceFallBackUI>
    <div [ngStyle]="{'height.px': interfaceGraphHeight, 'width.px': interfaceGraphWidth}"
      style="background-color: white;">
      <h6 class="noData"> No Data Available </h6>
    </div>
  </ng-template>
</div>
