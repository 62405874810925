import { Action } from '@ngrx/store';
import { Network } from 'app/shared/model/network';

export enum NetworkActionTypes {
    SetNetworks = '[Network] Set Networks',
    SetSelectedNetwork = '[Network] Set Selected Network',
}

export class SetNetworks implements Action {
    public readonly type: string = NetworkActionTypes.SetNetworks;
    constructor(public payload: Array<Network>) {}
}
export class SetSelectedNetwork implements Action {
    public readonly type: string = NetworkActionTypes.SetSelectedNetwork;
    constructor(public payload: Network) {}
}

export type NetworkActions = SetNetworks | SetSelectedNetwork;
