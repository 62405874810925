<div *ngIf="!checkConnectionService.internetState"  class="noInternetWrapper">
  <div class="noNet">
    <!-- <span class="message" >
      Internet seems to be slow
    </span> -->
    <span class="message">
      No Internet Connection.
    </span>Connecting in {{viewTimer/1000}}s
    <span (click)="retry()" class="button">
      Retry
    </span>
  </div>
</div>