import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Subject, Subscription } from 'rxjs';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { StorageService } from 'app/shared/services/misc/storage.service';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    AllChats: Subject<any> = new Subject<any>();
    tenantChat: Subject<any> = new Subject<any>();

    AllChatSub: Subscription;
    tenantChatSub: Subscription;

    tenantChatLength: number = this.encryptedLocalStorageService.getItem('notif') || 0;
    AllChatLength: number = this.encryptedLocalStorageService.getItem('adminNotiff') || 0;

    chatCollectionRef: AngularFirestoreCollection;
    constructor(
        private afs: AngularFirestore,
        private storage: StorageService,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private customerListService: CustomerListService,
    ) {
        this.chatCollectionRef = afs.collection('chats');
    }

    getCollectionRef() {
        return this.chatCollectionRef;
    }
    getDocRef(id): AngularFirestoreDocument {
        return this.chatCollectionRef.doc(id);
    }
    getMessages(tid) {
        const docRef = this.chatCollectionRef.doc(tid);
        return docRef.get();
    }

    createChat(tid, data) {
        return this.chatCollectionRef.doc(tid).set(data);
    }

    channelUpdated(tid) {
        return this.chatCollectionRef.doc(tid).valueChanges();
    }

    goToLatestChat() {
        try {
            setTimeout(() => {
                const chatBox = document.querySelector('.chatBox');
                chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight;
            }, 50);
        } catch (err) {}
    }

    // admin specific

    subscribeToAllChat() {
        this.getCollectionRef()
            .valueChanges()
            .subscribe((res) => {
                let resLength = 0;
                for (const x in res) {
                    const messages = res[x]?.messages;
                    for (const y in messages) {
                        if (messages[y].from !== 'Support') {
                            resLength++;
                        }
                    }
                }

                if (this.AllChatLength !== resLength) {
                    const newMessages = resLength - this.AllChatLength;
                    this.AllChats.next(newMessages);
                    // localStorage.setItem('adminNotiff', JSON.stringify(resLength));
                    this.encryptedLocalStorageService.setItem('adminNotiff', JSON.stringify(resLength));
                    this.AllChatLength = resLength;
                }
            });
    }
    unsubscribeToAllChat() {
        this.AllChatSub.unsubscribe();
    }

    // client specific

    subscribeToTenantChat() {
        const tid = this.customerListService.getTenantId().toString();
        this.tenantChatSub = this.chatCollectionRef
            .doc(tid)
            .valueChanges()
            .subscribe((res: any) => {
                let resLength = 0;
                const resMessages = res?.messages;
                for (const y in resMessages) {
                    if (resMessages[y].from === 'Support') {
                        resLength++;
                    }
                }

                if (this.tenantChatLength !== resLength) {
                    const newMesasages = resLength - this.tenantChatLength;
                    this.tenantChat.next(newMesasages);
                    // localStorage.setItem('notif', JSON.stringify(resLength));
                    this.encryptedLocalStorageService.setItem('notif', JSON.stringify(resLength));
                    this.encryptedLocalStorageService.setItem('notif', JSON.stringify(resLength));
                    this.tenantChatLength = resLength;
                }
            });
    }

    unsubscribeToTenantChat() {
        this.tenantChatSub.unsubscribe();
    }

    // DESKTOP NOTIFICATIONS

    checkPermission(): boolean {
        return Notification.permission === 'denied' ? false : true;
    }

    requestPermission(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Notification.requestPermission()
                .then((res) => {
                    if (res === 'granted') {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    sendDesktopNotification(numberOfMessages) {
        return new Promise((resolve, reject) => {
            // if (document.hidden) {
            const notification = new Notification('You Have ' + numberOfMessages + ' New Notifcation(s)');
            notification.onclick = () => {
                parent.focus();
                window.focus(); // just in case, older browsers
                notification.close();
                resolve(null);
            };
            // }
        });
    }
}
