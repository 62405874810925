<style>
    .parent {
        height: 270px;
        overflow: hidden;
    }

    .parent .line {
        float: left;
        height: 20px;
        display: block;
        margin-top: 20px;
        border-radius: 3px;
        background-image: linear-gradient(90deg, #ddd 0%, #e8e8e8 40%, #ddd 80%);
        background-size: 600px;
        animation: shine-lines 2s infinite linear;
    }

    .parent .avatar+.line {
        margin-top: 11px;
        width: 100px;
    }

    .parent .line~.line {
        background-color: #ddd;
    }

    @keyframes shine-lines {
        0% {
            background-position: 50px;
        }

        40% {
            background-position: 140px;
        }

        100% {
            background-position: 280px;
        }
    }
</style>

<div *ngIf="fakeText">
    <div *ngIf="show" class="parent" [ngStyle]="{'width': width}">
        <div style="height:25px;margin-bottom: 20px" class="line" [ngStyle]="{'width': width}"></div>

        <div [ngStyle]="{'width': line1}" class="line"></div>
        <div [ngStyle]="{'width': line2}" class="line"></div>
        <div [ngStyle]="{'width': line3}" class="line"></div>
        <div [ngStyle]="{'width': line4}" class="line"></div>

    </div>
</div>
<div *ngIf="!fakeText">
    <div *ngIf="show" class="loader">
        <span></span>
        <span></span>
        <span></span>
    </div>

</div>