import { Component, OnInit } from '@angular/core';
import { CheckConnectionService } from 'app/shared/services/misc';

@Component({
    selector: 'internet-tracker',
    templateUrl: './internet-tracker.component.html',
    styleUrls: ['./internet-tracker.component.scss'],
})
export class InternetTrackerComponent implements OnInit {
    constructor(public checkConnectionService: CheckConnectionService) {}

    timer: number = 30000;
    // internet: boolean = true;

    viewTimer: number;
    interval;

    ngOnInit() {
        this.checkInternetConnection();
        // this.initTimer()
    }

    checkInternetConnection() {
        setTimeout(() => {
            this.checkConnectionService.isOnline().subscribe(
                (res) => {
                    this.timer = 30000;
                    this.checkConnectionService.internetState = true;
                    this.checkInternetConnection();
                },
                (err) => {
                    this.checkConnectionService.internetState = false;
                    this.timer += 10000;
                    this.initTimer();
                    this.checkInternetConnection();
                },
            );
        }, this.timer);
    }

    retry() {
        this.checkConnectionService.isOnline().subscribe(
            (res) => {
                this.checkConnectionService.internetState = true;
            },
            (err) => {
                this.checkConnectionService.internetState = false;
            },
        );
    }

    initTimer() {
        this.viewTimer = this.timer;
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setInterval(() => {
            this.viewTimer -= 1000;
        }, 1000);
    }
}
