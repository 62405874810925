import { MODULE_SHARED_COMPONENTS } from './shared.components';
import { NgSwitcheryModule } from 'angular-switchery-ios';

import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
    SHARED_DIRECTIVES,
    SHARED_PROVIDERS,
    SHARED_COMPONENTS,
    SHARED_PIPES,
    FIREBASE_MODULES,
    DEFAULT_CORE_MODULES,
    MATERIAL_MODULES,
    THIRD_PARTY_MODULES,
} from 'app/shared/shared.components';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from 'app/core/services/interceptor.service';
import { SomethingWentWrongComponent } from './components/something-went-wrong/something-went-wrong.component';
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { NgxEchartsModule } from 'ngx-echarts';
PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
    imports: [
        ...MATERIAL_MODULES,
        ...FIREBASE_MODULES,
        FormsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        NgSwitcheryModule,
        NgOptimizedImage,
        PlotlyModule,
        NgxEchartsModule,
    ],
    declarations: [
        ...SHARED_DIRECTIVES,
        ...SHARED_COMPONENTS,
        ...SHARED_PIPES,
        ...MODULE_SHARED_COMPONENTS,
        SomethingWentWrongComponent,
    ],
    exports: [
        ...SHARED_DIRECTIVES,
        ...THIRD_PARTY_MODULES,
        ...MATERIAL_MODULES,
        ...FIREBASE_MODULES,
        ...DEFAULT_CORE_MODULES,
        ...SHARED_COMPONENTS,
        ...MODULE_SHARED_COMPONENTS,
        ...SHARED_PIPES,
    ],
    providers: [
        ...SHARED_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
        },
    ],
})
export class SharedCommonModule { }
