import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  @Input() errRequest: string = 'check your connection'
  @Input() errMessage: string = 'There was a problem connecting to server'
  @Input() errHeading: string = 'Oops! Something wrong'
  @Input() refresh: boolean = true
  
  
  refreshPage() {
    window.location.reload()
  }
}
