import { Template } from 'app/shared/model/template.model';
import { Action } from '@ngrx/store';

export enum TemplateActionTypes {
    SetTemplates = '[Template] Set Template',
    SetSelectedTemplate = '[Template] Set Selected Template',
}

export class SetTemplates implements Action {
    public readonly type: string = TemplateActionTypes.SetTemplates;
    constructor(public payload: Array<Template>) {}
}
export class SetSelectedTemplate implements Action {
    public readonly type: string = TemplateActionTypes.SetSelectedTemplate;
    constructor(public payload: Template) {}
}

export type TemplateActions = SetTemplates | SetSelectedTemplate;
