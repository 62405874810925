import { CosgridAppStore } from 'app/store/app.store';
import { allNetworks } from 'app/store/state/network.state';
import { Network } from 'app/shared/model/network';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DeviceListService } from 'app/shared/services/comp';
import { Component, OnInit } from '@angular/core';
import { NetworkListService } from 'app/store/services/network-list.service';

@Component({
    selector: 'select-network',
    templateUrl: './select-network.component.html',
    styleUrls: ['./select-network.component.scss'],
})
export class SelectNetworkComponent implements OnInit {
    networks: Array<Network> = [];
    selectedNetwork: Network = {
        networkName: '',
    };
    allNetworksOption = allNetworks;

    selectedNetworkSub: Subscription;
    AllNetworksSubscription: Subscription;

    constructor(
        private networkListService: NetworkListService,
        private deviceListService: DeviceListService,
        private router: Router,
        private store: Store<CosgridAppStore>,
    ) { }

    ngOnInit() {
        this.subscribeToAllNetworks();
        this.subscribeToSelectedNetwork();
    }

    ngOnDestroy() {
        this.AllNetworksSubscription.unsubscribe();
        this.selectedNetworkSub.unsubscribe();
    }

    networkChanged(value) {
        console.log(value);

        this.networkListService.dispatchSelectedNetworkToStore(value);
        this.deviceListService.dispatchSelectedDeviceToStore({}); // empty the selection only when the user toggles the selection and not when the value comes from localstorage
        this.router.navigateByUrl('/dashboard');
    }

    subscribeToSelectedNetwork() {
        this.selectedNetworkSub = this.store
            .select((slices) => slices.networkSlice.selectedNetwork)
            .subscribe((res) => {
                if (res) {
                    // getdevicelist call is in changeNetwork() cuz network cannot be changed except here
                    // device selection deleted when the user literally toggles the selection and not when the network value changes
                    this.selectedNetwork = res;
                }
            });
    }
    subscribeToAllNetworks() {
        this.AllNetworksSubscription = this.store
            .select((slices) => slices.networkSlice.networks)
            .subscribe((res) => {
                let temp: Array<any> = res;
                temp.unshift(this.allNetworksOption); // adding "all networks"
                temp.pop(); // removing "Non -attached devices"
                this.networks = temp;
            });
    }
}
