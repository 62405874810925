import { Injectable } from '@angular/core';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private encryptedLocalStorageService: EncryptedLocalStorageService) {}

    setRememberMe(data) {
        localStorage.setItem('remember', JSON.stringify(data));
    }
    getRememberMe(): boolean {
        return localStorage.getItem('remember') === 'true';
    }

    getRefreshToken(): string {
        return this.encryptedLocalStorageService.getItem('refresh');
    }
}
