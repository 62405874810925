<div class="w-100 h-50 d-flex flex-column align-items-center justify-content-center">
  <img src="https://cdn.cosgrid.com/COSGridNet/assets/error.png" alt="" width="70px" />
  <p class="h4">{{ errHeading }}</p>
  <p>{{ errMessage }}</p>
  <p>
    Please
    <span (click)="refreshPage()" *ngIf="refresh"><a (click)="refreshPage()" class="refresh">refresh the page</a>
      or</span>
    {{ errRequest }}
  </p>
</div>