import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EmitterService {
    // TO EMIT EVENT WHEN USER CHANGS THE DEVICE

    dataStr = new EventEmitter();

    constructor() {}

    sendMessage(data: string) {
        this.dataStr.emit(data);
    }
}
