import { encryptStorage } from 'app/core/services/local-storage.service';
import { Network } from 'app/shared/model/network';

export interface NetworkSlice {
    selectedNetwork: Network;
    networks: Network[];
}

// initial state for selectedNetwork
export const allNetworks: Network = {
    networkName: 'All Device Groups',
    id: '',
};

export const initialNetworkState: NetworkSlice = {
    selectedNetwork: encryptStorage.getItem('network') ? encryptStorage.getItem('network') : allNetworks,
    networks: [],
};
