import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'cosgrid-overlay-loader',
  templateUrl: 'loader.component.html',
})
export class LoaderComponent implements OnChanges, OnInit {
  @Input() show = true;
  @Input() fakeText = true;
  @Input() width = '600px'
  line1: string = ''
  line2: string = ''
  line3: string = ''
  line4: string = ''

  ngOnInit(): void {
    this.line1 = this.reduceWidth(this.width, 150);
    this.line2 = this.reduceWidth(this.width, 280);
    this.line3 = this.reduceWidth(this.width, 190);
    this.line4 = this.reduceWidth(this.width, 80);
  }

  reduceWidth(currentWidth: string, reduceBy: number): string {
    if (currentWidth.includes('px')) {
      const numericValue = parseInt(currentWidth, 10);
      const reducedWidth = numericValue - reduceBy;
      return `${reducedWidth}px`; // Return the new value with 'px'
    } else if (currentWidth.includes('%')) {
      // If the value is in percentage
      const numericValue = parseFloat(currentWidth); // Extract the percentage number
      const reducedWidth = numericValue - (reduceBy / 600 * 100); // Convert 150px to percentage of 600px
      return `${reducedWidth}%`; // Return the new percentage value
    } else {
      // Default case, return the original width if no recognizable unit is found
      return currentWidth;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      this.show = changes.show.currentValue;
    } catch (err) {
      this.show = false;
    }
  }
}
