import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-packetchart',
    templateUrl: './echart.component.html',
    styleUrls: ['./echart.component.scss'],
})
export class PacketChartComponent implements OnInit {
    @Input() title: string = 'Time Series';
    @Input() names: Array<string> = [];
    @Input() data: any = [];
    @Input() informationBox: { min: boolean; max: boolean; average: boolean; current: boolean } = {
        min: true,
        max: true,
        average: true,
        current: true,
    };
    @Input() axisTypes: Array<string> = ['time', 'value'];
    @Input() legendOrientation: string = 'vertical';
    chartType: 'line' = 'line';
    @Input() scatterChart: boolean = false;
    @Input() recordDuration: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years' = 'hours';
    @Input() yAxisType: 'memory' | 'cpu' = 'memory';
    @Input() lineColors: Array<string> = ['#0078be', '#6801af', '#ff560b', '#298c00'];
    @Input() timestamp: Array<string> | Array<number> = [];

    description: Array<any> = [];
    options: any;
    maxByteSize: number = 0;
    dataSize: string = 'b';
    maxTickLen: number = 0;

    ngOnInit(): void {
        this.createDescription();
        this.createChart();
    }

    createDescription(): void {
        this.data.length > 0 ? (this.description = []) : '';
        this.description = this.data.map((data: any) => ({
            min: this.tooltipValue(Math.min(...data)),
            max: this.tooltipValue(Math.max(...data)),
            average: this.tooltipValue(data.reduce((a: any, b: any) => a + b, 0) / data.length),
            current: this.tooltipValue(data[data.length - 1]),
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !changes.data.firstChange) {
            this.createDescription();
            this.createChart();
        }
    }

    createChart(): void {
        this.maxByteSize = Math.max(...this.data.flat());
        this.options = {
            animation: false,
            color: this.lineColors,
            title: {
                text: this.title,
            },
            axisPointer: {
                link: [
                    {
                        xAxisIndex: 'all',
                    },
                ],
            },
            tooltip: {
                trigger: 'axis',
                textStyle: {
                    fontSize: 13,
                    lineHeight: 21,
                },
                backgroundColor: 'rgba(52, 58, 64, 0.95)',
                formatter: (params: any) => {
                    const date = new Date(params[0].axisValue).toLocaleString();
                    const seriesInfo = params
                        .map(
                            (param: any) =>
                                `<tr>
              <td>${param.marker} <span style="color:rgba(240, 240, 240, 0.7)">${param.seriesName}</span></td>
              <td><b>${this.tooltipValue(param.value[1])}</b></td>
            </tr>`,
                        )
                        .join(''); // Added .join('') to convert array to string
                    return `${date}<br><table>${seriesInfo}</table>`;
                },
            },
            grid: [
                {
                    height: '32%',
                },
                {
                    top: '57%',
                    height: '32%',
                },
            ],
            xAxis: this.createXAxis(),
            yAxis: this.createYAxis(),
            legend: {
                show: true,
                type: 'scroll',
                orient: this.legendOrientation,
                right: '10',
                top: 'middle',
                formatter: (name: string) => (name.length > 15 ? `${name.slice(0, 15)}...` : name),
                icon: 'roundRect',
                backgroundColor: '#fff',
            },
            toolbox: {
                show: true,
                feature: {
                    restore: { show: true, title: 'Restore' },
                    dataView: { show: true, title: 'Data View' },
                },
            },
            series: this.getSeriesData(),
        };
    }

    tooltipValue(value: number) {
        if (value.toString().includes('.')) {
            switch (value.toString().split('.')[1].length) {
                case 1:
                case 2:
                    return value;
                default:
                    return value.toFixed(3);
            }
        } else return value;
    }

    getSeriesData() {
        return this.names.map((name, index) => ({
            xAxisIndex: index % 2,
            yAxisIndex: index % 2,
            id: index,
            name: name || 'Time Series Graph',
            type: this.chartType,
            showSymbol: false,
            data: this.timestamp.map((timestamp, i) => [timestamp, this.data[index][i]]),
            lineStyle: {
                color: this.lineColors[index % this.lineColors.length],
                width: this.scatterChart ? 1 : 2,
            },
            areaStyle: {
                color: this.lineColors[index % this.lineColors.length],
                opacity: this.scatterChart ? 0.1 : 0,
            },
        }));
    }

    createXAxis() {
        return this.names.map((name, index) => ({
            show: index % 2 === 0,
            type: this.axisTypes[0],
            data: this.timestamp,
            axisLine: { show: false },
            splitLine: { show: false },
            axisTick: { length: 0 },
            splitNumber: 10,
            gridIndex: index % 2 === 0 ? 0 : 1,
            axisLabel: {
                color: '#8d959d',
                formatter: (value: string, index: number) => {
                    const date = new Date(value);
                    switch (this.recordDuration) {
                        case 'seconds':
                            return date.toLocaleTimeString([], { minute: '2-digit', second: '2-digit' });
                        case 'minutes':
                        case 'hours':
                            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        case 'days':
                        case 'weeks':
                            return date.toLocaleDateString([], { month: 'short', day: '2-digit' });
                        case 'months':
                            return date.toLocaleDateString([], { month: 'short', year: '2-digit' });
                        default:
                            return date.toLocaleDateString([], { year: 'numeric' });
                    }
                },
            },
        }));
    }

    createYAxis() {
        return this.names.map((name, index) => ({
            inverse: index % 2 === 1,
            gridIndex: index % 2 === 0 ? 0 : 1,
            type: this.axisTypes[1],
            axisLine: { show: false },
            splitNumber: 5,
            axisTick: {
                length: 5,
            },
            splitLine: {
                show: true,
                lineStyle: { color: ['#e9ecef'] },
            },
            axisLabel: {
                color: '#8d959d',
                formatter: (value: number) => {
                    if (value.toString().includes('.')) {
                        switch (value.toString().split('.')[1].length) {
                            case 1:
                            case 2:
                                return value;
                            default:
                                return value.toFixed(3);
                        }
                    } else return value;
                },
            },
        }));
    }

    // calculateInterval(): number {
    //   if (this.maxByteSize >= 1048576) {
    //     return Math.ceil(this.maxByteSize / 1048576 / 5) * 1048576;
    //   } else if (this.maxByteSize >= 1024) {
    //     return Math.ceil(this.maxByteSize / 1024 / 5) * 1024;
    //   } else {
    //     return Math.ceil(this.maxByteSize / 5);
    //   }
    // }
}
