import { flatMap, values } from 'lodash';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'cosgrid-echarts-barchart',
  templateUrl: './echarts-barchart.component.html',
  styleUrls: ['./echarts-barchart.component.scss']
})
export class EchartsBarchartComponent implements AfterViewInit {
  @Input() title: string;
  @Input() graphs: Array<string> | null = null
  @Input() data: Array<string | number> = []
  @Input() axisTypes: Array<string> = ['category', 'value'];
  @Input() value: Array<string | number> | Array<Array<string | number>> = []
  @Input() legendOrientation: string = 'vertical';
  @Input() chartType: 'line' | 'bar' = 'line';
  @Input() scatterChart: boolean = false;
  @Input() colors: Array<string> = ['#0078be', '#00b3f0', '#6801af', '#bb64ff', '#ff560b', '#ffa608', '#298c00', '#a9ce21', '#58595b', '#939598'];
  @Input() showLegend: boolean = true;
  @Input() animation: boolean = false;
  @Input() valueYSuffix: string = '';
  @Input() valueXSuffix: string = '';
  @Input() tooltipSuffix: string = '';
  @Input() xAxisLabelInterval: number = 0
  @Input() xAxisRotate: number = 0
  options: any;
  constructor(private cdf: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.cdf.detectChanges()
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.firstChange) {
      this.createChart();
    }
  }

  createChart() {
    this.options = {
      animation: this.animation,
      color: this.colors,
      title: {
        show: this.title,
        text: this.title
      },
      grid: {
        containLabel: true // Ensures that labels are included within the grid
      },
      tooltip: {
        trigger: 'axis',
        textStyle: {
          fontSize: 13,
          lineheight: 21
        },
        backgroundColor: 'rgba(52, 58, 64, 0.95)',
        formatter: ((params: any) => {
          console.log(params[0]);

          const seriesInfo = params
            .map(
              (param: any) =>
                `<tr>
              <td>${param.marker} <span style="color:rgba(240, 240, 240, 0.7)">${param.value[this.axisTypes[0] == 'category' ? 0 : 1]}</span></td>
              <td><b>${param.value[this.axisTypes[0] == 'category' ? 1 : 0]} ${this.tooltipSuffix}</b></td>
            </tr>`,
            )
            .join(''); // Added .join('') to convert array to string
          return `<table>${seriesInfo}</table>`;
        })
      },
      xAxis: [
        {
          type: this.axisTypes[0],
          data: this.data,
          axisLine: { show: false },
          splitLine: { show: false },
          axisLabel: {
            color: '#6d7680',
            interval: this.xAxisLabelInterval,
            rotate: this.xAxisRotate,
            formatter: ((value: any) => {
              return this.valueXSuffix != '' ? value + ' ' + this.valueXSuffix : value
            })
          }
        }
      ],
      yAxis: [
        {
          type: this.axisTypes[1],
          axisLine: { show: false },
          splitNumber: 5,
          axisTick: {
            length: this.axisTypes[1] == 'values' ? 0 : 5
          },
          splitLine: {
            show: true,
            lineStyle: { color: ['#e9ecef'] },
          },
          axisLabel: {
            color: '#6d7680',
            interval: 0,
            formatter: ((value: any) => {
              return this.valueYSuffix != '' ? value + ' ' + this.valueYSuffix : value
            })
          }
        }
      ],
      legend: {
        show: this.showLegend,
        type: 'scroll',
        orient: this.legendOrientation,
        right: '0',
        top: 'middle',
        formatter: (name: string) => (name.length > 15 ? `${name.slice(0, 15)}...` : name),
        icon: 'roundRect',
        backgroundColor: '#fff',
      },
      series: this.getSeriesData()
    }
  }

  getSeriesData() {
    if (this.graphs == null) {
      return [{
        id: Math.floor(Math.random() * 256),
        name: this.title,
        type: this.chartType,
        showSymbol: false,
        colorBy: "data",
        data: this.data.map((x: string | number, i: number) => ({
          value: [x, this.value[i]],
          itemStyle: {
            color: this.colors[this.axisTypes[0] == 'category' ? (i % this.colors.length) : (this.colors.length - 1 - (i % this.colors.length))]
          }
        })),
        lineStyle: {
          color: this.colors,
          width: this.scatterChart ? 1 : 2,
        },
        areaStyle: {
          color: this.colors,
          opacity: this.scatterChart ? 0.1 : 0,
        },
      }]
    } else {
      return this.graphs.map((name, index) => ({
        id: index,
        name: name || 'Graph',
        type: this.chartType,
        showSymbol: false,
        data: this.data.map((data, i) => [data, this.data[index][i]]),
        lineStyle: {
          color: this.colors,
          width: this.scatterChart ? 1 : 2,
        },
        areaStyle: {
          color: this.colors,
          opacity: this.scatterChart ? 0.1 : 0,
        },
      }));
    }
  }

}
