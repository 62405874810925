import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SDWanGatewayUtilService } from 'app/modules/sd-wan-gateway/common/services/sd-wan-gateway.util.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertService } from 'app/shared/services/misc';
import { GlobalService } from 'app/shared/services/core';

export const _filter = (opt: string[], value: string): string[] => {
    const filterValue = value.toLowerCase().replace(/\s/g, '');
    return opt.filter((item) => item.toLowerCase().replace(/\s/g, '').includes(filterValue));
};

@Component({
    selector: 'cosgrid-search-features',
    templateUrl: './search-features.component.html',
    styleUrls: ['./search-features.component.scss'],
})
export class SearchFeaturesComponent implements OnInit {
    constructor(
        private router: Router,
        private sdWanGatewayUtilService: SDWanGatewayUtilService,
        private fb: UntypedFormBuilder,
        private alert: AlertService,
        private dialogRef: MatDialogRef<SearchFeaturesComponent>,
        private global: GlobalService,
    ) { }
    featureForm: UntypedFormGroup = this.fb.group({
        featureGroup: '',
    });
    featureGroups = [
        {
            group: 'Interfaces & VLANs',
            names: [
                'Configure - Interfaces & VLANs - LAN Interfaces',
                'Configure - Interfaces & VLANs -  WAN Interfaces ',
            ],
            routes: ['device/configure/addressing-vlan/laninterface', 'device/configure/addressing-vlan/waninterface'],
        },
        {
            group: 'Static Route',
            names: ['Configure - Static Route '],
            routes: ['device/configure/staticroutes'],
        },
        {
            group: 'SD-WAN',
            names: ['Configure - SD WAN - Interfaces  ', 'Configure - SD WAN - Rules '],
            routes: ['device/configure/sdwan/interfaces', 'device/configure/sdwan/rules'],
        },
        {
            group: 'IPSec VPN',
            names: ['Configure - IPSec VPN - IPSec VPN  ', 'Configure - IPSec VPN - Auto VPN '],
            routes: ['device/configure/vpn-creation/ipsecvpn', 'device/configure/vpn-creation/autovpn'],
        },
        {
            group: 'Firewall',
            names: [
                'Configure - Firewall - Zone ',
                'Configure - Firewall - Inbound Rules ',
                'Configure - Firewall - Outbound Rules ',
                'Configure - Firewall - IPSet ',
            ],
            routes: [
                'device/configure/firewall/zones',
                'device/configure/firewall/inboundrules',
                'device/configure/firewall/outboundrules',
                'device/configure/firewall/ipset',
            ],
        },
        {
            group: 'Link Bonding',
            names: ['Configure - Link Bonding - VPN Bonding ', 'Configure - Link Bonding - Internet Bonding '],
            routes: ['device/configure/link-bonding/vpn-bonding', 'device/configure/link-bonding/internet-bonding'],
        },
        {
            group: 'Network Acceleration',
            names: ['Configure - Network Acceleration '],
            routes: ['device/configure/network-acceleration'],
        },
        {
            group: 'QOS',
            names: ['Configure - QOS - Interface ', 'Configure - QOS - Rule ', 'Configure - QOS - Class '],
            routes: [
                'device/configure/qos/qos_interface',
                'device/configure/qos/qos_rules',
                'device/configure/qos/qos_class',
            ],
        },
        {
            group: 'Services',
            names: [
                'Configure - Services - DHCP ',
                'Configure - Services - SNMP ',
                'Configure - Services - NTP ',
                'Configure - Services - Backup and Restore Config ',
                'Configure - Services - Logging ',
                'Configure - Services - SSH ',
                'Configure - Services - HTTP ',
                'Configure - Services - Alarms ',
                'Configure - Services - Notification ',
            ],
            routes: [
                'device/configure/services/dhcp',
                '/device/configure/services/snmp',
                'device/configure/services/ntp',
                'device/configure/services/config',
                '/device/configure/services/logging',
                'device/configure/services/dropbear',
                'device/configure/services/http',
                'device/configure/services/alarms',
                'device/configure/services/notification',
            ],
        },
        {
            group: 'NAT',
            names: ['Configure - NAT '],
            routes: ['device/configure/nat', ''],
        },
        {
            group: 'Wireless',
            names: ['Configure - Wireless- Device ', 'Configure - Wireless - Interface '],
            routes: ['device/configure/wireless/wirelesssetting', 'device/configure/wireless/wirelessadvanced'],
        },
        // {
        //   group: 'BGP',
        //   names: ['Configure - BGP '],
        //   routes: ['device/configure/bgp'],
        // },
        {
            group: 'Policy Routing',
            names: ['Configure - Policy Routing '],
            routes: ['device/configure/policyrouting'],
        },
        {
            group: 'Content Filtering',
            names: [
                'Configure - Content FIltering - Category Filtering ',
                'Configure - Content FIltering - URL Blocking ',
            ],
            routes: ['device/configure/content-filter/category-filter', 'device/configure/content-filter/url-blocking'],
        },
        {
            group: 'URL Filtering',
            names: ['Configure - URL Filtering '],
            routes: ['device/configure/url-filter'],
        },
        {
            group: 'Netflow',
            names: ['Configure - Netflow '],
            routes: ['device/configure/netflowconfig'],
        },
        {
            group: 'VRRP',
            names: ['Configure - VRRP '],
            routes: ['device/configure/vrrp'],
        },
        {
            group: 'Diagnostics',
            names: [
                'Configure - Diagnostics - Ping  ',
                'Configure - Diagnostics - Trace Route ',
                'Configure - Diagnostics - DNS ',
                'Configure - Diagnostics - Packet Trace ',
                'Configure - Diagnostics - Throughput ',
            ],
            routes: [
                '/device/configure/diagnostics/ping',
                '/device/configure/diagnostics/ping',
                '/device/configure/diagnostics/dns',
                '/device/configure/diagnostics/tcpDump',
                '/device/configure/diagnostics/throughput',
            ],
        },
        {
            group: 'Device Overview',
            names: [
                'Monitor - Device Overview - Interfaces',
                'Monitor - Device Overview - System Info ',
                'Monitor - Device Overview - CPU Usage ',
                'Monitor - Device Overview - Memory Usage ',
            ],
            routes: [
                'device/configure/status/deviceoverview/device-interfaces',
                'device/configure/status/deviceoverview/sysInfo',
                'device/configure/status/deviceoverview/cpugraph',
                'device/configure/status/deviceoverview/memorygraph',
            ],
        },
        {
            group: 'Network',
            names: [
                'Monitor - Network - Routing Table ',
                'Monitor - Network - ARP Table ',
                'Monitor - Network - Interfaces ',
                'Monitor - Network - Device Status ',
                'Monitor - Network - DHCP Leases ',
            ],
            routes: [
                'device/status/network/routes',
                '/device/status/network/arptables',
                '/device/status/network/interfaces',
                '/device/status/network/devicestatus',
                'device/status/network/dhcp',
            ],
        },
        {
            group: 'SD-WAN',
            names: ['Monitor - SD WAN '],
            routes: ['device/status/sdwanstatus'],
        },
        {
            group: 'Tunnel',
            names: ['Monitor - Tunnel - IPSec ', 'Monitor - Tunnel - Link Bonding '],
            routes: ['device/status/ipsec/overview', 'device/status/ipsec/statistics'],
        },
        {
            group: 'Firewall',
            names: ['Monitor - Firewall - Firewall  ', 'Monitor - Firewall - IPSet '],
            routes: ['device/status/firewallstatus/firewall-all', 'device/status/firewallstatus/ipset'],
        },
        // {
        //   group: 'Routing',
        //   names: ['Monitor - Routing - VRRP  ', 'Monitor - Routing - BGP '],
        //   routes: ['device/status/routing/vrrpstatus', 'device/status/routing/bgpstatus'],
        // },
        {
            group: 'Usage Stats',
            names: [
                'Monitor - Usage Stats - URL Filter  ',
                'Monitor - Usage Stats - URL Tracking ',
                'Monitor - Usage Stats - Usage Status ',
            ],
            routes: [
                'device/status/url-ops/urlfilterstatus ',
                'device/status/url-ops/urltracking',
                'device/status/url-ops/usagestatus',
            ],
        },
        {
            group: 'Link Stats',
            names: [
                'Monitor - Link Stats - WAN Interface  ',
                'Monitor - Link Stats -LAN Interface ',
                'Monitor - Link Stats -Link Quality ',
                'Monitor - Link Stats -WAN Traffic ',
                'Monitor - Link Stats -RTT Comparsion ',
                'Packet Loss Comparsion ',
            ],
            routes: [
                'device/status/slastatus/interface',
                'device/status/slastatus/interfacesingle',
                'device/status/slastatus/ping',
                'device/status/slastatus/wantraffic',
                'device/status/slastatus/linkcomparision2',
                'device/status/slastatus/linkcomparision',
            ],
        },
        {
            group: 'Logs',
            names: ['Monitor - Logs  '],
            routes: ['device/status/logs'],
        },
        {
            group: 'Netflow',
            names: [
                'Monitor - Netflow - Top Protocols  ',
                'Monitor - Netflow - Top Destination ',
                'Monitor - Netflow - Top Talkers ',
                'Monitor - Netflow - Top Server Ports ',
                'Monitor - Netflow - Top Client Ports ',
            ],
            routes: [
                'device/status/netflow/topProtocols',
                'device/status/netflow/topDestinations',
                'device/status/netflow/topTalkers',
                'device/status/netflow/topServerPorts',
                'device/status/netflow/topClientPorts',
            ],
        },
        {
            group: 'Alarms',
            names: [
                'Monitor - Alarms - Device  ',
                'Monitor - Alarms -WAN ',
                'Monitor - Alarms -Performance ',
                'Monitor - Alarms -IPSec ',
            ],
            routes: [
                'device/status/alarms/device',
                'device/status/alarms/wan',
                'device/status/alarms/performance',
                'device/status/alarms/ipsec',
            ],
        },

        {
            group: 'Networks',
            names: ['Networks - Network Overview ', 'Networks - Monitor'],
            routes: ['network/configure', 'network/monitor'],
        },
        {
            group: 'Inventory',
            names: ['Organization - Inventory '],
            routes: ['organization/inventory'],
        },

        {
            group: 'Monitor',
            names: [
                'Organization - Monitor - IPSec VPN',
                'Organization - Monitor - Global Alarms',
                'Organization - Monitor - BGP Global',
                'Organization - Monitor - Interfaces Global',
            ],
            routes: [
                'organization/monitor/vpn-status',
                'organization/monitor/alarms/device',
                'organization/monitor/globalBGP',
                'organization/monitor/globalInterface',
            ],
        },
        {
            group: 'System Tools',
            names: [
                'Organization - System Tools - Users',
                'Organization - System Tools - Global Notification',
                'Organization - System Tools -Report',
            ],
            routes: [
                'organization/systemtools/users',
                'organization/systemtools/globalNotification',
                'organization/systemtools/reportGeneration',
            ],
        },
        {
            group: 'Config Templates',
            names: ['Config Templates', 'User -Defined Apps'],
            routes: ['organization/configtemp/config', 'organization/configtemp/udt'],
        },
        {
            group: 'Support',
            names: ['Support - Raise a Ticket', 'Support - Track Status'],
            routes: ['support/ticketRaise', 'support/trackTicket'],
        },
    ];

    featureGroupOptions: Observable<any[]>;

    ngOnInit() {
        // this.inpRef =  document.getElementById('input') as HTMLInputElement
        this.featureGroupOptions = this.featureForm.get('featureGroup')!.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterGroup(value)),
        );
    }

    private _filterGroup(value: string) {
        if (value) {
            return this.featureGroups
                .map((group) => ({ group: group.group, names: _filter(group.names, value) }))
                .filter((group) => group.names.length > 0);
        }

        return this.featureGroups;
    }

    onEnter() {
        this.gotoOption();
    }

    onEsc() {
        this.dialogRef.close();
    }

    isDeviceSelected(): boolean {
        try {
            // let x = JSON.parse(localStorage.getItem('selectedDevice'));
            let x = this.sdWanGatewayUtilService.getDeviceId();
            if (x === null || x === '' || x === undefined || x === '-1') {
                return false;
            } else {
                return true;
            }
        } catch (err) {
            return false;
        }
    }

    gotoOption() {
        if (
            this.sdWanGatewayUtilService.getDeviceId() !== '-1' &&
            this.sdWanGatewayUtilService.getDeviceId() !== undefined
        ) {
            this.global.searchText.next(this.featureForm.get('featureGroup').value);
            let chosenOption = this.featureForm.get('featureGroup').value;

            for (let i = 0; i < this.featureGroups.length; i++) {
                let index = this.featureGroups[i].names.findIndex((x) => {
                    return x === chosenOption;
                });

                if (index != -1) {
                    this.dialogRef.close();
                    this.router.navigateByUrl(this.featureGroups[i].routes[index]);
                    break;
                }
            }
            this.featureForm.get('featureGroup').reset();
            // inp.blur() // after navigating blur the input field
        } else {
            this.alert.showSelectDevice();
            this.featureForm.get('featureGroup').reset();
            this.dialogRef.close('selectDevice');
        }
    }
}
