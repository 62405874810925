import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CheckVersionService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private http: HttpClient, private handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }

    //  public initVersionCheck(url, frequency = 1000 * 60 * 30) {
    //      setInterval(() => {
    //          this.checkVersion(url);
    //      }, frequency);
    //  }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    public checkVersion() {
        // timestamp these requests to invalidate caches
        this.http
            .get('https://cosgrid.net/version.json' + '?t=' + new Date().getTime())
            .pipe(first())
            .subscribe(
                (response: any) => {
                    try {
                        const hash = response.hash;
                        const hashChanged = this.hasHashChanged(this.currentHash, hash);

                        // If new version, do something
                        if (hashChanged) {
                            location.reload();
                            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                            // for an example: location.reload();
                        } else {
                        }
                        // store the new hash so we wouldn't trigger versionChange again
                        // only necessary in case you did not force refresh
                        this.currentHash = hash;
                    } catch (err) {}
                },
                (err) => {
                    console.error(err, 'Could not get version');
                },
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        return currentHash !== newHash;
    }
}
