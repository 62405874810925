import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCase',
})
export class CamelCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return value;
        }
        // Convert to camel case
        return value
            .split('_')
            .map((string: string) => string.charAt(0).toUpperCase() + string.slice(1))
            .join(' ');
    }
}
