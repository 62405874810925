<hr />
<div class="d-flex justify-content-center align-items-center pb-3">
    <div class="popup bg-dark">
        <button *ngIf="data" [disabled]="!valid" class="btn btn-dark cancelModalButton" (click)="toggleRPopup($event)">
            <i class="material-icons">
                lock_reset
            </i>
            Reset
        </button>
        <span class="popuptext text-dark bg-light" id="myRPopup">
            <h5>Are you sure?</h5>
            <br />
            <p class="pb-4">Changes would Reset!</p>
            <div style="margin-top: 2px;" class="d-flex justify-content-around">
                <button (click)="toggleRPopup($event)" class="btn btn-sm btn-danger">
                    <i style="color: #fff; margin-right: 6px;" class="fa fa-times" aria-hidden="true"></i>
                    No
                </button>
                <button (click)="resetModal()" class="btn btn-sm btn-success">
                    <i class="fa fa-check" style="margin-right: 6px;" aria-hidden="true"></i>
                    Yes
                </button>
            </div>
        </span>
    </div>

    <div>
        <button
            *ngIf="data"
            [disabled]="!valid"
            (click)="callEdit()"
            type="button"
            class="btn ml-3 btn-outline-info saveModalButton"
        >
            <i *ngIf="!requestLoading" class="material-icons">
                check
            </i>
            Save changes
            <i *ngIf="requestLoading" class="fas fa-circle-notch fa-spin"></i>
        </button>
        <button *ngIf="!data" [disabled]="!valid" (click)="callAdd()" type="button" class="btn addModalButton">
            <i class="fas fa-solid fa-plus" style="margin-right: 5px; margin-top: -3px;"></i>
            Add
            <i *ngIf="requestLoading" class="fas fa-circle-notch fa-spin"></i>
        </button>
    </div>
</div>
