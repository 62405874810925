<div *ngIf="isData"  class="chartContainer"  >
  <div class="up" >
      <i class="fas fa-arrow-up"></i>
    <svg  height="16" width="64">
      <polygon  [attr.points]="'0 15 '+upPoints+' 64 15'"   fill="#F8D7DA"
      ></polygon>
      <polyline [id]="uid" fill="transparent"
        [attr.points]="upPoints"
        stroke="red" stroke-width="1" stroke-linecap="square"></polyline>
    </svg>
    <div class="data">
      {{ this.upLatestValue? (this.upLatestValue | formatBytes:'bits')+'ps':'' }}
    </div>
  </div>

  <div class="down" >
      <i class="fas fa-arrow-down"></i>
    <svg  height="16" width="64">
        <polygon  [attr.points]="'0 15 '+downPoints+' 64 15'"  fill="lightgreen"
        ></polygon>
      <polyline [id]="uid" fill="transparent" stroke="green" stroke-width="1"
        [attr.points]="downPoints">
      </polyline>
    </svg>
    <div class="data">
        {{ this.downLatestValue? (this.downLatestValue | formatBytes:'bits')+'ps':''}}
      </div>
  </div>
</div>
