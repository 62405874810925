import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/configs/constants';

@Injectable({
    providedIn: 'root',
})
export class SupportService {
    constructor(private http: HttpClient, private globalConstants: GlobalConstants) {}

    postForm(data) {
        return this.http.post(this.globalConstants.TENANT_SUPPORT, data);
    }

    getIssues(tenantname) {
        return this.http.post(this.globalConstants.TENANT_SUPPORT, {
            type: 'get',
            tenant_name: tenantname,
        });
    }

    changeIssueStatus(data) {
        return this.http.put(this.globalConstants.TENANT_SUPPORT + data.id + '/', data);
    }

    getAllTickets() {
        return this.http.get(this.globalConstants.TENANT_SUPPORT);
    }
}
