<div class="crash-container">
    <div class="content">
        <div class="crash-details">
            <div class="title">Something Went Wrong!</div>
            <button (click)="tryAgain()" class="btn btn-light">Try Again</button>
            <button (click)="copyError()" class="btn btn-light">Copy Error to Clipboard</button>
            <button (click)="contactSupport()" class="btn btn-light">Contact Support</button>
        </div>
    </div>
</div>
