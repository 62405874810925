import { Action } from '@ngrx/store';
import { User } from 'app/core/models/user';
import { Tenant } from 'app/shared/model';

// store the selected customer
export const SetCurrentUser = 'Set Current User';
export class SET_CURRENT_USER implements Action {
    readonly type = SetCurrentUser;
    constructor(public currentUser: User) {}
}

// store the tenantID
export const SetTenantID = 'Set Tenant ID';
export class SET_TENANT_ID implements Action {
    readonly type = SetTenantID;
    constructor(public tenantID: string) {}
}

// store the tenant name
export const SetTenantName = 'Set Tenant Name';
export class SET_TENANT_NAME implements Action {
    readonly type = SetTenantName;
    constructor(public tenantName: string) {}
}

// store the tenants in case of super admin
export const SetTenants = 'Set Tenants';
export class SET_TENANTS implements Action {
    readonly type = SetTenants;
    constructor(public tenants: Tenant[]) {}
}

// store the tenants in case of super admin
export const SetSelectedTenant = 'Set Selected Tenant';
export class SET_SELECTED_TENANT implements Action {
    readonly type = SetSelectedTenant;
    constructor(public tenant: Tenant) {}
}
export type Actions = SET_CURRENT_USER | SET_TENANT_ID | SET_TENANT_NAME | SET_TENANTS | SET_SELECTED_TENANT;
