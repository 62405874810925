import { DashboardConfService } from './modules/dashboard/services/dashboard-conf.service';
import { SETTING_ACTION } from './store/actions/settings.action';
import { GlobalService } from 'app/shared/services/core/global.service';
import { MatIconRegistry } from '@angular/material/icon';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from 'app/modules/auth/services/authentication.service';
import { UserService, WebsocketService } from 'app/shared/services/core';
import { Router } from '@angular/router';
import { RouteConstants } from 'app/configs/constants';
import { StorageService, HttpRequestCancelService } from 'app/shared/services/misc';
import { PushNotifictaionService } from 'app/core/services/push-notifictaion.service';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { delay, filter, firstValueFrom, map, Observable, retryWhen, Subscription, tap } from 'rxjs';
@Component({
    moduleId: module.id,
    selector: 'cosgrid-app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    sessionTimeoutCount: number;
    remembermeLoader = false;

    isLoggedIn = false;

    isConnected: Observable<boolean>;

    interval;
    tenantSubscription: Subscription;

    //jwtHelper: JwtHelper = new JwtHelper();
    jwtHelper = new JwtHelperService();
    public isAuthenticated$!: Observable<boolean>;
    constructor(
        private global: GlobalService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        @Inject(DOCUMENT) doc: any,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private router: Router,
        //private httpUtil: HttpUtil,
        private storage: StorageService,
        private wsService: WebsocketService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private authservice: AuthenticationService,
        private dashboard: DashboardConfService,
        private store: Store<CosgridAppStore>,
        private _oktaStateService: OktaAuthStateService,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private pushNotificationService: PushNotifictaionService,
        private httpRequestCancelService: HttpRequestCancelService, //public jwtHelper: JwtHelperService
    ) {
        // this.checkVerison.checkVersion();

        this.subscribeToIsLoggedIn();
        this.httpRequestCancelService.cancelPendingRequestsonRouteChange();
        // INITIAL SETUP
        this.registerGlobalIcons();
        this.getNotificationPermission();

        // if user goes offline and come online, reload the page.
        window.addEventListener('offline', () => {
            window.addEventListener('online', () => {
                this.snackBar.open("You're Online!", '', { duration: 1000 });
                location.reload(); // reload the page when the browser in online
                window.removeEventListener('online', () => { })
            });
        });

        // if user logout in one tab it need to logout all the tab and window by refreshing the page
        window.addEventListener('storage', this.checkUser.bind(this))

    }
    isTenantSelected;

    // END OF CONSTRUCTOR

    ngOnInit() {
        this.initialNavigate();
    }

    private checkUser(event: StorageEvent) {
        if (event.key === 'currentUser' && !localStorage.getItem('currentUser')) {
            window.location.reload(); //reload the page when user logout in one tab or one window
        }
    }

    navigateTo(url: string) {
        this.remembermeLoader = true;
        this.router.navigateByUrl(url);
    }

    async initialNavigate() {
        this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
            filter((s: AuthState) => !!s),
            map((s: AuthState) => s.isAuthenticated ?? false),
        );
        if (location.pathname.includes('free-trial')) {
            this.navigateTo('auth/free-trial')
        } else if (location.pathname.includes('sign-up')) {
            this.navigateTo('auth/sign-up')
        } else if (location.pathname.includes('microZAccess')) {
            let params = new URL(location.href).searchParams;

            if (params.get('payload')) {
                this.navigateTo(`auth/${RouteConstants.MICROZACCESS}?payload=${params.get('payload')}`)
            } else {
                this.navigateTo(`auth/${RouteConstants.MICROZACCESS}`)
            }
        } else if (location.href.includes('resetpwd')) {
            let params = new URL(location.href).searchParams;
            this.navigateTo(`auth/${RouteConstants.RESET_PASSWORD}?uid=${params.get('uid')}&token=${params.get('token')}`)
        } else if (location.href.includes('activate')) {
            this.remembermeLoader = true;
            let params = new URL(location.href).searchParams;
            this.navigateTo(`auth/${RouteConstants.ACTIVATE}?org=${params.get('org')}&uid=${params.get('uid')}&token=${params.get(
                'token',
            )}`)
        } else if (location.href.includes('cosgridsso')) {
            let params = new URL(location.href).searchParams;
            if (params.get('payload') === null) {
                this.navigateTo('auth/login')
            } else {
                this.navigateTo(`auth/${RouteConstants.COSGRIDSSO}?payload=${params.get('payload')}`)
            }
        } else {
            const token = this.userService.getToken();

            if (!this.jwtHelper.isTokenExpired(token)) {
                // if token is expired, probably it wont be a reload
                try {
                    this.remembermeLoader = true;
                    await this.getDashboardConfig();
                    this.global.isUserLoggedIn.next(true); // if user reloads the page
                    this.wsService.establishToken();
                } catch (error) {
                    this.navigateTo(RouteConstants.AUTH)
                }
            } else {
                try {
                    if (this.storage.getRememberMe()) {
                        this.rememberMeAction();
                    } else {
                        if (location.href.includes('activate')) {
                            this.authenticationService.accountActivateParams = location.href.split('?')[1];

                            let params = new URL(location.href).searchParams;
                            this.navigateTo(`auth/${RouteConstants.ACTIVATE}?org=${params.get('org')}&uid=${params.get(
                                'uid',
                            )}&token=${params.get('token')}`)
                        } else {
                            this.navigateTo(RouteConstants.AUTH)
                        }
                    }
                } catch (error) {
                    console.log(error);
                    this.router.navigateByUrl(RouteConstants.AUTH);
                }
            }
        }
    }

    subscribeToisTenantSelected() {
        try {
            this.tenantSubscription = this.store
                .select((slices) => slices.superAdminSlice.isTenantSelected)
                .subscribe(
                    (res) => {
                        this.isTenantSelected = res;
                    },
                    (err) => {
                        console.log(err);
                        this.isTenantSelected = false;
                    },
                );
        } catch (err) { }
    }

    async getDashboardConfig() {
        return new Promise((resolve, reject) => {
            (async () => {
                if (this.global.isSuperAdmin()) {
                    this.subscribeToisTenantSelected();
                    if (this.isTenantSelected) {
                        try {
                            // Use firstValueFrom to get the first emitted value from the observable
                            let data: any = await firstValueFrom(this.dashboard.getDashboardConf());

                            this.store.dispatch({
                                type: SETTING_ACTION,
                                payload: data.dashboard,
                            });
                            resolve('');
                        } catch (error) {
                            console.log(error);
                            reject(new Error('Failed to get dashboard configuration for super admin.'));
                        }
                    } else {
                        resolve('');
                    }
                } else {
                    try {
                        let data: any = await firstValueFrom(this.dashboard.getDashboardConf());

                        this.store.dispatch({
                            type: SETTING_ACTION,
                            payload: data.dashboard,
                        });
                        resolve('');
                    } catch (error) {
                        console.log(error);
                        this.authservice.logout();
                        reject(new Error('Failed to get dashboard configuration for non-super admin.'));
                    }
                }
            })();
        });
    }




    isRedirect() {
        try {
            // get the email id from URL params
            const userEmail = location.href.split('?')[1];
            if (userEmail) {
                // this.userService.setUserEmail(userEmail)
            }
        } catch (err) { }
    }

    subscribeToIsLoggedIn() {
        this.global.isUserLoggedIn.subscribe((value) => {
            // check whether user is logged in
            this.isLoggedIn = value;
        });
    }

    rememberMeAction() {
        // const token = localStorage.getItem('refresh');
        const token = this.encryptedLocalStorageService.getItem('refresh');
        this.authenticationService.rememberMe(token).subscribe(
            (data: any) => {
                if (data.user.isSuperAdmin) {
                    this.remembermeLoader = true;
                    this.router.navigateByUrl(RouteConstants.SUPER_ADMIN);
                } else {
                    this.remembermeLoader = true;
                    // TODO - remove this line
                    this.encryptedLocalStorageService.setItem('tenantID', data.tenant_id);
                    // this.appStore.dispatch(new SET_TENANT_ID(data.tenant_id))
                    this.router.navigateByUrl(RouteConstants.DASHBOARD);
                }

                this.userService.setUser(data.user);
                this.pushNotificationService.subscribeToPushNotiff();
                this.userService.setToken(data.token);
                //this.httpUtil.updateHeaders(data.token); // TODO: Completely remove http util service
                // TODO - remove next line
                this.encryptedLocalStorageService.setItem('tenantName', data.tenant);
                // this.appStore.dispatch(new SET_TENANT_ID(data.tenant))
                this.global.updateAuthStatus();
                this.wsService.establishToken();
            },
            (error) => {
                this.router.navigateByUrl(RouteConstants.AUTH_LOGIN);
                this.remembermeLoader = true;
            },
        );
    }

    registerGlobalIcons() {
        this.iconRegistry.addSvgIcon(
            'ndr-dash-logo',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ndr-dash-logo.svg')
        )
        this.iconRegistry.addSvgIcon(
            'waf-logo',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/WAF-logo.svg')
        )
        this.iconRegistry.addSvgIcon(
            'sdwan-logo',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sd-wan-logo.svg')
        );
        this.iconRegistry.addSvgIcon(
            'mza-logo',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mza-logo.svg')
        );
        this.iconRegistry.addSvgIcon(
            'team',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/teams.svg')
        );
        this.iconRegistry.addSvgIcon(
            'chrome',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/browser-icons/chrome.svg')
        );
        this.iconRegistry.addSvgIcon(
            'brave',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/browser-icons/brave.svg')
        );
        this.iconRegistry.addSvgIcon(
            'edge',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/browser-icons/edge.svg')
        );
        this.iconRegistry.addSvgIcon(
            'firefox',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/browser-icons/firefox.svg')
        );
        this.iconRegistry.addSvgIcon(
            'opera',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/browser-icons/opera.svg')
        );
        this.iconRegistry.addSvgIcon(
            'safari',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/browser-icons/safari-ios.svg')
        );
        this.iconRegistry.addSvgIcon(
            'wrong',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/not-applicable.svg')
        );
        this.iconRegistry.addSvgIcon(
            'correct',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/applicable.svg')
        );
        this.iconRegistry.addSvgIcon(
            'trash',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/trash.svg')
        );
        this.iconRegistry.addSvgIcon(
            'arrow-in',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-in.svg')
        );
        this.iconRegistry.addSvgIcon(
            'arrow-out',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-out.svg')
        );
        this.iconRegistry.addSvgIcon(
            'enabled',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/enabled.svg')
        );
        this.iconRegistry.addSvgIcon(
            'disabled',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/disabled.svg')
        );
        this.iconRegistry.addSvgIcon(
            'accept',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/firewallIcons/accept.svg '),
        );
        this.iconRegistry.addSvgIcon(
            'reject',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/firewallIcons/reject.svg '),
        );
        this.iconRegistry.addSvgIcon(
            'deny',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/firewallIcons/deny.svg '),
        );
        this.iconRegistry.addSvgIcon(
            'sort-up',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sortIcons/sort-up.svg '),
        );
        this.iconRegistry.addSvgIcon(
            'ndr',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ndr-logo.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'sort-down',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sortIcons/sort-down.svg '),
        );
        this.iconRegistry.addSvgIcon(
            'network',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/globalIcons/network.svg '),
        );
        this.iconRegistry.addSvgIcon('logs', this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/logs.svg'));
        this.iconRegistry.addSvgIcon(
            'sdWanSide',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/globalIcons/sdWanSide.svg '),
        );
        this.iconRegistry.addSvgIcon(
            'secureTunnel',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/globalIcons/ipsecTunnel.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'noLockTunnel',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/globalIcons/nolockTunnel.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'dashboard',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/dashboard.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'microzaccess',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/microzaccess.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'network',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/network.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'organization',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/organization.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'overlayServer',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/overlayServer.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'inventory',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/inventory.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'user',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/user.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'teams',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/teams.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'device',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/device.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'quickstart',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/quickstart.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'download',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/download.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'docs',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/docs.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'livechat',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/livechat.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'router',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/router.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'monitor',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/monitor.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'configure',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/configure.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'systools',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/systools.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'templates',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/templates.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'tools',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sidemenu/tools.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'notification',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/headers/notification.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'support',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/headers/support.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'setting',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/headers/setting.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'profile',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/headers/profile.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'totalDevice',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cardIcons/totalDevice.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'onlineDevice',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cardIcons/onlineDevice.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'totalUser',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cardIcons/totalUser.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'onlineUser',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cardIcons/onlineUser.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'google',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/settingsIcon/Google.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'okta',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/settingsIcon/Okta.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'azure',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/settingsIcon/Azure.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'onelogin',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/settingsIcon/onelogin.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'saml',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/settingsIcon/saml.svg'),
        );
        this.iconRegistry.addSvgIcon('upArrow', this.sanitizer.bypassSecurityTrustResourceUrl('../assets/upArrow.svg'));
        this.iconRegistry.addSvgIcon(
            'downArrow',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/downArrow.svg'),
        );
    }

    getNotificationPermission() {
        // only ask for permission for the first time
        if (Notification.permission !== 'denied') {
            Notification.requestPermission()
                .then((res) => { })
                .catch(console.error);
        }
    }

    // finds if the user reloaded the page or newly visits the page
    isReload(): boolean {
        try {
            const requestedUrl: string = location.href;
            const urlSeperator: string = this.global.isProduction() ? '.net' : '4200';
            return requestedUrl.split(urlSeperator)[1].length > 2 || requestedUrl.includes('/auth');
        } catch (err) { }
    }

    ngOnDestroy(): void {
        window.removeEventListener('storage', this.checkUser.bind(this))
        window.removeEventListener('offline', () => { })
    }
}
