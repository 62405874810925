import { AbstractControl } from '@angular/forms';
import { URL_REGEX } from 'app/configs/constants';

export function ValidateURL(control: AbstractControl) {
    if (control.value === '') {
        return null;
    } else if (!URL_REGEX.test(control.value)) {
        return {
            incorrectUrl: true,
        };
    }
    return null;
}
