<div class="table-wrapper">
  <div *ngIf="heading"  class="heading">
    <h3>{{heading}}</h3>
  </div>
  <div class="actions">
    <div class="action-buttons">
      <button (click)="downloadAllRows()" class="curvedBtn"> <i class="fas fa-cloud-download-alt"></i>
         Download
        </button>
    </div>
  </div>

  <div class="main">
    <table >
      <thead >
        <tr>
          <th *ngFor="let rowProp of rowProps">{{rowProp.header}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows" >
          <td  *ngFor="let rowProp of rowProps">
            <span>
              {{row[rowProp.key] | emptyFiller: rowProp.nullMessage }}
            </span>

        </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>