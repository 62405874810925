import { Component, Input, Output, OnInit, forwardRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
declare var $: any;

@Component({
    selector: 'cosgrid-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true,
        },
    ],
})
export class ToggleComponent implements ControlValueAccessor {
    // @Input() value;
    // @Output() valueChange = new EventEmitter()
    @Input() title = "Toggle Enable/Disable"

    toggleValue: boolean;
    onChange: Function;
    onTouched: Function;

    constructor() { }
    valueChanged() {
        this.onChange(this.toggleValue);
    }
    writeValue(value: any): void {
        if (value !== undefined) {
            this.toggleValue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
