import { Injectable } from '@angular/core';
import { ONLY_ALPHABETS, ONLY_NUMBERS } from 'app/configs/constants';

@Injectable()
export class InputValidationService {
    constructor() {}

    containsAlphabets(inputData: string) {
        const temp = ONLY_ALPHABETS.test(inputData);

        return temp;
    }
    containsOnlyNumbers(inputData: string) {
        return ONLY_NUMBERS.test(inputData);
    }
    keyCodeValidationForOnlyNumbers(key: number): boolean {
        return (key >= 48 && key <= 57) || key === 8 || key === 46 || (key >= 37 && key <= 40) || key === 0
            ? true
            : false;
    }
    specialCharacterValidation(key: number): boolean {
        const character = String.fromCharCode(key);
        return character != '%' && character != '&' && character != '(' && character != "'" ? true : false;
    }
}
