export const SETTING_ACTION = 'SETTING_ACTION';

export interface SettingAction {
    type: typeof SETTING_ACTION;
    payload: any;
}

export function createMyAction(payload: any): SettingAction {
    return {
        type: SETTING_ACTION,
        payload,
    };
}
