<div class="chartConatiner d-flex">

  <div class="p-3" style="flex: 1;">
    <div style="width: 100%;">
      <config-select className="col-3 float-right" [(ngModel)]="graphConfData1.timeduration"
        (change)="changeChart1($event.value)"
        [options]="[{view: '30 m',  value: 30}, {view: '1 hr',  value: 60}, {view: '3 hr',  value: 180}, {view: '12 hr',  value: 720}, {view: '24 hr',  value: 1440}]"
        viewKey="view" valueKey="value"></config-select>
    </div>
    <p style="height: 60px;"></p>
    <div *ngIf="isEmpty1 && !loading1" class="d-flex justify-content-center align-items-center m-2"
      style="height: 50vh; background-color: rgb(241, 241, 241);">
      <p>
        No data available yet!
      </p>
    </div>

    <div *ngIf="loading1" style="height: 20rem;">
      <cosgrid-custom-loader [cardLoading]="true"></cosgrid-custom-loader>
    </div>
    <div *ngIf="!isEmpty1 && !loading1"
      style="height: 330px;width: 100%;background-color: rgb(248, 248, 248); padding: 5px;">
      <cosgrid-echarts-barchart title="" [data]="barChartGraph['values']" [value]="barChartGraph['keys']"
        chartType="bar" [axisTypes]="['value', 'category']" tooltipSuffix="Mb"></cosgrid-echarts-barchart>
      <!-- <canvas id="tenantGraph1"></canvas> -->
      <!-- <cosgrid-user-level-graphs></cosgrid-user-level-graphs> -->
    </div>
  </div>

  <div class="p-3" style="flex: 1;">
    <div>
      <config-select className="col-3 float-right" (change)="changeChart($event.value, 'ip')"
        [(ngModel)]="graphConfData1Ip_addr" [options]="userIps" default="Select IP"></config-select>

      <config-select className="mx-1 col-3 float-right" (change)="changeChart($event, 'currentChart')"
        [(ngModel)]="currentChartId"
        [options]="[{view: 'Top Applications', value: 1}, {view: 'Top Protocols', value: 2}, {view: 'Top Ports', value: 3}]"
        viewKey="view" valueKey="value"></config-select>

      <config-select className="col-3 float-right" (change)="changeChart($event.value, 'duration')"
        [(ngModel)]="graphConfData.timeduration"
        [options]="[{view: '30 m',  value: 30}, {view: '1 hr',  value: 60}, {view: '3 hr',  value: 180}, {view: '12 hr',  value: 720}, {view: '24 hr',  value: 1440}]"
        viewKey="view" valueKey="value"></config-select>

    </div>
    <p style="height: 50px;"></p>
    <div *ngIf="isEmpty && !loading" class="d-flex justify-content-center align-items-center m-2"
      style="height: 50vh; background-color: rgb(241, 241, 241);">
      <p>
        No data available yet!
      </p>
    </div>
    <div *ngIf="loading" style="height: 20rem;">
      <cosgrid-custom-loader [cardLoading]="true"></cosgrid-custom-loader>
    </div>
    <div *ngIf="!isEmpty && !loading"
      style="height: 330px;width: 100%;margin-top: 10px;background-color: rgb(248, 248, 248); padding: 5px;">
      <pie-chart [data]="pieChartGraph['data']" [legendData]="pieChartGraph['labels']" title=""></pie-chart>
      <!-- <canvas *ngIf="!isEmpty" id="tenantGraph" style="width: 100%;height: 360px;"></canvas> -->
    </div>


  </div>
</div>