import { encryptStorage } from 'app/core/services/local-storage.service';
import { SuperAdminActionTypes, SuperAdminActions } from 'app/store/actions/super-admin.actions';
import { initialSuperAdminState } from 'app/store/state/super-admin.state';

export const superAdminFeatureKey = 'superAdmin';

export function reducer(state = initialSuperAdminState, action: SuperAdminActions): any {
    switch (action.type) {
        case SuperAdminActionTypes.isTenantSelected: {
            // localStorage.setItem('isTenantSelected',JSON.stringify(action.payload))
            encryptStorage.setItem('isTenantSelected', JSON.stringify(action.payload));
            return {
                ...state,
                isTenantSelected: action.payload,
            };
        }
        default:
            return state;
    }
}
