import * as SuperAdminActions from 'app/store/actions/super-admin.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';

@Injectable({
    providedIn: 'root',
})
export class SuperAdminListService {
    constructor(private store: Store<CosgridAppStore>) {}

    dispatchIsTenantSelectedToStore(isTenantSelected: boolean) {
        this.store.dispatch(new SuperAdminActions.isTenantSelected(isTenantSelected));
    }
}
