import * as DeviceActions from 'app/store/actions/device.actions';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/configs/constants';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { HttpClient } from '@angular/common/http';
import { DeviceList } from 'app/shared/model/device.list.model';
import { Subject } from 'rxjs';
import { GlobalService } from 'app/shared/services/core/global.service';

@Injectable()
export class DeviceListService {
    constructor(
        private global: GlobalService,
        private globalConstants: GlobalConstants,
        private appStore: Store<CosgridAppStore>,
        private customerListService: CustomerListService,
        private http: HttpClient,
    ) {}

    deviceList: DeviceList[] = [];
    deviceListSubject: Subject<any> = new Subject<any>(); // TODO: temporarily using it for sharing devicelist between dashboard and google map
    currentTenantId = this.customerListService.getTenantId();

    getDevicesUsingTenantId(tid?: any) {
        //
        if (this.global.isSuperAdmin()) {
            const temptid = tid ? tid : this.currentTenantId;
            //
            return this.http.post(this.globalConstants.ADMIN_DEVICE, { tenant_id: temptid }).subscribe((data) => {
                this.deviceList = [];
                const r: any = data;
                r.forEach((dev: any) => {
                    const d: any = {
                        id: dev.id,
                        name: dev.name,
                        macAddress: dev.macAddress,
                        // createdDateTime: dev['createdDateTime'],
                        deviceStatus: dev.deviceStatus,
                        // lastModifiedDateTime: dev['lastModifiedDateTime'],
                        geographicalZone: dev.geographicalZone,
                    };
                    this.deviceList.push(d);
                });
            });
        }
    }

    // returns devices based on data give,
    // data = network, tenant
    getDeviceList(data) {
        return this.http.post(this.globalConstants.DEVICE_LIST, data);
    }
    getListOfDevices() {
        return this.http.get(this.globalConstants.DEVICE_LIST);
    }
    dispatchAllDevicesToStore(listOfDevices: DeviceList[]) {
        this.appStore.dispatch(new DeviceActions.SET_LIST_OF_DEVICES(listOfDevices));
    }

    dispatchSelectedDeviceToStore(device: DeviceList) {
        this.appStore.dispatch(new DeviceActions.SET_SELECTED_DEVICE(device));
    }
}
