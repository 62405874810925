import { Component, OnInit, AfterViewInit, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CommonService, WebsocketService } from 'app/shared/services/core';
import { CustomerListService, DeviceListService, TableService } from 'app/shared/services/comp';
import { Router } from '@angular/router';
import { AlertService } from 'app/shared/services/misc';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopoverGraphComponent } from 'app/modules/dashboard/components/popover-graph/popover-graph.component';
import { StatusService } from 'app/modules/sd-wan-gateway/sub-modules/status/services/status.service';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { Network } from 'app/shared/model/network';

import * as _ from 'lodash';
import { DashboardConfService } from 'app/modules/dashboard/services/dashboard-conf.service';
import { take, takeUntil } from 'rxjs/operators';
import { DeviceFilterType } from 'app/store/state/dashboard.state';
import { SET_FILTER } from 'app/store/actions/dashboard.actions';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';
import { JsonDataService } from 'app/shared/services/core/country.data.service';
declare let $: any;

@Component({
    moduleId: module.id,
    selector: 'device-card',
    templateUrl: 'devicecard.component.html',
    styleUrls: ['./devicecard.component.scss'],
})
export class DeviceCardComponent implements OnInit, AfterViewInit, OnDestroy {
    private destroy$ = new Subject<void>()
    requestLoading: boolean = false;
    @Input() dashboardConf: any = {
        wan3: false,
        vpnStatus: false,
        commercialDashboard: false,
        pagination: {
            pageSize: 10,
            needPagination: false,
        },
    };

    rows: any[] = [];
    pager: any = {
        currentPage: 1,
        pageSize: 10,
    };
    pageSizes = [5, 10, 15, 20];
    response = false;
    selectedDev: any = -1;
    snackRef;

    temp: Array<any> = [];
    currentPageTemp: Array<any> = [];
    sortParameter = 'name';
    interval;
    selectedNetwork: Network = {
        networkName: '',
    };
    networkSubscription: Subscription;
    immedieateFallbackSubscription: Subscription;
    wsDataStreamSubscription: Subscription;

    isCommercial: boolean = this.dashboardConf.commercialDashboard;
    isWAN3: boolean = this.dashboardConf.wan3;
    isVPN: boolean = this.dashboardConf.vpnStatus;
    isPagination = false;

    commercialView = false;
    netTotal = 0;
    loading = false;

    deviceFilterType: DeviceFilterType;
    sortType = 'name';
    sortConf: any = {
        param: 'none',
        order: 'asc',
        ev: '',
    };
    upOrder = 'asc'; // default literal for ascending order
    searchText = '';
    isWan3 = false;
    error = false;

    tempEdit = {};
    isEdit = {};
    commercialData = {};

    isSN: boolean = this.customerListService.getTenantId() === '11';

    filterColor = {
        [DeviceFilterType.ALL]: '#3185FA',
        [DeviceFilterType.ONLINE]: 'lawngreen',
        [DeviceFilterType.OFFLINE]: 'red',
        [DeviceFilterType.UNDEPLOYED]: 'orange',
    };

    currentTenantId: string = this.customerListService.getTenantId() ? this.customerListService.getTenantId() : '';
    errCount = 0;
    vpnDashboard: any;
    constructor(
        private customerListService: CustomerListService,
        private deviceListService: DeviceListService,
        private store: Store<CosgridAppStore>,
        private router: Router,
        private statusService: StatusService,
        private alert: AlertService,
        private dialog: MatDialog,
        private wsService: WebsocketService,
        private tableService: TableService,
        private dashboard: DashboardConfService,
        private snackbar: MatSnackBar,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private countryDataService: JsonDataService,
        private commonService: CommonService
    ) {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
            });

        this.commonService.changeDashboardEmitter.subscribe(() => {
            this.rows = []
            this.ngOnDestroy()
            this.ngOnInit()
        })
    }

    ngOnInit() {
        this.wsService.errorEmitter.pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.requestLoading = value
        })
        this.countryCode = this.countryDataService.getCountryDetails()
        this.subscribeToSelectedNetwok();
        this.getDataFromDataEmitter();
        this.subscribeToDeviceFilter();
        // if (!this.globalService.isSuperAdmin()) {
        //   this.getCommercialData()
        // }
        // else if (this.customerListService.getTenantId()) {
        //   this.getCommercialData()
        // }
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            this.isCommercial = changes.dashboardConf.currentValue.commercialDashboard;
            this.isWAN3 = changes.dashboardConf.currentValue.wan3;
            this.isVPN = changes.dashboardConf.currentValue.vpnStatus;
        } catch (err) { }
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }

    getVpnDashboardValue() {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
            });
    }

    ngAfterViewInit(): void {
        // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        // Add 'implements AfterViewInit' to the class.
        $('[data-toggle=popover]').popover();
    }

    subscribeToDeviceFilter() {
        this.store
            .select((slices) => slices.dashboardSlice.deviceFilter)
            .subscribe((res) => {
                this.deviceFilterType = res;
                this.filterDevices();
            });
    }

    getDataFromDataEmitter() {
        if (!this.wsDataStreamSubscription) {
            this.subscribeToWsDataStream();
        }
    }

    getCommercialData() {
        return new Promise((resolve, reject) => {
            this.dashboard.getCommercialViewData().subscribe(
                (res) => {
                    this.commercialData = res;
                    setTimeout(() => {
                        this.parseCommercialData();
                        resolve(true);
                    }, 200);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    postCommercialData() {
        this.dashboard.postCommercialViewData(this.commercialData).subscribe(
            (res) => {
                this.alert.swalSuccess('Commercial Data Updated');
                this.getCommercialData();
            },
            (err) => {
                this.alert.snack('There was an error in updating data', 'OK');
                this.getCommercialData();
            },
        );
    }

    updateCommercialData() {
        this.dashboard.postCommercialViewData(this.commercialData).subscribe();
    }

    parseCommercialData() {
        try {
            this.rows.forEach((row) => {
                for (const i in row.wan_status) {
                    const wan = row.wan_status[i].name;
                    if (!(wan in this.commercialData[row.id])) {
                        this.commercialData[row.id][wan] = {
                            amount: 0,
                            date: new Date().toISOString(),
                            lastUpdated: new Date().toISOString(),
                        };
                    }
                }
            });
            this.updateCommercialData();
            this.calculateTotal();
        } catch (err) { }
    }

    subscribeToWsDataStream() {
        let snackRef;
        this.wsDataStreamSubscription = this.wsService.getDataEmitter().subscribe((res) => {
            if (snackRef) {
                this.snackbar.dismiss(); // dismiss the snackbar, if the socket is connected again.
            }

            if (res.deviceinfo && !this.commercialView) {
                this.alert.dismissCurrent(); // to dismiss the Network Error, Unable to connect to server wrong snack if is back online
                const data = res.deviceinfo;
                this.rows = JSON.parse(JSON.stringify(data));
                console.log(this.rows, "rows");

                if (this.vpnDashboard) this.addCountryFlags(this.rows);
                this.response = true;
                this.error = false;
                this.deviceListService.deviceListSubject.next(data);
                if (!this.vpnDashboard) {
                    this.isWan3 = this.isWan3Available(this.rows);
                }
                this.deviceListService.dispatchAllDevicesToStore(data);
                this.deviceListService.deviceList = this.rows;
                this.temp = [...this.rows]; // deepcopy

                this.filterDevices(); // apply filter when recieving new data
                // this.sortDevices() // apply sort when recieving new data
                setTimeout(() => {
                    this.searchRows();
                }, 100);
            } else if ('error' in res) {
                this.response = true;
                this.error = true;
                snackRef = this.alert
                    .dismissableSnack('Network Error, Unable to connect to server', 'Retry')
                    .afterDismissed()
                    .subscribe((res) => {
                        if (res.dismissedByAction) {
                            // only reload if the user dismisses the snack
                            location.reload();
                        }
                    });
            } else {
                this.response = true;
            }
        });
    }

    stopPropogation(ev) {
        ev.stopPropagation();
    }

    addCountryFlags(rows: any) {
        if (rows[0]?.location?.includes('<span')) return;
        rows.map((row) => {
            if (row.location != null) {
                const country = row.location.split(',');
                if (country.length > 1) {
                    const code: any = this.countryCode.filter(
                        (code) => code.name == country[country.length - 1].trim(),
                    );
                    const result = `<span class="mr-2 pt-1 fi fi-${code[0].code.toLowerCase()}"></span>`;
                    row.location = result + country.join(', ');
                }
            }
        });
    }

    editAmount(ev, item, wanType) {
        ev.stopPropagation();
        if (!Object.keys(this.tempEdit)?.length) {
            if (wanType === 'wan1') {
                this.isEdit['id'] = item.id;
                this.isEdit['wan_index'] = 0;
                item.isWAN1Edit = true;
            } else if (wanType === 'wan2') {
                this.isEdit['id'] = item.id;
                this.isEdit['wan_index'] = 1;
                item.isWAN2Edit = true;
            }
            this.tempEdit['amount'] = this.commercialData[item.id][item.wan_status[0].name].amount;
            this.tempEdit['date'] = this.commercialData[item.id][item.wan_status[0].name].date;
        } else {
            this.alert.snack('Please save or cancel the current edit', 'OK');
        }
    }

    cancelAmount(item, wanType) {
        if (wanType === 'wan1') {
            item.isWAN1Edit = false;
        } else if (wanType === 'wan2') {
            item.isWAN2Edit = false;
        } else if (wanType === 'wan3') {
            item.isWAN3Edit = false;
        }
        this.tempEdit = {};
        this.isEdit = {};
    }

    saveAmount(item, wanType) {
        if (wanType === 'wan1') {
            saveData.call(this, 0);
            item.isWAN1Edit = false;
        } else if (wanType === 'wan2') {
            item.isWAN2Edit = false;
            saveData.call(this, 1);
        } else if (wanType === 'wan3') {
            item.isWAN3Edit = false;
            saveData.call(this, 2);
        }
        // empty data and recalculate total
        this.tempEdit = {};
        this.isEdit = {};
        this.calculateTotal();
        this.postCommercialData();

        // utill function
        function saveData(index) {
            const cmRow = this.commercialData[item.id];
            const wanName = item.wan_status[index].name;
            cmRow[wanName].amount = this.tempEdit['amount'];
            cmRow[wanName].date = this.tempEdit['date'];
            cmRow[wanName].lastUpdated = new Date().toISOString();
        }
    }

    calculateTotal() {
        let total = 0;
        for (const device in this.commercialData) {
            for (const iface in this.commercialData[device]) {
                total = total + parseInt(this.commercialData[device][iface].amount);
            }
        }
        this.netTotal = total;
    }
    reload() {
        location.reload();
    }

    toggleCommercialView() {
        if (!this.commercialView) {
            this.loading = true;
            this.getCommercialData()
                .then((res) => {
                    this.loading = false;
                    this.commercialView = true;
                })
                .catch((res) => {
                    this.commercialView = false;
                    this.loading = false;
                });
        } else {
            this.commercialView = false;
        }
    }

    isWan3Available(data: Array<any>): boolean {
        let check: boolean;
        for (const x in data) {
            if (data[x].wan_status?.length > 2) {
                check = true;
                break;
            } else {
                check = false;
            }
        }
        return check;
    }

    setPage(page: number) {
        // this.pager.pageSize = parseInt(this.pager.pageSize)
        // get pager object from service
        // this.pager = this.tableService.getPager(this.temp.length, page, this.pager.pageSize);
        // get current page of items
        // this.currentPageTemp = this.temp.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }

    showPopup(ev, item, iface) {
        ev.stopPropagation();
        let height = 430;
        const width = 550;
        if (!item.ping_panels[iface]) {
            height = 263;
        }

        const dialogPosition: DialogPosition = {
            top: ev.y - height - 12 + 'px',
            left: ev.x - width - 12 + 'px',
        };
        const screenHeight = window.innerHeight;

        if (screenHeight - ev.y + height < screenHeight) {
            // do nothing becuase default conf is to fit above
        } else if (ev.y + height + 10 < screenHeight) {
            dialogPosition.top = ev.y + 10 + 'px';
        } else {
            const difference = ev.y + height - screenHeight;

            dialogPosition.top = ev.y - difference - 10 + 'px';
            dialogPosition.left = ev.x - width - 20 + 'px'; // offset for user to see the interface name
        }
        const dialogRef = this.dialog.open(PopoverGraphComponent, {
            data: {
                item: item,
                iface: iface,
            },
            position: dialogPosition,
            backdropClass: 'dialogBackdrop', // to remove the black overlay
            height: height + 'px',
            width: width + 'px',
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                ev.target.style.color = 'black';
            });
    }

    prefetchGrafanaData() {
        this.statusService.postGrafanaInterface().subscribe(
            (data: any) => {
                // localStorage.setItem('grafanaInterface', JSON.stringify(data));
                this.encryptedLocalStorageService.setItem('grafanaInterface', JSON.stringify(data));
            },
            (err) => { },
        );
    }

    subscribeToSelectedNetwok() {
        this.store
            .select((slices) => slices.networkSlice.selectedNetwork)
            .subscribe((res: Network) => {
                this.selectedNetwork = res;
            });
    }

    getUnique(arr1, arr2) {
        return _.uniq([...arr1, ...arr2]);
    }

    onSelect(ev: any) {
        this.selectedDev = ev.selected[0].id;
    }
    filterDevices() {
        switch (this.deviceFilterType) {
            case DeviceFilterType.ONLINE: {
                if (this.vpnDashboard) {
                    this.temp = this.rows.filter((inp) => inp.deviceStatus === 'connected');
                    break;
                }
                this.temp = this.rows.filter((inp) => inp.deviceStatus === 'running');
                break;
            }
            case DeviceFilterType.OFFLINE: {
                if (this.vpnDashboard) {
                    this.temp = this.rows.filter((inp) => inp.deviceStatus === 'offline');
                    break;
                }
                this.temp = this.rows.filter((inp) => inp.deviceStatus === 'error');
                break;
            }
            case DeviceFilterType.UNDEPLOYED: {
                this.temp = this.rows.filter((inp) => inp.deviceStatus === 'registered');
                break;
            }
            default: {
                this.temp = [...this.rows];
                break;
            }
        }
        this.sortDevices();
    }

    updateDeviceFilterType(val: DeviceFilterType) {
        this.store.dispatch(new SET_FILTER(val));
    }

    sortDevices() {
        if (this.sortConf.param === 'none') {
            this.setPage(this.pager.currentPage);
        } else if (this.sortConf.param === 'ls') {
            this.sortByLastSeen(this.sortConf.order, this.sortConf.ev);
        } else if (this.sortConf.param === 'name') {
            this.sortByName(this.sortConf.order, this.sortConf.ev);
        } else if (this.sortConf.param === 'w1') {
            this.sortByByWAN(0, this.sortConf.order, this.sortConf.ev);
        } else if (this.sortConf.param === 'w2') {
            this.sortByByWAN(1, this.sortConf.order, this.sortConf.ev);
        } else if (this.sortConf.param === 'w3') {
            this.sortByByWAN(2, this.sortConf.order, this.sortConf.ev);
        } else if (this.sortConf.param === 'st') {
            this.sortByStatus(this.sortConf.order, this.sortConf.ev);
        }
    }

    hidePopup() {
        this.dialog.closeAll();
    }

    rowClick(ev) {
        if (!this.vpnDashboard) {
            this.deviceListService.dispatchSelectedDeviceToStore(ev);
            this.prefetchGrafanaData();
            this.router.navigate(['/device/status']);
        } else {
            this.deviceListService.dispatchSelectedDeviceToStore(ev);
            this.router.navigate(['/mza/status']);
        }
    }

    sortByName(order, ev) {
        this.tableService.aciveIcon(ev);

        // store the current conf
        this.sortConf.order = order;
        this.sortConf.ev = ev;
        this.sortConf.param = 'name';
        this.temp = this.temp.sort((a, b) => {
            if (order === this.upOrder) {
                return a['name'].localeCompare(b['name']);
            } else {
                return b['name'].localeCompare(a['name']);
            }
        });
        this.setPage(this.pager.currentPage);
    }
    sortByByWAN(wanIndex, order, ev) {
        this.tableService.aciveIcon(ev);
        // store the current conf
        this.sortConf.order = order;
        this.sortConf.ev = ev;
        wanIndex === 0
            ? (this.sortConf.param = 'w1')
            : wanIndex === 2
                ? (this.sortConf.param = 'w2')
                : (this.sortConf.param = 'w3');
        this.temp = this.temp.sort((a, b) => {
            let aWeight;
            let bWeight;
            try {
                aWeight = a['wan_status'][wanIndex].weight;
            } catch (err) {
                aWeight = 0;
            }
            try {
                bWeight = b['wan_status'][wanIndex].weight;
            } catch (err) {
                bWeight = 0;
            }
            if (order === this.upOrder) {
                return bWeight - aWeight;
            } else {
                return aWeight - bWeight;
            }
        });
        this.setPage(this.pager.currentPage);
    }
    sortByStatus(order, ev) {
        this.tableService.aciveIcon(ev);
        // store the current conf
        this.sortConf.order = order;
        this.sortConf.ev = ev;
        this.sortConf.param = 'st';
        const weightage = {
            running: 3,
            error: 2,
            registered: 0,
        };
        this.temp = this.temp.sort((a, b) => {
            if (order === this.upOrder) {
                return weightage[b['deviceStatus']] - weightage[a['deviceStatus']];
            } else {
                return weightage[a['deviceStatus']] - weightage[b['deviceStatus']];
            }
        });
        this.setPage(this.pager.currentPage);
    }
    sortByLastSeen(order, ev) {
        this.tableService.aciveIcon(ev);
        // store the current conf
        this.sortConf.order = order;
        this.sortConf.ev = ev;
        this.sortConf.param = 'ls';
        const getTimestamp = (date) => new Date(date).getTime();
        this.temp = this.temp.sort((a, b) => {
            if (order === this.upOrder) {
                return getTimestamp(b['lastSeenTime']) - getTimestamp(a['lastSeenTime']);
            } else {
                return getTimestamp(a['lastSeenTime']) - getTimestamp(b['lastSeenTime']);
            }
        });
        this.setPage(this.pager.currentPage);
    }
    downloadAllRows() {
        this.tableService.exportTableToCSV('Devices', false, 'table');
    }

    // search

    searchRows() {
        try {
            let filter,
                table,
                tr,
                td,
                i,
                j,
                txtValue,
                check = false,
                trLength,
                tdLength;
            filter = this.searchText.toUpperCase();
            table = document.getElementById('table');
            tr = table.querySelectorAll('tbody tr');
            trLength = tr?.length;
            for (i = 0; i < trLength; i++) {
                td = tr[i].getElementsByTagName('td');
                check = false;
                tdLength = td?.length; // to avoid looking for searchText in actions column wch has only buttons
                for (j = 0; j < tdLength; j++) {
                    if (td[j]) {
                        txtValue = td[j].innerText;
                        if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            check = true;
                            break;
                        } else {
                            check = false;
                        }
                    }
                }
                if (check) {
                    tr[i].style.display = '';
                } else {
                    tr[i].style.display = 'none';
                }
            }
        } catch (err) { }
    }

    countryCode = [
    ];
}
