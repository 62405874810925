import { encryptStorage } from 'app/core/services/local-storage.service';
import { NetworkActions, NetworkActionTypes } from 'app/store/actions/network.actions';
import { initialNetworkState } from 'app/store/state/network.state';

export const networkFeatureKey = 'network';

export function reducer(state = initialNetworkState, action: NetworkActions): any {
    switch (action.type) {
        case NetworkActionTypes.SetNetworks: {
            return {
                ...state,
                networks: action.payload,
            };
        }
        case NetworkActionTypes.SetSelectedNetwork: {
            // localStorage.setItem('network', JSON.stringify(action.payload))
            encryptStorage.setItem('network', JSON.stringify(action.payload));
            return {
                ...state,
                selectedNetwork: action.payload,
            };
        }
        default:
            return state;
    }
}
