import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtillService {
    constructor() {}

    convertUnixTime(unixTimestamp) {
        return new Date(unixTimestamp * 1000).toLocaleTimeString();
    }

    scrollToBottom(timeout = 10) {
        try {
            const obj = document.querySelector('.modal-body');
            if (obj) {
                setTimeout(() => {
                    obj.scrollBy({
                        top: 500,
                        left: 0,
                        behavior: 'smooth',
                    });
                }, timeout);
            }
        } catch (err) {}
    }
}
