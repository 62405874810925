import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'pie-chart',
    template: ` <div class="mGraph-wrapper">
        <div class="mGraph" id="piechart" echarts [options]="options"></div>
    </div>`,
    styles: [
        `
            .mGraph-wrapper {
                width: 100%;
                height: 90%;
                background: #fff;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .mGraph-wrapper .mGraph {
                top: 15px;
                width: 100%;
                height: calc(100% - 15px);
                overflow: hidden;
            }
        `,
    ],
})
export class PiechartComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Input() title: string;
    @Input() legendData: string[];
    @Input() radius: string = '80%';
    @Input() positionX: string = '50%';
    @Input() positionY: string = '50%';
    @Input() colors: string[] = ['#0078be', '#00b3f0', '#6801af', '#bb64ff', '#ff560b', '#ffa608', '#298c00', '#a9ce21', '#58595b', '#939598']
    options: any;
    constructor() { }
    calculateRotation(data) {
        const percentage = data.data.percent;
        console.log(percentage);

        // Assuming 0% corresponds to no rotation (0 deg) and 100% to 90 deg rotation
        const rotationAngle = percentage * 0.9; // Adjust multiplier for desired rotation range
        return rotationAngle;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !changes.data.firstChange) {
            this.ngOnInit();
        }

    }
    ngOnInit() {
        this.options = {
            title: {
                show: true,
                text: this.data?.length > 0 ? this.title : 'No Data',
                // textAlign: 'center',
                // textVerticalAlign: 'center'
                left: this.data?.length > 0 ? 'left' : 'center',
                top: this.data?.length > 0 ? 'left' : 'center',
            },
            grid: {
                left: '2%', // Adjust spacing as needed
                right: '2%', // Adjust spacing as needed
                bottom: '10%',
                top: '10%',
                containLabel: true,
            },
            backgroundColor: '#fff',
            color: this.colors,
            tooltip: {
                trigger: 'item',
                formatter: '{b} <strong>: {c} ({d}%)</strong>',
                backgroundColor: '#000',
                axisPointer: {
                    label: {
                        margin: 20
                    }
                }
            },
            legend: {
                show: this.data.length > 0 ? true : false,
                type: 'scroll',
                orient: 'vertical',
                left: 'right',
                data: this.legendData,
                textStyle: {
                    color: '#000',
                },
                formatter: function (name) {
                    return name.length > 15 ? name.slice(0, 15) + '...' : name;
                },
            },
            series: [
                {
                    name: this.data.length > 0 ? this.title : 'No Data',
                    type: 'pie',
                    radius: this.radius,
                    avoidLabelOverlap: true,
                    showEmptyCircle: true,
                    center: [this.positionX, this.positionY],
                    data: this.data,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 5,
                            shadowOffsetX: 0,
                            shadowColor: '#424242',
                        },
                    },
                    label: {
                        show: this.data.length > 0 ? true : false,
                        position: 'inside',
                        formatter: function (param: any) {
                            return param.percent < 6 ? '' : param.percent + '%';
                        },
                        // rotate: this.data.length ? 'tangential' : '',
                        overflow: 'break',
                        fontSize: 13,
                        fontWeight: 'bold',
                        borderWidth: 0,
                        borderColor: '#fff',
                    },
                    labelLayout: {
                        hideOverlap: true,
                    },
                },
            ],
        };
    }
}
