<div class="settings-container wrapper">
  <div class="title">
    <h4>Dashboard Settings</h4>
  </div>

  <div *ngFor="let dashboardSetting of dashboardSettings" class="item">
    <div class="itemTitle" *ngIf="!dashboardSetting?.hide">
      <h5>{{dashboardSetting.name}} <i *ngIf="dashboardSetting.customizable" class="fas fa-cog"></i> </h5>
      <h6>{{dashboardSetting.description}}</h6>
    </div>
    <div class="action" *ngIf="!dashboardSetting?.hide">
      <ng-switchery matTooltip="Toggle Enable/Disable" [(ngModel)]="dashboardSetting.enabled"
        (ngModelChange)="saveSettings()" size="switchery-sm" name="switchery-check">
      </ng-switchery>
    </div>
  </div>
</div>