import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { IRowConfig, defaultProps } from 'app/shared/components/table/utills/defaultProps';
import { TableService } from 'app/shared/services/comp';

@Component({
    selector: 'cosgrid-stream-table',
    templateUrl: './stream-table.component.html',
    styleUrls: ['./stream-table.component.scss'],
})
export class StreamTableComponent implements OnInit {
    @Input() heading: string;
    @Input() rows: Array<any> = [];
    @Input() rowProps: Array<IRowConfig>;

    constructor(private tableService: TableService, private defaultProps: defaultProps) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        try {
            // Timeout for DOM to render
            setTimeout(() => {
                const tBody = document.querySelector('tbody');
                tBody.scrollTop = tBody.scrollHeight - tBody.clientHeight;
            }, 100);
        } catch (err) {}
    }
    downloadAllRows() {
        this.tableService.exportTableToCSV(this.heading, false, 'table');
    }
}
