import { Component, OnInit, Input, AfterViewInit, SimpleChange, SimpleChanges, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
    selector: 'Tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements AfterViewInit {
    @Input() description = '';
    @Input() format;
    @Input() note = '';
    @Input() preFill: 'ip' | 'subnet' | 'ipsub';

    sanitizedContent;
    constructor(private sanitizer: DomSanitizer) {}

    ngAfterViewInit() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            this.description = this.sanitizer.sanitize(SecurityContext.HTML, changes.description.currentValue);
        } catch (err) {}
        try {
            this.format = this.sanitizer.sanitize(SecurityContext.HTML, changes.format.currentValue);
        } catch (err) {}
        try {
            this.note = this.sanitizer.sanitize(SecurityContext.HTML, changes.note.currentValue);
        } catch (err) {}
        this.parseTooltip();
    }

    parseTooltip() {
        let description = `<div style="font-size:14px" >Description </div> `;
        this.sanitizedContent = `${description}<div style="font-size:12px" >${this.description}<div>`;
        if (this.format) {
            this.sanitizedContent += '<br>';
            let format = `<div style="font-size:14px">Format </h6><br>`;
            this.sanitizedContent += `${format}<ul style="font-size:12px;padding-left:12px">`;
            let formatArray = this.format.split('##');
            formatArray.forEach((inp) => {
                this.sanitizedContent += `<li>${inp}</li>`;
            });
            this.sanitizedContent += `</ul>`;
        }

        // if (this.note) {
        //   this.sanitizedContent += '<br>'
        //   let note = `<div style="font-size:14px">Note </h6><br>`
        //   this.sanitizedContent += `${note}<ul style="font-size:12px;padding-left:12px">`
        //   let noteArray = this.note.split('##')
        //   noteArray.forEach((inp) => {
        //     this.sanitizedContent += `<li>${inp}</li>`
        //   })
        //   this.sanitizedContent += `</ul>`
        // }
    }

    ip = {};
    subnet = {
        format: '',
    };

    ipsub = {};
}
