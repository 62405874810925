<div *ngIf="isGlobal;else deviceLevel">
  <!-- <cosgrid-configuration-header [title]="'BGP'" [content]="'<h5> Border Gateway Protocol (BGP) </h5>
       <hr>
       <p>View Status of BGP</p>'" (reload)="getData()">
  </cosgrid-configuration-header> -->
  <div class="tableHeading">
    <h3>BGP Status</h3>
  </div>
  <div class="cosgridTable">
    <table >
        <thead>
          <tr>
            <th scope="col">Device Name</th>
            <th scope="col">Neighbour</th>
            <th scope="col">ASN Number</th>
            <th scope="col">State</th>
            <th scope="col">Msg Sent</th>
            <th scope="col">Msg Received</th>
            
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="isGlobal"  >
            <ng-container *ngFor="let item of bgpGlobalIndex;let j=index">
              <tr *ngFor="let x of bgpData[item].neighbors;let y = index"  >
                <td >{{item}} <br>
               &nbsp;&nbsp;&nbsp;<span style="font-size: 13px" > Local IP : {{bgpData[item].local_ip}} <br>
                &nbsp;&nbsp;&nbsp; Local ASN : {{bgpData[item].local_asn}} </span>
              </td>
              <td >{{x.neighbor}}</td>
              <td  >{{x.remote_asn}}</td>
              <td  >{{x.state}}</td>
              <td  >{{x.msgsent}}</td>
              <td  >{{x.msgrcvd}}</td>
            </tr>
          </ng-container>
        </ng-container>
        
        
      </tbody>
    </table>
    <cosgrid-overlay-loader [show]="loading" ></cosgrid-overlay-loader>
    </div>

    <ng-template #noName >
      <span>.</span>
    </ng-template>
</div>

<ng-template #deviceLevel>

  <div class="tableHeading">
    <h3>BGP</h3>
  </div>
  <div  *ngIf="!isGlobal"  class="d-flex">
  <div class="card">
    <h5>Local IP</h5>
    <h6>{{bgpData.local_ip}}</h6>
  </div>
  <div class="card">
    <h5>Local ASN Number</h5>
    <h6>{{bgpData.local_asn}}</h6>
</div>
</div>
<cosgrid-table
[rows]="bgpData.neighbors"
[rowProps]="rowProps"
[tableProps]="tableProps"
[isConfig]="false"
[refreshLoading]="loading"
(refreshed)="getData()"
></cosgrid-table>

</ng-template>
