import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'emptyFiller',
})
export class EmptyFillerPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (args === '') {
            args = 'Not Available';
        }
        try {
            if (Array.isArray(value)) {
                // i) if the value is a array
                if (value.length === 0) {
                    // a) check if its length is 0
                    return args; // b) return null message if its length is 0
                } else {
                    return value; // c) return the value if length is more than zero
                }
            } else {
                if (typeof value === 'string') {
                    // ii) if the value is a string
                    value = value.trim(); // a) trim the value
                    if (value === undefined || value === '') {
                        // b) check if it is empty or undefined
                        return args; // c) return null message  if it is empty or undefined
                    } else {
                        return value; // d) return the value if it is not
                    }
                } else if (typeof value === 'object') {
                    // iii) check if is a object
                    if (Object.keys(value)) {
                        // a) check if it is empty
                        return args; // b) return null message if it is empty
                    } else {
                        return value; // c) return value if it is not
                    }
                } else {
                    if (value === undefined) {
                        // iv) if it is number or boolean or anyother datatype
                        return args;
                    } else {
                        return value;
                    }
                }
            }
        } catch (err) {
            return args; // in case of any error , return the null message
        }
    }
}
