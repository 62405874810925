import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertDate' })
export class TimeToDateConversion implements PipeTransform {
    transform(value: any): any {
        if (value) {
            if (typeof value !== 'number') {
                const numbersFromString = value.match(/\d+/g);
                const numericValuesAsNumbers = numbersFromString.map((value) => parseInt(value, 10));
                const [hours, minutes, seconds] = numericValuesAsNumbers;
                return Math.floor(hours / 24) + 'd ' + (hours % 24) + 'h ' + minutes + 'm ' + seconds + 's';
            }
            let numdays = Math.floor(value / 86400);
            let numhours = Math.floor((value % 86400) / 3600);
            let numminutes = Math.floor(((value % 86400) % 3600) / 60);
            let numseconds = ((value % 86400) % 3600) % 60;
            if (numdays > 0) {
                return numdays + ' days ' + numhours + ' h ' + numminutes + ' m '; // + numseconds + " s";
            } else {
                return numhours + ' h: ' + numminutes + ' m '; // + numseconds + " s";
            }
        } else {
            return '';
        }
    }
}
