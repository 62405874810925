import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/modules/auth/services/authentication.service';

// this handles chunk failes to load error and will hard refresh if it happens
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private router: Router, private authenticationSerice: AuthenticationService) {}
    handleError(error: Error | HttpErrorResponse): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        } else if (!(error instanceof HttpErrorResponse)) {
            // this.authenticationSerice.logout();
            // this.router.navigateByUrl(RouteConstants.SOMETHING_WENT_WRONG, { state: error });
        }
        throw error;
    }
}
