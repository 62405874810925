import { AbstractControl } from '@angular/forms';
import { IPV4Sub, IPV4 } from 'app/configs/constants';

// todo: use validate ip as function and get ip or subnet as param
export function ValidateSubnet(control: AbstractControl) {
    if (control.value === '') {
        return null;
    } else {
        try {
            let split = control.value.split('/');
            let ip = split[0];
            let subnet = parseInt(split[1]);
            if (IPV4.test(ip) && subnet > 0 && subnet <= 32) {
                return null;
            } else {
                return {
                    subpattern: true,
                };
            }
        } catch (err) {
            return {
                subpattern: true,
            };
        }
    }
}
