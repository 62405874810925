import { ActionReducerMap } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import * as deviceReducer from 'app/store/reducers/devices.reducer';
import * as customerReducer from 'app/store/reducers/customer.reducer';
import * as superAdminReducer from 'app/store/reducers/super-admin.reducer';
import * as networkReducer from 'app/store/reducers/network.reducer';
import * as templateReducer from 'app/store/reducers/templates.reducer';
import * as dashboardReducer from 'app/store/reducers/dashboard.reducer';
import * as sharedReducer from 'app/store/reducers/shared.reducer';
import * as settingReducer from 'app/store/reducers/settings.reducer';
import * as availableDashboardReducer from 'app/store/reducers/availableDashboard.reducer';

export const reducers: ActionReducerMap<CosgridAppStore> = {
    deviceSlice: deviceReducer.reducer,
    customerSlice: customerReducer.reducer,
    superAdminSlice: superAdminReducer.reducer,
    networkSlice: networkReducer.reducer,
    templateSlice: templateReducer.reducer,
    dashboardSlice: dashboardReducer.reducer,
    sharedSlice: sharedReducer.reducer,
    settingSlice: settingReducer.reducer,
    availableDashboardSlice: availableDashboardReducer.reducer
};
