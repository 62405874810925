import { Component, OnInit } from '@angular/core';
import { Customer } from '../../model/customer.list.model';
import { CosgridAppStore } from 'app/store/app.store';
import { Store } from '@ngrx/store';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import * as _ from 'lodash';
import { skipWhile } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    selector: 'cossgrid-customer-list-dropdown-component',
    templateUrl: 'customer.list.dropdown.component.html',
})
export class CustomerListComponent implements OnInit {
    customerList: Customer[] = [];
    selectedCustomer: Customer[];
    constructor(private customerListService: CustomerListService, private appStore: Store<CosgridAppStore>) {}
    ngOnInit() {
        this.customerListService.getCustomerList().subscribe((data) => {
            this.customerList = data;
            this.customerListService.dispatchAllCusomtersToStore(data);
        });
        this.appStore
            .select((slices) => slices.customerSlice.listOfCustomers)
            .pipe(skipWhile((customerList) => _.isEmpty(customerList)))
            .subscribe((customerList) => (this.customerList = customerList));
    }
    cusomterSelected(item: Customer) {}
}
