import * as _ from 'lodash';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { SupportService, AlertService } from 'app/shared/services/misc';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerListService } from 'app/shared/services/comp';
import { skipWhile } from 'rxjs/operators';

@Component({
    selector: 'cosgrid-edit-view-issue',
    templateUrl: './edit-view-issue.component.html',
    styleUrls: ['./edit-view-issue.component.scss'],
})
export class EditViewIssueComponent implements OnInit {
    currentTenantName: string = this.customerListService.getTenantName();
    loading: boolean = false;
    isEdit: boolean = false;
    devices = [];
    ticketRaiseForm: UntypedFormGroup = this.fb.group({
        tenant_name: this.currentTenantName,
        issue_in: ['common', Validators.required],
        issue_sub: ['', Validators.required],
        issue_desc: [''],
        id: 0, // for sending data
        status: '', //for sending data
    });

    resolvedDescription;

    constructor(
        private http: HttpClient,
        private fb: UntypedFormBuilder,
        private appStore: Store<CosgridAppStore>,
        private supportService: SupportService,
        private alert: AlertService,
        private dialogRef: MatDialogRef<EditViewIssueComponent>,
        private customerListService: CustomerListService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.ticketRaiseForm.patchValue(JSON.parse(JSON.stringify(data)));
        if (data.status === 'OPEN') {
            this.isEdit = true;
        } else {
            this.isEdit = false;
            this.resolvedDescription = data.admin_desc;
        }
    }

    ngOnInit() {
        this.getAllDevices();
    }

    getAllDevices() {
        this.appStore
            .select((slices) => slices.deviceSlice.listOfDevices)
            .pipe(skipWhile((listOfDevices) => _.isEmpty(listOfDevices)))
            .subscribe((listOfDevices) => {
                if (listOfDevices) {
                    this.devices = listOfDevices;
                }
            });
    }
    validateForm() {
        if (!this.ticketRaiseForm.valid) {
            for (let i in this.ticketRaiseForm.controls) {
                this.ticketRaiseForm.controls[i].markAsTouched();
            }
            return false;
        } else {
            return true;
        }
    }

    save() {
        if (this.validateForm()) {
            this.loading = true;
            this.supportService.changeIssueStatus(this.ticketRaiseForm.value).subscribe(
                (res) => {
                    this.alert.swalSuccess('Your Ticket Has been Successfully edited');
                    this.loading = false;
                    this.dialogRef.close(res);
                },
                (err) => {
                    this.loading = false;
                },
            );
        }
    }
}
