import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { SDWanGatewayUtilService } from 'app/modules/sd-wan-gateway/common/services/sd-wan-gateway.util.service';
import { GlobalConstants } from 'app/configs/constants';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class StatusService {
    public systemStatusCache: Observable<any>;
    previousDevid;
    constructor(
        private sdWanGatewayUtilService: SDWanGatewayUtilService,
        private http: HttpClient,
        private customerListService: CustomerListService,
        private globalConstants: GlobalConstants,
    ) {}

    clearCache() {
        this.systemStatusCache = null;
    }

    getSystemStatus() {
        if (!this.systemStatusCache) {
            this.systemStatusCache = this.http
                .get(
                    `${this.globalConstants.status_device}${this.sdWanGatewayUtilService.getDeviceId()}${
                        this.globalConstants.STATUS_SYSTEM_STATUS
                    }`,
                )
                .pipe(shareReplay(1));
        } else if (this.previousDevid != this.sdWanGatewayUtilService.getDeviceId()) {
            this.systemStatusCache = this.http
                .get(
                    `${this.globalConstants.status_device}${this.sdWanGatewayUtilService.getDeviceId()}${
                        this.globalConstants.STATUS_SYSTEM_STATUS
                    }`,
                )
                .pipe(shareReplay(1));
        }
        this.previousDevid = this.sdWanGatewayUtilService.getDeviceId();
        return this.systemStatusCache;
    }

    getSystemInfo() {
        return this.http.get(
            `${this.globalConstants.status_device}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_SYSTEM_INFO
            }`,
        );
    }

    getBoardStatus() {
        return this.http.get(
            `${this.globalConstants.status_device}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_SYSTEM_BOARD
            }`,
        );
    }

    getArpStatus() {
        return this.http.get(
            `${this.globalConstants.status_network}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_ARP_STATUS
            }`,
        );
    }

    getInterfaceStatus() {
        return this.http.get(
            `${this.globalConstants.status_network}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_INTERFACE_STATUS
            }`,
        );
    }

    getDeviceStatus() {
        return this.http.get(
            `${this.globalConstants.status_network}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_DEVICESTATUSALL
            }`,
        );
    }
    getDhcpStatus() {
        return this.http.get(
            `${this.globalConstants.status_network}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_DHCP_LEASES
            }`,
        );
    }

    getRouteStatus() {
        return this.http.get(
            `${this.globalConstants.status_network}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_ROUTE_STATUS
            }`,
        );
    }

    getIpsecstatus() {
        return this.http.get(
            `${this.globalConstants.status_tunnel}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_IPSEC_STATUS
            }`,
        );
    }

    getLinkBodingstatus() {
        return this.http.get(
            `${this.globalConstants.status_tunnel}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_LB_STATUS
            }`,
        );
    }

    getMwanstatus() {
        return this.http.get(
            `${this.globalConstants.status_sdwan}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_MWAN3_STATUS
            }`,
        );
    }

    getBGPStatus() {
        return this.http.get(
            `${this.globalConstants.status_network}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_BGP_STATUS
            }`,
        );
    }

    getBGPGloablStatus() {
        const params = new HttpParams().set('tid', this.customerListService.getTenantId());
        const status_tenant = this.globalConstants.status_tenant; // to remove the last character i.e "/"
        const STATUS_BGP_STATUS = this.globalConstants.STATUS_BGP_STATUS;
        return this.http.get(
            `${status_tenant.substring(0, status_tenant.length - 1)}${STATUS_BGP_STATUS.substring(
                0,
                STATUS_BGP_STATUS.length - 1,
            )}/?${params}`,
        );
    }
    getBGPRouteStatus() {
        return this.http.get(
            `${this.globalConstants.status_network}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_BGP_ROUTE_STATUS
            }`,
        );
    }
    getURLFilterStatus() {
        return this.http.get(
            `${this.globalConstants.status_usage}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_URLFILTER_STATUS
            }`,
        );
    }

    getUrlTracking(data) {
        return this.http.post(
            `${this.globalConstants.status_usage}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_URL_TRACKING
            }`,
            data,
        );
    }
    getUsageStatus() {
        return this.http.get(
            `${this.globalConstants.status_usage}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_BANDWIDTH_DATA
            }`,
        );
    }
    getFirewallStatus() {
        return this.http.get(
            `${this.globalConstants.status_firewall}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_FIREWALL_STATUS
            }`,
        );
    }
    getChainRuleStatus(data) {
        return this.http.post(
            `${this.globalConstants.status_firewall}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_FIREWALL_CHAIN_STATUS
            }`,
            { chain_list: data },
        );
    }
    getIPSetStatus() {
        return this.http.get(
            `${this.globalConstants.status_firewall}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_IPSET_STATUS
            }`,
        );
    }

    postGrafanaInterface() {
        return this.http.post(
            `${this.globalConstants.device_grafana}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.DEVICE_GRAFANA_INTERFACE
            }`,
            '',
        );
    }

    getDeviceGranfanaIdInterface() {
        return this.http.post;
    }

    postGrafanaPing() {
        return this.http.post(
            `${this.globalConstants.device_grafana}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.DEVICE_GRAFANA_PING
            }`,
            '',
        );
    }

    getELKlogs(): Observable<any> {
        return this.http.post(
            `${this.globalConstants.status_log}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_LOGS
            }`,
            { device_name: JSON.parse(localStorage.getItem('selectedDevice')).name },
        );
    }

    getLogData(logData) {
        return this.http.post(
            `${this.globalConstants.status_log}${this.sdWanGatewayUtilService.getDeviceId()}${
                this.globalConstants.STATUS_LOGS
            }`,
            logData,
        );
    }

    getTTYD() {
        const endpoint =
            this.globalConstants.device_config +
            this.sdWanGatewayUtilService.getDeviceId() +
            this.globalConstants.DEVICE_TTYD;
        return this.http.get(endpoint);
    }
}
