import { UserService } from './../../shared/services/core/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService, AlertService } from 'app/shared/services/misc';
import { AuthenticationService } from 'app/modules/auth/services/authentication.service';
import { GlobalService } from 'app/shared/services/core';

@Injectable()
export class AuthGuard implements CanActivate {
    jwtHelper = new JwtHelperService();
    constructor(
        private router: Router,
        private storage: StorageService,
        private alert: AlertService,
        private auth: AuthenticationService,
        private userService: UserService,
        private global: GlobalService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const accessToken = this.userService.getToken();

        if (this.storage.getRememberMe()) {
            const refreshToken = this.storage.getRefreshToken();
            if (!this.jwtHelper.isTokenExpired(refreshToken)) {
                if (this.jwtHelper.isTokenExpired(accessToken)) {
                    this.global.refresh(state.url); // refresh then token
                    return false;
                } else {
                    return true;
                }
            } else {
                // not logged in so redirect to login page with the return url
                this.alert
                    .snack('You`r session has been expired, Please Login Again', 'OK')
                    .afterDismissed()
                    .subscribe(() => {
                        this.auth.logout();
                    });
                // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        } else {
            if (this.jwtHelper.isTokenExpired(accessToken)) {
                this.alert
                    .snack('You`r session has been expired, Please Login Again', 'OK')
                    .afterDismissed()
                    .subscribe(() => {
                        this.auth.logout();
                    });
                return false;
            } else {
                return true;
            }
        }
    }
}
