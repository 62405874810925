import { IRowConfig, tableProps, legend } from 'app/shared/components/table/utills/defaultProps';
import { EmitterService } from '../emitter.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlarmstatusService } from 'app/modules/sd-wan-gateway/sub-modules/status/services/alarmstatus.service';

declare var $: any;
declare var jquery: any;

@Component({
    selector: 'cosgrid-devicealarmstatus',
    templateUrl: './devicealarmstatus.component.html',
    styleUrls: ['./devicealarmstatus.component.scss'],
})
export class DevicealarmstatusComponent implements OnInit {
    alertData: any[] = [];
    isSelected = false;
    $sub: any;
    selectedTemplate: any;
    states: String[];
    times: String[];
    objs: any[] = [];
    loading = true;

    rowProps: Array<IRowConfig> = [
        { header: 'State', key: 'state', type: 'icon', style: { 'padding-left': '30px' } },
        { header: 'Time', key: 'time' },
    ];
    tableProps: tableProps = {
        sort: false,
        download: true,
        refresh: true,
        search: true,
    };

    legends: Array<legend> = [
        { iconName: 'positive', label: 'OK', key: 'Ok' },
        { iconName: 'negative', label: 'Alerting', key: 'Alerting' },
    ];

    constructor(
        public dialog: MatDialog,
        private alarmstatusservice: AlarmstatusService,
        private event: EmitterService,
    ) {}

    ngOnInit() {
        this.getAlerts();

        this.event.dataStr.subscribe((data) => {
            this.getAlerts();
        });
    }

    getAlerts() {
        this.loading = true;
        this.objs = [];
        this.$sub = this.alarmstatusservice.getDeviceStatus().subscribe(
            (configuration: any) => {
                this.objs = configuration;
                this.alertData = this.objs;

                this.loading = false;
            },
            (error) => {
                this.loading = false;
            },
        );
    }
}
