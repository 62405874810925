<a class="selectContainer">
    <div class="btn-group d-flex justify-content-around w-100">
        <button class="textButton" type="button" [innerText]="viewKey ? selectedOption[viewKey] : selectedOption"
            data-container="body" data-toggle="popover" data-placement="bottom" data-trigger="focus" tabindex="0">
            Select
            <span class="sr-only"></span>
        </button>
        <button type="button" id="dropdownButton" class="dropdown-toggle dropdown-toggle-split clickable"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-right overflow DropdownAnimate slideIn" style="min-width: 200px;">
            <input id="search" [(ngModel)]="searchText" autocomplete="off" placeholder="&#xf002;"
                class="form-control form-control-sm" (keyup)="filterValue()" (blur)="clearSearch()" type="text" />

            <a *ngFor="let option of options; let i = index" (click)="optionChanged(option)" class="dropdown-item">
                <div class="optionsContainer">
                    <span>{{ viewKey ? option[viewKey] : option }}</span>
                    <!-- Only used for select device dropdown -->
                    <cosgrid-table-icon *ngIf="option.deviceStatus === 'running'" [icon]="'positive'"
                        [tooltip]="'Running'"></cosgrid-table-icon>
                    <cosgrid-table-icon *ngIf="option.deviceStatus === 'error'" [icon]="'negative'"
                        [tooltip]="'Not Reachable'"></cosgrid-table-icon>
                    <cosgrid-table-icon *ngIf="option.deviceStatus === 'registered'" [icon]="'registered'"
                        [tooltip]="'Registered'"></cosgrid-table-icon>
                </div>
            </a>
        </div>
    </div>
</a>