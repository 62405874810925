<div class="wrapper">
    <div class="card">
        
        <div class="card-body">
                <h5 class="card-title">
                        Page Not Found
                    </h5>
                    <hr>

           <p class="card-text">  Error 404 </p>
        </div>
        <mat-card-actions>
            <button (click)="routeBack()" mat-raised-button color="primary">Go to home</button>
        </mat-card-actions>
    </div>
</div>
 