import { NetflowGraphsComponent } from 'app/shared/components/netflow-graphs/netflow-graphs.component';
import { PieChartComponent } from 'app/shared/components/charts/pie-chart/pie-chart.component';
import { BarComponent } from 'app/shared/components/charts/bar/bar.component';

import { CustomButtonComponent } from 'app/shared/components/custom-button/button.component';
import { AlarmstatusComponent } from 'app/modules/sd-wan-gateway/sub-modules/status/components/alarmstatus/alarmstatus.component';
import { FirewallTargetPipe } from 'app/shared/pipes/firewall-target.pipe';
import { FormatBytesPipe } from 'app/shared/pipes/format-bytes.pipe';
import { ParseDatePipe } from 'app/shared/pipes/parse-date.pipe';
import { EmptyFillerPipe } from 'app/shared/pipes/empty-filler.pipe';
import { ShowHidePassDirective } from 'app/shared/directives/show-hide-pass.directive';
import { CustomSnackbarComponent } from 'app/shared/components/custom-snackbar/custom-snackbar.component';
import { ConfigurationHeaderComponent } from 'app/shared/components/configuration-header/configuration-header.component';
import { ConfigSubnetComponent } from 'app/shared/components/config-subnet/config-subnet.component';
import {
    IPV4Directive,
    InputValidator,
    NumberOnlyDirective,
    MACAddressDirective,
    ModalHolderDirective,
} from 'app/shared/directives';

import {
    CustomerListComponent,
    DeviceListComponent,
    NotFoundComponent,
    LoaderComponent,
    DatatableFooterComponent,
    ConfigureModalFooterComponent,
} from 'app/shared';

// Material
import { MatDialogModule } from '@angular/material/dialog';
/*MatRadioModule,MatIconModule,
    MatCheckboxModule,MatProgressBarModule,MatToolbarModule,
    MatSliderModule,MatListModule,MatExpansionModule,MatTooltipModule,
    MatSnackBarModule,MatChipsModule,MatButtonModule,
    MatSelectModule,MatSlideToggleModule,MatTableModule,MatPaginatorModule,
    MatAutocompleteModule,MatTabsModule,MatDatepickerModule,
    MatBadgeModule, MatInputModule,MatStepperModule,

    } from '@angular/material/dialog';*/
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { IpMultiComponent } from 'app/shared/components/ip-multi/ip-multi.component';
import { ToggleComponent } from 'app/shared/components/toggle/toggle.component';
import { TableActionsComponent } from 'app/shared/components/table-actions/table-actions.component';
import { TableSearchComponent } from 'app/shared/components/table-search/table-search.component';
import { DeviceNamePipe } from 'app/shared/pipes/device-name.pipe';
import { ParseInterfacePipe } from 'app/shared/pipes/parse-interface.pipe';
import { NoConfComponent } from 'app/shared/components/no-conf/no-conf.component';
import { TableComponent } from 'app/shared/components/table/table.component';
import { TableIconComponent } from 'app/shared/components/table-icon/table-icon.component';
import { StreamTableComponent } from 'app/shared/components/stream-table/stream-table.component';
import { LiveChatSupportComponent } from 'app/shared/components/live-chat-support/live-chat-support.component';
import { TrafficRealtimeChartComponent } from 'app/shared/components/traffic-realtime-chart/traffic-realtime-chart.component';
import { TopDevicesComponent } from 'app/modules/sd-wan-gateway/sub-modules/status/components/graph/top-devices/top-devices.component';

// services imports
import {
    CustomerListService,
    DeviceListService,
    RealtimeChartService,
    defaultProps,
    TableService,
    ChatService,
} from 'app/shared/services/comp';
import {
    AlertService,
    CheckConnectionService,
    CheckVersionService,
    HttpRequestCancelService,
    StorageService,
    UtillService,
    InputValidationService,
    SupportService,
} from 'app/shared/services/misc';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxIpModule } from 'ngx-ip';
import { TagInputModule } from 'ngx-chips';
import { NgSwitcheryModule } from 'angular-switchery-ios';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { SearchFilterPipe } from 'app/shared/pipes/searchfilter.pipe';
import { IpsecalarmstatusComponent } from 'app/modules/sd-wan-gateway/sub-modules/status/components/alarmstatus/ipsecalarmstatus/ipsecalarmstatus.component';
import { DevicealarmstatusComponent } from 'app/modules/sd-wan-gateway/sub-modules/status/components/alarmstatus/devicealarmstatus/devicealarmstatus.component';
import { PerformancealarmstatusComponent } from 'app/modules/sd-wan-gateway/sub-modules/status/components/alarmstatus/performancealarmstatus/performancealarmstatus.component';
import { WanalarmstatusComponent } from 'app/modules/sd-wan-gateway/sub-modules/status/components/alarmstatus/wanalarmstatus/wanalarmstatus.component';
import { BgpStatusComponent } from 'app/modules/sd-wan-gateway/sub-modules/status/components/routing/components/bgp-status/bgp-status.component';
import { ConfirmDialog } from 'app/shared/components/confirm.dialog/confirm.dialog.component';
import { MultiSelectDropdownComponent } from 'app/shared/components/multiselect.dropdown/multiselect.dropdown.component';
import { SwitchComponent } from 'app/shared/components/switch.input/switch.input.component';
import { ArrayObjectKeyPipe } from 'app/shared/pipes/array-object-key.pipe';
import { ConfigureModalHeaderComponent } from 'app/shared/components/configure-modal-header/configure-modal-header.component';
import { CustomTooltipComponent } from 'app/shared/components/custom-tooltip/custom-tooltip.component';
import { ConfigNameComponent } from 'app/shared/components/config-name/config-name.component';
import { ConfigInputComponent } from 'app/shared/components/config-input/config-input.component';
import { ConfigSelectComponent } from 'app/shared/components/config-select/config-select.component';
import { InternetTrackerComponent } from 'app/shared/components/internet-tracker/internet-tracker.component';
import { ConfigMultiComponent } from 'app/shared/components/config-multi/config-multi.component';
import { HeaderSelectComponent } from 'app/shared/components/header-select/header-select.component';
import { SidenavComponent } from 'app/shared/components/sidenav/sidenav.component';
import { ClipboardTextComponent } from 'app/shared/components/clipboard-text/clipboard-text.component';
import { WafPieChartComponent } from 'app/shared/components/charts/waf-pie-chart/waf-pie-chart.component';
import { TimeToDateConversion } from 'app/shared/pipes/time-convert-date.pipe';
import { EchartComponent } from 'app/shared/components/echarts/echart.component';
import { PacketChartComponent } from 'app/shared/components/packetchart/packetchart.component';
import { ErrorComponent } from 'app/shared/components/error/error.component';
import { CamelCasePipe } from 'app/shared/pipes/camel-case.pipe';
import { TruncatePipe } from 'app/shared/pipes/truncate.pipe';
import { DateTimeComponent } from 'app/shared/components/date-time/date-time.component';
import { TimeformatPipe } from 'app/shared/pipes/timeformat.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomLoaderComponent } from 'app/shared/components/custom-loader/custom-loader.component';
import { EchartsBarchartComponent } from 'app/shared/components/echarts-barchart/echarts-barchart.component';
import { PiechartComponent } from 'app/shared/components/piechart.component';
export const SHARED_COMPONENTS = [
    ErrorComponent,
    ClipboardTextComponent,
    EchartComponent,
    SwitchComponent,
    CustomerListComponent,
    PacketChartComponent,
    DeviceListComponent,
    CustomSnackbarComponent,
    DatatableFooterComponent,
    NotFoundComponent,
    LoaderComponent,
    ConfigureModalFooterComponent,
    IpMultiComponent,
    TopDevicesComponent,
    ToggleComponent,
    TableActionsComponent,
    ConfigurationHeaderComponent,
    TableSearchComponent,
    NoConfComponent,
    TableComponent,
    TableIconComponent,
    StreamTableComponent,
    LiveChatSupportComponent,
    TrafficRealtimeChartComponent,
    ConfirmDialog,
    MultiSelectDropdownComponent,
    ConfigureModalHeaderComponent,
    CustomTooltipComponent,
    ConfigNameComponent,
    ConfigInputComponent,
    ConfigSelectComponent,
    InternetTrackerComponent,
    ConfigMultiComponent,
    ConfigSubnetComponent,
    HeaderSelectComponent,
    CustomButtonComponent,
    PieChartComponent,
    BarComponent,
    SidenavComponent,
    WafPieChartComponent,
    NetflowGraphsComponent,
    DateTimeComponent,
    CustomLoaderComponent,
    EchartsBarchartComponent,
    PiechartComponent
];

// componenets which needs to be shared between two modules
export const MODULE_SHARED_COMPONENTS = [
    AlarmstatusComponent,
    DevicealarmstatusComponent,
    IpsecalarmstatusComponent,
    PerformancealarmstatusComponent,
    WanalarmstatusComponent,
    BgpStatusComponent,
];

export const SHARED_PIPES = [
    EmptyFillerPipe,
    DeviceNamePipe,
    ParseDatePipe,
    EmptyFillerPipe,
    FormatBytesPipe,
    FirewallTargetPipe,
    ParseInterfacePipe,
    SearchFilterPipe,
    ArrayObjectKeyPipe,
    TimeToDateConversion,
    CamelCasePipe,
    TruncatePipe,
    TimeformatPipe
];

export const MATERIAL_MODULES = [
    MatDialogModule,
    MatMenuModule,
    MatCardModule,
    MatRadioModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatSliderModule,
    MatListModule,
    MatExpansionModule,
    NgxMatSelectSearchModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatChipsModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatTableModule,
    ClipboardModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatInputModule,
    MatBadgeModule,
    MatStepperModule,
    DragDropModule
];
export const THIRD_PARTY_MODULES = [
    // FOR DATE TIME PCIKER
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxIpModule,
    TagInputModule,
    NgSwitcheryModule,
];
export const DEFAULT_CORE_MODULES = [FormsModule, ReactiveFormsModule, HttpClientModule];

export const FIREBASE_MODULES = [AngularFirestoreModule];

export const SHARED_DIRECTIVES = [
    IPV4Directive,
    InputValidator,
    NumberOnlyDirective,
    MACAddressDirective,
    ModalHolderDirective,
    ShowHidePassDirective,
];

export const SHARED_PROVIDERS = [
    // comp
    CustomerListService,
    DeviceListService,
    RealtimeChartService,
    defaultProps,
    TableService,
    ChatService,

    // misc services
    AlertService,
    CheckConnectionService,
    CheckVersionService,
    HttpRequestCancelService,
    InputValidationService,
    StorageService,
    UtillService,
    SupportService,
    TimeformatPipe
    // hasRequiredField
];
