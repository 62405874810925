import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'cosgrid-datatable-footer',
    templateUrl: './datatable-footer.component.html',
    styleUrls: ['./datatable-footer.component.scss'],
})
export class DatatableFooterComponent implements OnInit {
    @Input() isSelected = false;
    @Input() requestLoading = false;
    @Input() noRows = false;
    @Input() customCreateText = '';
    @Output() add = new EventEmitter<string>();
    @Output() edit = new EventEmitter<string>();
    @Output() delete = new EventEmitter<string>();

    constructor() { }

    ngOnInit() { }

    callAdd() {
        this.add.next('call add function');
    }
    callEdit() {
        this.edit.next('edit');
    }
    callDelete() {
        this.delete.next('delete');
    }
}
