<div *ngIf="loading">
  <cosgrid-overlay-loader></cosgrid-overlay-loader>
</div>


<mat-tab-group *ngIf="!loading" mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab>
    <ng-template mat-tab-label>
      <i class="fab fa-windows fa-lg" style="color: #00a4ef; margin-right: 10px;"></i>
      Windows
    </ng-template>
    <div class="p-5">
      <h4>Download the MicroZAccess msi file</h4>
      <h4 class="mt-3">Steps</h4>
      <mat-stepper orientation="vertical" #stepper>
        <mat-step>
          <ng-template matStepLabel>Download the MicroZAccess msi file <i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
          <button class="btn rounded-sm btn-primary p-2 px-3" (click)="downloadClick()">
            <i class="fab fa-windows fa-lg" style="margin-right: 10px;"></i>Download MicroZAccess For Windows
            Installer
          </button>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Run the msi file by double clicking on it to install the app.<i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>On the Windows Defender pop up, click on “More Info” and then click on “Run
            Anyway”.<i class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Once you're able to view the Welcome wizard, click next.<i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Accept the End-User License Agreement to continue.<i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Select the folder where you want to install the app. By default the app is installed
            in 'C:\Program Files\COSGrid MicroZAccess\'.<i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Once the installation is completed, launch the app by clicking on the shortcut on
            your desktop or by searching for COSGrid MicroZAccess in the searchbar.<i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>After Installation <i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
          <ul>
            <li>
              <h6>Login with your credentials(Email and Password/SSO/ Google).</h6>
            </li>
            <li>
              <h6>Once logged in, the profile “mza” will appear under “MicroZAccess profiles”, which is a default
                profile.</h6>
            </li>
            <li>
              <h6>By default, the 'mza' profile will be connected automatically.</h6>
            </li>
          </ul>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>To Add/Delete or Edit profile <i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
          <ul>
            <li>
              <h6>Login to cosgrid.net and navigate to Dashboard</h6>
            </li>
            <li>
              <h6>Click the device drop-down list to select your device</h6>
            </li>
            <li>
              <h6>Click MicroZAccess Endpoint profile in dashboard to add a profile and toggle advanced to add subnets
                and gateways(Optional)</h6>
            </li>
            <li>
              <h6>The changes would be reflected in the COSGridMicroZAccess application and you can toggle the button
                next to the profile name to connect.</h6>
            </li>
          </ul>
        </mat-step>
      </mat-stepper>


    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i class="fab fa-linux fa-lg" style=" margin-right: 10px;"></i>
      Linux
    </ng-template>
    <div class="p-5">
      <h4>Download and run the latest COSGrid MicroZAccess Client</h4>
      <h4 class="mt-3">Steps</h4>
      <mat-stepper orientation="vertical" #stepper>
        <mat-step>
          <ng-template matStepLabel>Download the {{details['Linux-UI']}} using the following command <i
              class="fa fa-angle-down ml-2 d-flex" style="font-size:20px;"></i></ng-template>
          <div class="d-flex">
            <span style="color: grey;">wget
              https://cdn.cosgrid.com/website/MicroZAccess/{{details['Linux-UI']}}</span><span><cosgrid-clipboard-text
                value="wget https://cdn.cosgrid.com/website/MicroZAccess/{{details['Linux-UI']}}"></cosgrid-clipboard-text></span>
          </div>

        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Install the {{details['Linux-UI']}} using the following command <i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>
          <div class="d-flex">
            <span style="color: grey;">sudo dpkg -i {{details['Linux-UI']}}</span>
            <cosgrid-clipboard-text value="sudo dpkg -i {{details['Linux-UI']}}"></cosgrid-clipboard-text>
          </div>

        </mat-step>
        <mat-step>
          <ng-template matStepLabel>After installation, either run cosgrid-microzacess from Activities or run <i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>
          <div class="d-flex"><span style="color: grey;">cosgrid-microzaccess</span><cosgrid-clipboard-text
              [value]="'cosgrid-microzaccess'"></cosgrid-clipboard-text></div>

        </mat-step>
        <mat-step>
          <ng-template matStepLabel>A password prompt would appear on the first-run, which is required to enable the
            functioning of the app <i class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>

        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Now login with your credentials(Email and Password,SSO or Google) <i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>

        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Once logged in, the profile “mza” will appear under “MicroZAccess profiles”, which
            is a default profile <i class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>

        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Toggle the button next to the profile name to connect. <i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>

        </mat-step>
        <mat-step>
          <ng-template matStepLabel>To Add/Delete or Edit profile <i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i> </ng-template>
          <ul>
            <li>
              <h6>Login to cosgrid.net and navigate to Dashboard then</h6>
            </li>
            <li>
              <h6>Click the device drop-down list to select your device</h6>
            </li>
            <li>
              <h6>Click Add to add a profile and toggle advanced to add subnets and gateways(Optional)</h6>
            </li>
            <li>
              <h6>The changes would be reflected in the CosgridMicroZAccess application</h6>
            </li>
          </ul>
        </mat-step>
      </mat-stepper>

    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i class="fab fa-apple fa-lg" style="color: gray; margin-right: 10px;"></i>
      MacOs
    </ng-template>
    <div class="p-5">
      <h4>Download and run the latest COSGrid MicroZAccess</h4>
      <h4 class="mt-3">Steps</h4>
      <mat-stepper orientation="vertical" #stepper>
        <mat-step>
          <ng-template matStepLabel>Download the MicroZAccess dmg file <i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
          <button class="btn rounded-sm btn-primary p-2 px-3" (click)="downloadMacOSClick()">
            <i class="fab fa-apple fa-lg" style="margin-right: 10px;"></i>Download MicroZAccess For MacOS
          </button>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Click the dmg file by double clicking on it to install the app.<i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Drag the COSGrid-MicroZAccess application to Applications<i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Eject the COSGrid-MicroZAccess in Desktop(Optional)<i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Go to Application and click COSGrid-MicroZAccess Application<i
              class="fa fa-angle-down ml-2" style="font-size:20px;"></i></ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>After Open the App <i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
          <ul>
            <li>
              <h6>Login with your credentials(Email and Password/SSO/ Google).</h6>
            </li>
            <li>
              <h6>Once logged in, the profile “mza” will appear under “MicroZAccess profiles”, which is a default
                profile.</h6>
            </li>
            <li>
              <h6>By default, the 'mza' profile will be connected automatically.</h6>
            </li>
          </ul>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>To Add/Delete or Edit profile <i class="fa fa-angle-down ml-2"
              style="font-size:20px;"></i></ng-template>
          <ul>
            <li>
              <h6>Login to cosgrid.net and navigate to Dashboard then,</h6>
            </li>
            <li>
              <h6>Click the device drop-down list to select your device</h6>
            </li>
            <li>
              <h6>Click MicroZAccess Endpoint profile in dashboard to add a profile and toggle advanced to add subnets
                and gateways(Optional)</h6>
            </li>
            <li>
              <h6>The changes would be reflected in the COSGrid MicroZAccess application</h6>
            </li>
          </ul>
        </mat-step>
      </mat-stepper>


    </div>
  </mat-tab>
  >
</mat-tab-group>