import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm.dialog.component.html',
})
export class ConfirmDialog {
    public title: string;
    public message: string;
    public content: string;

    public configTempDevices: any[] = [];
    public NetworkDevices: any[] = [];

    constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data?: any) {
        if (data) {
            try {
                if (data.message) {
                    this.content = data.message;
                }
                if (data.devices.length > 0) {
                    this.configTempDevices = data.devices;
                }
            } catch (err) {}
            try {
                if (data.deviceList.length > 0) {
                    this.NetworkDevices = data.deviceList;
                }
            } catch (err) {}
        }
    }
}
