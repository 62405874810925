import { Action } from '@ngrx/store';
import { DeviceList } from 'app/shared/model/device.list.model';

// store the selected device
export const SetDevice = 'Set Device';
export class SET_SELECTED_DEVICE implements Action {
    readonly type = SetDevice;
    constructor(public devices: DeviceList) {}
}

// store all the devices
export const SetListOfDevices = 'SET LIST OF DEVICES';
export class SET_LIST_OF_DEVICES implements Action {
    readonly type = SetListOfDevices;
    constructor(public listOfDevices: DeviceList[]) {}
}
export type Actions = SET_SELECTED_DEVICE | SET_LIST_OF_DEVICES;
