import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IRowConfig, tableProps } from 'app/shared/components/table/utills/defaultProps';
import { priority1, priority2 } from 'app/configs/constants';
import { StatusService } from 'app/modules/sd-wan-gateway/sub-modules/status/services/status.service';

@Component({
    selector: 'cosgrid-bgp-status',
    templateUrl: './bgp-status.component.html',
    styleUrls: ['./bgp-status.component.scss'],
})
export class BgpStatusComponent implements OnInit {
    bgpData: any = {
        neighbors: [],
    };
    bgpGlobalIndex = [];
    loading: boolean = true;
    isGlobal: boolean;
    rowProps: Array<IRowConfig> = [
        { header: 'Neighbour', key: 'neighbor', style: priority1 },
        { header: 'ASN Number', key: 'remote_asn', style: priority2 },
        { header: 'State', key: 'state' },
        { header: 'Msg Sent', key: 'msgsent' },
        { header: 'Msg Recerived', key: 'msgrcvd' },
    ];
    tableProps: tableProps = {
        sort: true,
        download: true,
        refresh: true,
        search: true,
    };
    constructor(private statusService: StatusService, private router: Router) {}

    ngOnInit() {
        this.isGlobal = this.router.url.includes('organization-monitor');
        this.getData();
    }
    ngOnDestroy() {
        this.bgpData = {}; // to clear tenant level or device level data when user navigates to the other
    }

    getData() {
        this.loading = true;
        if (this.isGlobal) {
            this.statusService.getBGPGloablStatus().subscribe(
                (res: Array<any>) => {
                    this.bgpData = res;
                    this.bgpGlobalIndex = Object.keys(this.bgpData);
                    this.loading = false;
                },
                (err) => {
                    this.loading = false;
                },
            );
        } else {
            this.statusService.getBGPStatus().subscribe(
                (res: Array<any>) => {
                    this.bgpData = res;
                    this.loading = false;
                },
                (err) => (this.loading = false),
            );
        }
    }
}
