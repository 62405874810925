import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const secret = 'E3148733574634FC17A6B1F1CC13E';

const encrypt = (txt: string, key): string => {
    return CryptoJS.AES.encrypt(txt.toString(), key).toString();
};

const decrypt = (txtToDecrypt: string, key) => {
    return CryptoJS.AES.decrypt(txtToDecrypt, key).toString(CryptoJS.enc.Utf8);
};

export const encryptStorage = {
    setItem: (key: string, value: string) => {
        if (value !== undefined) {
            localStorage.setItem(key, encrypt(value, secret));
        }
    },
    getItem: (key: string) => {
        let data = localStorage.getItem(key) || '';
        try {
            return data && JSON.parse(decrypt(data, secret));
        } catch (error) {
            return decrypt(data, secret);
        }
    },
    removeItem: (key: string) => {
        localStorage.removeItem(key);
    },
};

@Injectable({
    providedIn: 'root',
})
export class EncryptedLocalStorageService {
    key = 'E3148733574634FC17A6B1F1CC13E';

    constructor() {}

    public setItem(key: string, value: string) {
        if (value !== undefined) {
            localStorage.setItem(key, this.encrypt(value));
        }
    }

    public getItem(key: string) {
        let data = localStorage.getItem(key) || '';
        try {
            return data && JSON.parse(this.decrypt(data));
        } catch (error) {
            return this.decrypt(data);
        }
    }
    public removeItem(key: string) {
        localStorage.removeItem(key);
    }

    public clearData() {
        localStorage.clear();
    }

    private encrypt(txt: string): string {
        return CryptoJS.AES.encrypt(txt.toString(), this.key).toString();
    }

    private decrypt(txtToDecrypt: string) {
        return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
    }
}
