<div class="actionContainer">
  <ng-switchery matTooltip="Toggle Enable/Disable" *ngIf="displayToggle" [(ngModel)]="value"
    (ngModelChange)="toggleValueChanged()" size="switchery-sm" name="switchery-check">
  </ng-switchery>

  <i *ngIf="needView" matTooltip="View" (click)="viewClicked($event)" class="fa fa-eye view" aria-hidden="true"></i>

  <i *ngIf="needEdit" matTooltip="Edit" (click)="editClicked($event)" class="fa fa-pencil-square-o edit"
    aria-hidden="true"></i>
  <!-- <i  *ngIf="needDelete"  matTooltip="Delete" style="color: #DD0330" (click)="deleteClicked($event)" class="fas fa-trash delete" aria-hidden="true"></i> -->
  <mat-icon svgIcon="trash" class="material-icons delete" matTooltip="Delete" *ngIf="needDelete"
    (click)="deleteClicked($event)" aria-hidden="true"></mat-icon>
  <!-- <mat-icon *ngIf="needDelete" (click)="deleteClicked($event)" class="delete" matTooltip="Delete" svgIcon="trash"
    ></mat-icon> -->

</div>