import { Component, Input } from '@angular/core';

@Component({
    selector: 'cosgrid-table-icon',
    templateUrl: './table-icon.component.html',
    styleUrls: ['./table-icon.component.scss'],
})
export class TableIconComponent {
    @Input() icon: string;
    @Input() tooltip: string;

    constructor() {
        if (!this.tooltip)
            if (this.icon == 'tick')
                this.tooltip = 'Accept'
        if (this.icon == 'worng')
            this.tooltip = 'Deny'
        if (this.icon == 'block')
            this.tooltip = 'Reject'
    }
}
