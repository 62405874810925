import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'app/configs/constants';
import { UserService } from 'app/shared/services/core';
import { AlertService } from 'app/shared/services/misc';

@Injectable({
    providedIn: 'root',
})
export class PushNotifictaionService {
    currentMessage = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private globalConstants: GlobalConstants,
        private userService: UserService,
        private alertService: AlertService,
        private angularFireMessaging: AngularFireMessaging,
    ) {
        try {
            this.angularFireMessaging.messages.subscribe((_messaging) => {
                /* _messaging.onMessage = _messaging.onMessage.bind(_messaging);
           _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);*/
            });
        } catch (err) {}
    }

    /**
     * update token in firebase database
     *
     * @param userId userId as a key
     * @param token token as a value
     */
    updateToken(userId, token) {
        this.http
            .post(`${this.globalConstants.tenant_app}`, {
                user_id: userId,
                app_id: token,
            })
            .subscribe((res) => {});
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */
    requestPermission() {
        let userId;
        try {
            userId = this.userService.getUser().primaryKey;
        } catch (err) {}
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.updateToken(userId, token);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            },
        );
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((payload: any) => {
            this.currentMessage.next(payload);
            this.alertService.snack(payload.data.message, 'OK');
        });
    }

    subscribeToPushNotiff() {
        try {
            this.requestPermission();
            this.receiveMessage();
        } catch (err) {}
    }
}
