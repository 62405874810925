import { HttpClient } from '@angular/common/http';

import { Subject, Observable, BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
//import { HttpUtil } from 'app/shared/services/core/http.util.service';
import { UserService } from 'app/shared/services/core/user.service';
import { AlertService } from 'app/shared/services/misc/alert.service';
import { Router } from '@angular/router';
import { GlobalConstants } from 'app/configs/constants';
import { StorageService } from 'app/shared/services/misc/storage.service';
//import { tokenNotExpired } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    private mockMode = false;
    public username: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public searchText: Subject<string> = new Subject<string>();

    public currentConfig: BehaviorSubject<String[]> = new BehaviorSubject<String[]>([]);

    //private httpUtil: HttpUtil;
    jwtHelper = new JwtHelperService();
    constructor(
        private userService: UserService,
        private alert: AlertService,
        private router: Router,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private globalConstants: GlobalConstants,
        private http: HttpClient, //public jwtHelper: JwtHelperService
    ) {}

    setA(a) {
        //this.httpUtil = a;
    }

    updateCurrentConfig(config: Array<any>, key: string = 'name') {
        this.currentConfig.next(config.map((inp) => inp[key]));
    }

    clearCurrentConfig() {
        this.currentConfig.next([]);
    }

    isMockMode(): boolean {
        if (this.mockMode) {
            return true;
        } else {
            return false;
        }
    }

    isProduction(): boolean {
        if (location.href.includes('cosgrid')) {
            return true;
        } else {
            return false;
        }
    }

    handleError(error: any) {
        if (error.code === 'token_not_valid') {
            this.refresh(this.router.url); // if access is expired refresh the access token with refresh token
        }

        return throwError(() => error || 'Internal Server error');
    }

    updateAuthStatus(): void {
        if (this.isAuthenticatedUser()) {
            this.isUserLoggedIn.next(true);
        } else {
            this.isUserLoggedIn.next(false);
        }
    }

    isAuthenticatedUser(): boolean {
        const user = this.userService.getUser();
        const token = this.userService.getToken();
        if (!this.jwtHelper.isTokenExpired(token)) {
            this.isUserLoggedIn.next(true);
            return true;
        } else {
        }
        if (_.isEmpty(user)) {
            this.isUserLoggedIn.next(false);
            return false; //
        } else {
            this.isUserLoggedIn.next(true);
            return true; //
        }
    }
    /**
     * This method validates the logged in user is a super admin or a normal user
     */
    isSuperAdmin(): boolean {
        if (!_.isEmpty(this.userService.getUser())) {
            return this.userService.getUser().isSuperAdmin;
        }
    }

    public refresh(route?, url?) {
        try {
        } catch (error) {}
        this.http
            .post(this.globalConstants.REFRESH, {
                refresh: this.encryptedLocalStorageService.getItem('refresh'),
            })
            .subscribe(
                (res: any) => {
                    this.userService.setToken(res.access);
                    //this.httpUtil.updateHeaders(res.access);

                    // if(route)
                    // this.router.navigateByUrl(route)
                    this.alert
                        .snack('Refreshing You`r session, Please wait', 'OK', 2000)
                        .afterDismissed()
                        .subscribe(() => {
                            location.reload();
                        });
                },
                (err) => {
                    if (err.error.detail === 'No Connectivity') {
                    } else {
                        this.logout();
                    }
                },
            );
    }

    logout() {
        // function duplicated because of circular dependency
        // remove user from local storage to log user out

        // TODO : clear interval, remove device from redux
        localStorage.removeItem('tenant');
        localStorage.removeItem('tenantName');
        localStorage.removeItem('refresh');
        localStorage.removeItem('tenantID');

        this.userService.clearUser();
        this.userService.deleteCookie('cosgridToken');
        this.router.navigateByUrl('/login');
        this.updateAuthStatus();
        this.alert.dismissCurrent();
    }

    generateConfig() {}
}
