import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EmitterService } from './emitter.service';
import { AlarmstatusService } from 'app/modules/sd-wan-gateway/sub-modules/status/services/alarmstatus.service';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Component({
    selector: 'cosgrid-alarmstatus',
    templateUrl: './alarmstatus.component.html',
    styleUrls: ['./alarmstatus.component.scss'],
})
export class AlarmstatusComponent implements OnInit {
    routeLinks: any[];
    activeLinkIndex = 0;
    devices = [];
    global = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private alarm: AlarmstatusService,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private eventEmitter: EmitterService,
    ) {
        this.routeLinks = [
            { label: 'Device', link: 'device' },
            { label: 'WAN', link: 'wan' },
            { label: 'Performance', link: 'performance' },
            { label: 'IPSec', link: 'ipsec' },
        ];
    }

    ngOnInit() {
        this.global = this.router.url.includes('organization/monitor/alarms/device');
        this.devices = this.encryptedLocalStorageService.getItem('listOfDevices');
        if (this.global) {
            this.changeDevice(this.devices[0].id);
        }
    }

    changeDevice(deviceid) {
        this.alarm.deviceId = deviceid; // TODO: emit event when changed
        this.router.navigateByUrl('/organization/monitor/alarms/device');
        this.route.data.subscribe((e) => {
            this.eventEmitter.sendMessage('changed');
        });
    }
}
