<cosgrid-table
    [heading]="'Device Alarms'"
    [nullMessage]="'No Device Reachability History available yet!'"
    [rows]="alertData"
    [rowProps]="rowProps"
    [tableProps]="tableProps"
    [isConfig]="false"
    [refreshLoading]="loading"
    (refreshed)="getAlerts()"
    [legends]="legends"
></cosgrid-table>