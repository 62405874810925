<div class="wrapper d-flex justify-content-center">
  <div>
    <h2 class="mb-3">Raise a Ticket </h2>
    <div style="min-width: 40rem;">
      <form [formGroup]="ticketRaiseForm" class="shadow p-3">
        <div class="form-row">
          <div class="form-group col-6 d-flex align-items-center">
            <p class="mr-2">Product: </p>
            <config-select formControlName="product" [options]="['Reflex SD-WAN', 'MicroZAccess']"
              placeholder="Choose your product" description="Choose the issue product"></config-select>
          </div>

        </div>
        <div class="d-flex mb-4">
          <div style="flex:1">
            <config-select name="Issue In" formControlName="issue_in" [options]="devices" viewKey="name" valueKey="name"
              placeholder="Select issue device" description="Choose the device for raise an issue"
              [className]="'col-11 '" [multiple]="true"></config-select>
          </div>
          <div style="flex:1">
            <config-select name="Priority" formControlName="priority"
              [options]="['Urgent (P1)', 'High (P2)', 'Medium (P3)', 'Low (P4)']" placeholder="How Important"
              description="How important this issue" [className]="'col-11'"></config-select>
          </div>
        </div>
        <div class="d-flex mb-4">
          <div style="flex:1">
            <config-input name="Issue Name" formControlName="issue_sub" placeholder="Issue Name"
              desctiption="Enter the name of the issue" [className]="'col-11'"></config-input>
          </div>
          <div style="flex:1" class="ml-3 mt-2">
            <label>Mode of Contact</label><br>
            <mat-radio-group aria-label="Select an option" formControlName="mode_contact">
              <mat-radio-button value="Email">Email</mat-radio-button>
              <mat-radio-button value="Phone">Phone</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div style="flex:1">
            <config-input name="Description" type="string" formControlName="issue_desc"
              placeholder="Detailed Description of the issue" desctiption="Enter the detail description of the issue"
              [className]="'col-11'"></config-input>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button (click)="submitForm()" class="btn btn-primary">
            Submit <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw"></i>
          </button>
        </div>
      </form>
    </div>

  </div>
</div>