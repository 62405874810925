import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import * as CustomerActions from 'app/store/actions/customer.actions';
import { GlobalConstants } from 'app/configs/constants';
import { Injectable } from '@angular/core';
import { Customer } from 'app/shared/model/customer.list.model';
import { map } from 'rxjs/operators';
import { SET_TENANT_NAME } from 'app/store/actions/shared.actions';
import { Tenant } from 'app/shared/model';
import { selectSelectedTenant, selectTenantID, selectTenantName } from 'app/store/selectors/shared.selector';
import { combineLatest } from 'rxjs';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Injectable()
export class CustomerListService {
    selectedTenantId: string = undefined;
    selectedTenantName = '';
    selectedTenant: Tenant;
    constructor(
        private http: HttpClient,
        private globalConstants: GlobalConstants,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private appStore: Store<CosgridAppStore>,
    ) {
        combineLatest([
            this.appStore.select(selectSelectedTenant),
            this.appStore.select(selectTenantName),
            this.appStore.select(selectTenantID),
        ]).subscribe(([selectedTenant, selectedTenantName, selectedTenantID]) => {
            this.selectedTenant = selectedTenant;
            this.selectedTenantName = selectedTenantName;
            this.selectedTenantId = selectedTenantID;
        });
    }

    getTenantId() {
        if (this.encryptedLocalStorageService.getItem('currentUser')?.isSuperAdmin) {
            return this.encryptedLocalStorageService.getItem('tenant')?.id; // for super admin
        }

        return this.encryptedLocalStorageService.getItem('tenantID'); // for normal tenant login
    }

    getTenantName() {
        try {
            if (this.encryptedLocalStorageService.getItem('currentUser')?.isSuperAdmin) {
                // TODO - remove next line
                // localStorage.setItem('tenantName', 'undefined');   // if he is superadmin and organization owner, make the tenant name undefined
                this.encryptedLocalStorageService.setItem('tenantName', 'undefined');
            }

            if (this.selectedTenantName === 'undefined') {
                // check whether he is superadmin
                try {
                    return this.encryptedLocalStorageService.getItem('tenant')?.tenantname;
                } catch (e) {
                    return '';
                }
            } else {
                return this.encryptedLocalStorageService.getItem('tenantName');
            }
        } catch (err) {
            return '';
        }
    }

    getTenantInfo(data) {
        return this.http.post(this.globalConstants.STATUS_TENANT_INFO, data);
    }

    getCustomerList(): Observable<Customer[]> {
        return this.http.get(this.globalConstants.TENANT_LIST).pipe(map((response) => response as Customer[]));
    }
    // TO DO - Create a new slice to store all the tenants list and selected tenant
    dispatchAllCusomtersToStore(listOfCustomers: Customer[]) {
        this.appStore.dispatch(new CustomerActions.SET_LIST_OF_CUSTOMERS(listOfCustomers));
    }

    dispatchSelectedCusomtersToStore(selectedCustomer: Customer) {
        this.appStore.dispatch(new CustomerActions.SET_SELECTED_CUSTOMER(selectedCustomer));
    }
}
