import { NG_VALUE_ACCESSOR, NgControl, ControlValueAccessor, UntypedFormControl, Validators } from '@angular/forms';
import {
    Component,
    OnInit,
    forwardRef,
    Input,
    Injector,
    Optional,
    Self,
    AfterViewInit,
    SimpleChange,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { hasRequiredField } from 'app/shared/services/misc';

@Component({
    selector: 'config-select',
    templateUrl: './config-select.component.html',
    styleUrls: ['./config-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConfigSelectComponent),
            multi: true,
        },
    ],
})
export class ConfigSelectComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() name: string;
    @Input() className: string = '';
    @Input() options: Array<any> = [];
    @Input() viewKey: string;
    @Input() valueKey: string;
    @Input() dataWithIcon: boolean = false;
    @Input() description;
    @Input() format;
    @Input() note;
    @Input() multiple: boolean = false;
    @Input() disabled: boolean = true;
    @Input() default: string;
    @Input() defaultValue: string = "";
    @Input() placeholder: string;
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    onChange: Function;
    onTouched: Function;

    input = new UntypedFormControl(['', [Validators.required]]);
    inputChangeSub: Subscription;

    isNgModel: boolean = false;
    isRequired: boolean = false;

    constructor(
        @Self()
        @Optional()
        private injector: Injector,
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.input = ngControl.control as UntypedFormControl;
            if (hasRequiredField(this.input)) {
                this.isRequired = true;
            } else {
                this.isRequired = false;
            }
            this.input.updateValueAndValidity();
        } else {
            this.isNgModel = true;
            this.subscribeToValueChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges) { }
    subscribeToValueChanges() {
        this.inputChangeSub = this.input.valueChanges.subscribe((res) => {
            this.onChange(res);
        });
    }

    optionChanged(ev) {
        this.change.emit(ev);
    }

    writeValue(value: any): void {
        if (value !== undefined) {
            this.input.setValue(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
