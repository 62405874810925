import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'app/shared/services/misc';

@Component({
    selector: 'cosgrid-something-went-wrong',
    templateUrl: './something-went-wrong.component.html',
    styleUrls: ['./something-went-wrong.component.scss'],
})
export class SomethingWentWrongComponent {
    error: any;

    constructor(private router: Router, private aletService: AlertService) {
        this.error = this.router.getCurrentNavigation()?.extras?.state;
    }

    tryAgain() {
        location.href = location.origin;
    }

    copyError() {
        /* Copy the text inside the text field */
        try {
            navigator.clipboard.writeText(JSON.stringify(this.error.stack));
            this.aletService.snack('Successfully Copied');
        } catch (error) {}
    }

    contactSupport() {
        location.href = 'https://cosgrid.com/contacts';
    }
}
