
 <select *ngIf="global"  class="form-control form-control-sm"  style="float:right;width: 150px" (change)="changeDevice($event.target.value)" >
    <option value="{{item.id}}" *ngFor="let item of devices">{{item.name}} </option>
  </select>
<div class="wrapper">
  <nav class="toolbarSwitcher" mat-tab-nav-bar backgroundColor="primary" color="primary" style="background: #f5f5f5;">
    <a class="item" *ngFor="let r of routeLinks" mat-tab-link [routerLink]="r.link" routerLinkActive #rla="routerLinkActive" routerLinkActive [active]="rla.isActive">
      {{r.label}}
    </a>
  </nav>
  <br>
  <router-outlet ></router-outlet>
</div>