import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef } from '@angular/core';

@Component({
    selector: 'header-select',
    templateUrl: './header-select.component.html',
    styleUrls: ['./header-select.component.scss'],
})
export class HeaderSelectComponent implements OnChanges {
    @Input() selectedOption: string;
    @Input() options: Array<any>;
    @Input() viewKey: string;
    @Input() icon: string;
    @Input() parentSelector: string;

    @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

    searchText: string = '';
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if ('selectedOption' in changes) {
            // if (!changes.selectedOption.currentValue) {
            //   if (this.viewKey) {
            //     this.selectedOption = "Select"
            //   } else {
            //     this.selectedOption[this.viewKey] = "Select"
            //   }
            // }
        }
    }

    optionChanged(ev) {
        this.optionSelected.emit(ev);
    }

    filterValue() {
        const filterValue = this.searchText;
        const filterOption = filterValue.toLowerCase();
        const filterArray: NodeListOf<HTMLElement> = document.querySelectorAll(this.parentSelector + ' .dropdown-item');
        for (let i = 0; i < filterArray.length; i++) {
            let filterInstance = filterArray[i];
            if (filterInstance.innerText.toLowerCase().includes(filterOption)) {
                filterInstance.style.display = '';
            } else {
                filterInstance.style.display = 'none';
            }
        }
    }

    clearSearch() {
        this.searchText = '';
        // immedieately invoking the function will conflict with onclick, and dev change wont trigger
        setTimeout(() => {
            this.filterValue(); // to remove "display:none" for all items
        }, 1000);
    }
}
