import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface IPanel {
    icon: string;
    link: string;
    label: string;
}

@Component({
    selector: 'cosgrid-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
    @Input() routeLinks: IPanel[] = [];
    open: boolean = false;
    constructor() {}

    ngOnInit(): void {}

    handleClick() {
        if (this.open) {
            this.open = false;
        } else {
            this.open = true;
        }
    }
}
