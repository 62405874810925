import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
    transform(bytes: any, args?: any): any {
        const decimals = 1;
        if (bytes === 0) {
            return args === 'bits' ? '0 b' : '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (args === 'bits') {
            sizes = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
