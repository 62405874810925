import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from 'app/modules/dashboard/dashboard.routing';
import { DeviceCardComponent } from 'app/modules/dashboard/components/devices/devicecard.component';
import { PopoverGraphComponent } from 'app/modules/dashboard/components/popover-graph/popover-graph.component';
import { TopApplicationsComponent } from 'app/modules/dashboard/components/top-applications/top-applications.component';
import { TopHostsComponent } from 'app/modules/dashboard/components/top-hosts/top-hosts.component';
import { TopSendersComponent } from 'app/modules/dashboard/components/top-senders/top-senders.component';
import { SharedCommonModule } from 'app/shared/shared.module';
import { DashboardComponent } from 'app/modules/dashboard/dashboard.component';
import { AgmCoreModule } from '@agm/core';
//import { MAT_DATE_LOCALE } from '@angular/core';
import { MapComponent } from './components/map/map.component';
import { TileGroupComponent } from './components/tile-group/tile-group.component';

@NgModule({
    declarations: [
        DashboardComponent,
        DeviceCardComponent,
        PopoverGraphComponent,
        TopApplicationsComponent,
        TopHostsComponent,
        TopSendersComponent,
        MapComponent,
        TileGroupComponent,
    ],
    imports: [CommonModule, SharedCommonModule, DashboardRoutingModule, AgmCoreModule],
    providers: [{ provide: LOCALE_ID, useValue: 'en-GB' }],
})
export class DashboardModule {
    constructor() {}
}
