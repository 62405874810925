<div class="sidenav-container">
    <div class="navContainer">
        <div *ngFor="let route of routeLinks">
            <h6 [routerLink]="route.link" routerLinkActive="selected" *ngIf="route.label !== 'Security'" class="label">
                <span
                    ><i
                        style="margin-right: 20px; font-size: 20px;"
                        *ngIf="route.label !== 'Security'"
                        [class]="route.icon"
                    >
                    </i>
                </span>
                {{ route.label }}
            </h6>
            <div *ngIf="route.label === 'Security'">
                <h6 (click)="handleClick()">
                    <span><i style="margin-right: 20px; font-size: 20px;" [class]="route.icon"> </i> </span>
                    <span>{{ route.label }}</span>
                </h6>
                <div *ngIf="open">
                    <div *ngIf="route.children">
                        <div *ngFor="let item of route.children" [routerLink]="item.link" routerLinkActive="selected">
                            <h6 class="children">
                                <span><i style="margin-right: 20px; font-size: 20px;" [class]="item.icon"> </i> </span>
                                {{ item.label }}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
