import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { EditViewIssueComponent } from './edit-view-issue/edit-view-issue.component';
import { SupportService, AlertService } from 'app/shared/services/misc';
import { GlobalService } from 'app/shared/services/core';
import { IRowConfig, tableProps } from 'app/shared/services/comp';

@Component({
    selector: 'cosgrid-track-ticket-status',
    templateUrl: './track-ticket-status.component.html',
    styleUrls: ['./track-ticket-status.component.scss'],
})
export class TrackTicketStatusComponent implements OnInit {
    rows: any = []
    rowProps: IRowConfig[] = [
        { header: 'Ticket ID', key: 'issue_id', type: 'string' },
        { header: 'Issue In', key: 'issue_in', type: 'string' },
        { header: 'Issue Name', key: 'issue_sub', type: 'string' },
        { header: 'Priority', key: 'priority', type: 'string' },
        { header: 'Description', key: 'issue_desc', type: 'string' },
        {
            header: 'Status', key: 'status', dataStyle: (row, key) => ({
                'background-color': row[key] === 'OPEN' ? '#34b000' : row[key] === 'CLOSED' ? '#ff560b' : '',
                padding: '5px 10px',
                'border-radius': '5px',
                color: '#fff',
                width: '40px',
                display: 'block',
                'justify-content': 'center',
                'align-items': 'center',
                'font-size': '14px',
            }), type: 'string'
        },
        { header: 'Product', key: 'product', type: 'string' }
    ]
    tableProps: tableProps = {
        sort: false,
        refresh: false,
        download: false,
        search: false
    }
    issuesData;
    loading: boolean = true;
    currentTenantName: string = this.customerListService.getTenantName();

    constructor(
        private supportService: SupportService,
        private customerListService: CustomerListService,
        private global: GlobalService,
        private alert: AlertService,
        private dialog: MatDialog,
    ) { }

    isSuperAdmin: boolean = false;

    ngOnInit() {
        this.getIssues();
        this.isSuperAdmin = this.global.isSuperAdmin();
    }

    onoff(item) {
        item.enabled = !item.enabled;

        if (item.enabled) {
            item.status = 'OPEN';
        } else {
            item.status = 'CLOSE';
        }

        item['tenant_name'] = this.currentTenantName;
        this.supportService.changeIssueStatus(item).subscribe(
            (res: any) => {
                if (res.status === 'OPEN') this.alert.swalSuccess('Successfully opened an issue ');
                else {
                    this.alert.swalSuccess('Successfully closed an issue');
                }
                this.getIssues();
            },
            (err) => { },
        );
    }

    openDialog(data) {
        const dialogRef = this.dialog.open(EditViewIssueComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getIssues();
            }
        });
    }

    getIssues() {
        this.loading = true;
        this.supportService.getIssues(this.currentTenantName).subscribe(
            (res: any) => {
                this.rows = res.filter(row => { return row.issue_id != '461821ea-c197-4f30-afc7-7ac65abc2efa' });
                this.loading = false;
            },
            (err) => {
                this.loading = false;
            },
        );
    }
}
