import { EMAIL } from 'app/configs/constants';
import { AbstractControl } from '@angular/forms';

export function ValidateEmail(control: AbstractControl) {
    if (control.value === '') {
        return null;
    } else if (!EMAIL.test(control.value)) {
        return {
            incorrectEmail: true,
        };
    }
    return null;
}
