import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CosgridAppStore } from 'app/store/app.store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { GlobalConstants } from 'app/configs/constants';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class SDWanGatewayUtilService {
    public NO_CONFIGURATION_MESSAGE = 'No Data';
    public NO_STATUS_MESSAGE = 'No Data';
    private subscriptions: Subscription[] = [];
    public interfaces: any = [];
    public physicalinterface: any = [];
    public logicalinterface: any = [];
    selectedTemplate;

    constructor(
        private appStore: Store<CosgridAppStore>,
        private http: HttpClient,
        private globalConstants: GlobalConstants,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private router: Router,
        private handler: HttpBackend,
    ) { }

    getDeviceId(): string {
        let selectedDeviceId = '-1';
        let selectedDeviceIdfromLocal = '-1';

        this.subscriptions.push(
            this.appStore
                .select((slices) => slices.deviceSlice.selectedDevice)
                .subscribe((selectedDevice) => {
                    try {
                        selectedDeviceId = selectedDevice.id;
                    } catch (err) { }
                }),
        );
        if (selectedDeviceId === undefined) {
            try {
                const selectedDevice = this.encryptedLocalStorageService.getItem('selectedDevice');
                selectedDeviceIdfromLocal = selectedDevice.id;

                return selectedDeviceIdfromLocal;
            } catch (err) {
                return '-1';
            }
        } else {
            return selectedDeviceId;
        }
    }
    getDeviceName() {
        let selectedDeviceName = '-1';
        let selectedDeviceNamefromLocal = '';
        //  let selectedDeviceIdfromLocal :string = '-1';

        this.subscriptions.push(
            this.appStore
                .select((slices) => slices.deviceSlice.selectedDevice)
                .subscribe((selectedDevice) => {
                    selectedDeviceName = selectedDevice.name;
                    //
                }),
        );

        if (selectedDeviceName === undefined) {
            try {
                const selectedDevice = this.encryptedLocalStorageService.getItem('selectedDevice');
                selectedDeviceNamefromLocal = selectedDevice.name;
                return selectedDeviceNamefromLocal;
            } catch (err) { }
        } else {
            return selectedDeviceName;
        }
    }

    getUrlWithDeviceId(): string {
        return this.globalConstants.DEVICE_URL.concat(this.getDeviceId());
    }

    getEnableConfigs() {
        this.selectedTemplate = this.encryptedLocalStorageService.getItem('selectedTemplate');
        let url;

        if (this.router.url === '/configtemp/configtempedit/firewall/zones') {
            url = this.globalConstants.template + this.selectedTemplate;
        } else {
            url = this.globalConstants.device_config + this.getDeviceId() + this.globalConstants.DEVICE_ENABLE_CONFIGS;
        }
        return this.http.get(url);
    }

    postEnableConfigs(data) {
        this.selectedTemplate = this.encryptedLocalStorageService.getItem('selectedTemplate');
        // this.selectedTemplate = localStorage.getItem('selectedTemplate');
        let url;

        if (this.router.url === '/configtemp/configtempedit/firewall/zones') {
            url = this.globalConstants.template + this.selectedTemplate;
        } else {
            url = this.globalConstants.device_config + this.getDeviceId() + this.globalConstants.DEVICE_ENABLE_CONFIGS;
        }
        return this.http.post(url, data);
    }
}
