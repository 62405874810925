import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cosgrid-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss']
})
export class CustomLoaderComponent implements OnInit {
  @Input() tableLoading: boolean = false
  @Input() toolboxAvailable: boolean = false;
  @Input() searchLoading: boolean = false;
  @Input() noOfToolboxOptions: number = 0;
  @Input() show: boolean = true;
  @Input() cardLoading: boolean = false;
  @Input() plainCard: boolean = true;
  @Input() className: string = ''
  @Input() cardRows: number = 0
  @Input() cardCols: number = 0
  @Input() cardHeader: boolean = false;
  constructor() { }

  ngOnInit(): void { }

}
