import { AbstractControl } from '@angular/forms';
import { PORT } from 'app/configs/constants';

export function ValidatePort(control: AbstractControl) {
    try {
        let port: string = control.value;
        port = port.trim();
        if (port === '') {
            return null; // return no error if port is empty
        }

        if (port.includes(':')) {
            const portRange = port.split(':');
            if (portRange.length > 2) {
                return {
                    incorrectRange: true,
                };
            } else {
                if (PORT.test(portRange[0]) && PORT.test(portRange[1])) {
                    if (parseInt(portRange[0]) < parseInt(portRange[1])) {
                        return null;
                    } else {
                        return {
                            incorrectRange: true,
                        };
                    }
                } else {
                    return {
                        incorrectRange: true,
                    };
                }
            }
        } else {
            if (!PORT.test(port)) {
                return {
                    incorrectPort: true, // return error if it doese`nt match the regex
                };
            } else {
                return null;
            }
        }
    } catch (err) {
        return null;
    }
}
