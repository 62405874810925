import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlarmstatusService } from 'app/modules/sd-wan-gateway/sub-modules/status/services/alarmstatus.service';
import { IRowConfig } from 'app/shared/services/comp';

@Component({
    selector: 'cosgrid-ipsecalarmstatus',
    templateUrl: './ipsecalarmstatus.component.html',
    styleUrls: ['./ipsecalarmstatus.component.scss'],
})
export class IpsecalarmstatusComponent implements OnInit {
    alertData: any[] = [];
    isSelected = false;
    $sub: any;
    selectedTemplate: any;

    loading = false;
    rowProps: Array<IRowConfig> = [
        { header: 'State', key: 'state' },
        { header: 'Time', key: 'time' },
    ];

    constructor(public dialog: MatDialog, private alarmstatusservice: AlarmstatusService) {}

    ngOnInit() {}

    getAlerts() {
        this.loading = true;
        this.$sub = this.alarmstatusservice.getIPSecStatus().subscribe(
            (configuration: any) => {
                this.alertData = configuration;
                this.loading = false;
                this.isSelected = false;
            },
            (error) => {
                this.loading = false;
            },
        );

        // this.addgraphanacomponent.displayInterface();
    }
}
