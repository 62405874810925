import { AuthenticationService } from '../../auth/services/authentication.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertService } from 'app/shared/services/misc/alert.service';
import { User } from 'app/core/models/user';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

/**
 *  This gaurd is used to get additional confirmation if the logged in user is a super admin
 */
@Injectable()
export class SuperAdminAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private alert: AlertService,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private auth: AuthenticationService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user: User = this.encryptedLocalStorageService.getItem('currentUser');
        if (user.isSuperAdmin) {
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.auth.logout();
            this.alert.snack('Permission Denied', 'OK', { duration: 2000 });
            return false;
        }
    }
}
