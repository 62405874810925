import { Router, ActivationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HttpRequestCancelService {
    private cancelPendingRequests$ = new Subject<void>();

    constructor(private router: Router) {}

    cancelPendingRequestsonRouteChange() {
        this.router.events.subscribe(
            (event) => {
                if (event instanceof ActivationEnd) {
                    // this.cancelPendingRequests()
                }
            },
            (err) => {
                console.log(err);
            },
        );
    }
    /** Cancels all pending Http requests. */
    public cancelPendingRequests() {
        this.cancelPendingRequests$.next();
    }

    public onCancelPendingRequests() {
        return this.cancelPendingRequests$.asObservable();
    }
}
