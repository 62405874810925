import { Action } from '@ngrx/store';
import { Customer } from 'app/shared/model/customer.list.model';

// store the selected customer
export const SetCustomer = 'Set Customer';
export class SET_SELECTED_CUSTOMER implements Action {
    readonly type = SetCustomer;
    constructor(public customers: Customer) {}
}

// store all the cusomter
export const SetListOfCustomers = 'SET LIST OF CUSOMTERS';
export class SET_LIST_OF_CUSTOMERS implements Action {
    readonly type = SetListOfCustomers;
    constructor(public listOfCustomers: Customer[]) {}
}
export type Actions = SET_SELECTED_CUSTOMER | SET_LIST_OF_CUSTOMERS;
