import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'not-found',
    templateUrl: '404.component.html',
})
export class NotFoundComponent {
    routeBack() {
        location.href = location.origin;
    }
}
