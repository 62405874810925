import { Injectable } from '@angular/core';
import { TableService } from '../../../services/comp/table.service';

const upOrder = 'asc';
@Injectable()
export class defaultProps {
    constructor(private tableService: TableService) { }
    uTableid = 'table';
    rowProps: IRowConfig = {
        nullMessage: 'None',
        header: 'Data header',
        type: 'string',
        ArrayObjectKey: 'noKey',
        sort: (key, order, ev, rows) => {
            return this.tableService.sortRows(key, order, ev, rows);
        },
    };
    legends: legend[] = [];
    tableProps: tableProps = {
        sort: false,
        download: true,
        refresh: false,
        search: true,
    };
}

export interface IRowConfig {
    nullMessage?: string;
    width?: string;
    header: string;
    key?: string;
    style?: any;
    headAlign?: 'left' | 'center' | 'right';
    rowAlign?: 'left' | 'center' | 'right';
    type?: 'string' | 'array' | 'icon' | 'date' | 'ip' | 'bytes' | 'html';
    format?: string;
    sort?: Function;
    dataStyle?: any;
    ArrayObjectKey?: string;
    valueFormatter?: (value: any) => string;
    copy?: boolean;
}

export interface legend {
    iconName?: string;
    label?: string;
    key?: any;
}

export interface tableProps {
    sort: boolean;
    download: boolean;
    refresh: boolean;
    search: boolean;
}
