import { Action } from '@ngrx/store';

export enum SuperAdminActionTypes {
    isTenantSelected = '[SuperAdmin] isTenantSelected',
}

export class isTenantSelected implements Action {
    readonly type = SuperAdminActionTypes.isTenantSelected;
    constructor(public payload: boolean) {}
}

export type SuperAdminActions = isTenantSelected;
