import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from 'app/shared/components/custom-snackbar/custom-snackbar.component';
import { Subject } from 'rxjs';
declare var swal: any;
import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class AlertService {
    public pollingRestart = new Subject<any>();

    private slowInternetSnackRef;

    constructor(private router: Router, private snackbar: MatSnackBar) {
        // clear alert message on route change
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // clear alert
                this.dismissCurrent();
            }
        });
    }

    snack(msg: string, action: string = 'OK', duration: any = 3000) {
        const snackref = this.snackbar.open(msg, action, {
            duration: duration,
        });
        return snackref;
    }

    getSnackMessage(snack: MatSnackBarRef<any>) {
        return snack.instance.data.message;
    }

    showSlowInternet() {
        this.slowInternetSnackRef = this.snackbar.openFromComponent(CustomSnackbarComponent, {
            data: "Loading...You'r Internet seems to be very slow",
            verticalPosition: 'top',
            panelClass: ['customSnackClass'],
        });
    }

    dismissSlowInternetSnack() {
        if (this.slowInternetSnackRef) {
            this.slowInternetSnackRef.dismiss();
        }
    }

    showSelectDevice() {
        this.swalError('Please Select Device');
    }

    dismissableSnack(msg: string, action: string) {
        const snack = this.snackbar.open(msg, action);
        return snack;
    }

    dismissCurrent() {
        this.snackbar.dismiss();
    }

    showDeleting() {
        return this.snackbar.openFromComponent(CustomSnackbarComponent, {
            data: 'Deleting <i *ngIf="requestLoading" class="fa fa-spinner fa-pulse fa-fw"></i>',
        });
    }

    swalSuccess(title) {
        const toast = swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
        });

        toast({
            type: 'success',
            title,
        });
        return toast;
    }

    swalError(title?) {
        const toast = swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
        });

        toast({
            type: 'error',
            title: title || 'error',
        });
        return toast;
    }

    addSuccess(configName) {
        let message = `${configName} added successfully`;
        return this.swalSuccess(message);
    }

    editSuccess(configName) {
        let message = `${configName} edited successfully`;
        return this.swalSuccess(message);
    }

    deleteSuccess(configName) {
        let message = `${configName} deleted successfully`;
        return this.swalSuccess(message);
    }

    swalModify() {
        const res = swal({
            text: 'are you sure to modify this?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Modify it!',
        })
            .then((result) => {
                if (result.value) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch((e) => {});

        return res;
    }

    swalConfirm(text, confirm) {
        const res = swal({
            // title: 'Are you sure?',
            text,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirm,
        })
            .then((result) => {
                if (result.value) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch((e) => {});

        return res;
    }

    swalmodal(success, error) {
        Swal.fire({
            title: '<strong>Backup Process Completed</strong>',

            html: `
            <div class="row">
              ${success}
              ${error}

            </div>
           `,

            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
        });
    }
}
