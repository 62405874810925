import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import * as _ from 'lodash';
import { CustomerListService } from 'app/shared/services/comp';
import { CommonService } from 'app/shared/services/core';
import { Customer } from 'app/shared/model/customer.list.model';
import { skipWhile } from 'rxjs/operators';

@Component({
    selector: 'select-tenant',
    templateUrl: './select-tenant.component.html',
    styleUrls: ['./select-tenant.component.scss'],
})
export class SelectTenantComponent implements OnInit {
    tenants = [];
    selectedTenant: Customer = {
        tenantname: 'Select Tenant',
        id: '-1',
    };
    constructor(
        private appStore: Store<CosgridAppStore>,
        private customerListService: CustomerListService,
        private commonService: CommonService,
    ) {}

    ngOnInit() {
        this.subscribeToCustomerList();
        this.subscribeToSelectedCustomer();
    }

    getAllTenants() {
        this.customerListService.getCustomerList().subscribe((customerList) => {
            this.customerListService.dispatchAllCusomtersToStore(customerList);
        });
    }

    subscribeToCustomerList() {
        this.appStore
            .select((slices) => slices.customerSlice.listOfCustomers)
            .pipe(skipWhile((customerList) => _.isEmpty(customerList)))
            .subscribe((customerList) => {
                this.tenants = customerList;
            });
    }

    subscribeToSelectedCustomer() {
        this.appStore
            .select((slices) => slices.customerSlice.selectedCustomer)
            .pipe(skipWhile((selectedCustomer) => _.isEmpty(selectedCustomer)))
            .subscribe((selectedCustomer) => {
                if (!Object.keys(selectedCustomer).length) {
                    this.selectedTenant = {
                        tenantname: 'Select Tenant',
                    };
                } else {
                    this.selectedTenant = selectedCustomer;
                }
            });
    }

    tenantChanged(tenant): any {
        this.commonService.updateSelectedTenant(tenant);
    }
}
