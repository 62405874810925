import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'custom-button',
    template: ` <button (click)="click($event)" class="btn btn-outline-primary" style="float:right;" [disabled]="disabled">
        {{ name }} <i *ngIf="loading" class="fas fa-circle-notch fa-spin"></i>
    </button>`,
})
export class CustomButtonComponent implements OnChanges {
    @Input() type: 'save' | 'add' = 'save';
    @Input() loading: boolean = false;
    @Input() disabled: boolean = false;
    @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

    name: string = 'Save Changes';
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if ('type' in changes) {
            if (changes.type.currentValue === 'save') {
                this.name = 'Save Changes';
            } else {
                this.name = 'Add';
            }
        }
    }

    click(ev) {
        this.clicked.emit(ev);
    }
}
