export const environment = {
    production: true,
    endpoint: 'https://cosgridnetworks.in/',
    firebaseConfig: {
        apiKey: 'AIzaSyA0RsKNnhSj9DPdB9doX2N5Hlfz0lG4SJc',
        authDomain: 'alarms-42d94.firebaseapp.com',
        databaseURL: 'https://alarms-42d94.firebaseio.com',
        projectId: 'alarms-42d94',
        storageBucket: 'alarms-42d94.appspot.com',
        messagingSenderId: '728127574542',
        appId: '1:728127574542:web:2e376d593babecfe27625f',
    },
    googleClientId: '728127574542-nu1d78pb3uialijb7attlagl31vddurt.apps.googleusercontent.com',
    agmCoreKey: 'AIzaSyAzfCmNKLOYWZwsIu_FXkbVLzSBYZ1N_E0'
};
