import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[cosgridShowHidePass]',
})
export class ShowHidePassDirective {
    private _shown = false;

    constructor(private el: ElementRef) {
        this.setup();
    }

    setup() {
        const parent = this.el.nativeElement.parentNode;
        const icon = document.createElement('i');
        icon.className = 'fa fa-eye';
        icon.style.paddingLeft = '3px';
        icon.style.marginTop = '7px';
        icon.style.color = 'gray';
        icon.style.cursor = 'pointer';
        icon.style.height = '11px';
        icon.style.position = 'relative';
        icon.style.left = '-22px';
        icon.style.zIndex = '100';
        icon.style.backgroundColor = 'white';

        icon.addEventListener('click', (event) => {
            this.toggle(icon);
        });
        parent.appendChild(icon);
        parent.querySelector('input').style.minWidth = '100%'; // to make the input element occupy
    }

    toggle(icon: HTMLElement) {
        this._shown = !this._shown;
        if (this._shown) {
            this.el.nativeElement.setAttribute('type', 'text');
            icon.className = '';
            icon.className = 'fa fa-eye-slash';
        } else {
            this.el.nativeElement.setAttribute('type', 'password');
            icon.className = '';
            icon.className = 'fa fa-eye';
        }
    }
}
