import { createSelector } from '@ngrx/store';
import { SharedSlice } from 'app/store/state/shared.state';

const selectNetworks = (state) => state;

export const selectCurrentUser = createSelector(selectNetworks, (state: SharedSlice) => state.currentUser);

export const selectTenantName = createSelector(selectNetworks, (state: SharedSlice) => state.tenantName);

export const selectTenantID = createSelector(selectNetworks, (state: SharedSlice) => state.tenantID);

export const selectTenants = createSelector(selectNetworks, (state: SharedSlice) => state.tenants);

export const selectSelectedTenant = createSelector(selectNetworks, (state: SharedSlice) => state.selectedTenant);
