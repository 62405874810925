import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/configs/constants';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { GlobalService } from 'app/shared/services/core/global.service';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HttpUtil {
    constructor(
        private globalConstants: GlobalConstants,
        private http: HttpClient,
        private global: GlobalService,
        private handler: HttpBackend,
    ) {
        this.http = new HttpClient(this.handler);
        global.setA(this); // to avoid circular dependecy
    }

    login(body: any, options?) {
        return this.http.post(this.globalConstants.LOGIN_URL, body).pipe(
            catchError((err) => {
                this.global.handleError(err);
                return throwError(() => err);
            }),
        );
    }

    register(url: any, body: any, options?) {
        const endpoint = this.globalConstants.endpoint + url;
        if (!options) {
            return this.http.post(endpoint, body).pipe(
                catchError((err) => {
                    this.global.handleError(err);
                    return throwError(() => err);
                }),
            );
        }
        return this.http.post(endpoint, body).pipe(
            catchError((err) => {
                this.global.handleError(err);
                return throwError(() => err);
            }),
        );
    }

    activate(url: any, body: any) {
        const endpoint = this.globalConstants.endpointUrl + url;
        return this.http.post(endpoint, body);
    }

    Logout(options?): Observable<any> {
        const endpoint = this.globalConstants.LOGOUT_URL;
        return this.http.post(endpoint, {}).pipe(
            catchError((err) => {
                this.global.handleError(err);
                return throwError(() => err);
            }),
        );
    }
    /**
     * This method updates the token to the global variable
     * @param token of type string
     */
    /*updateHeaders(token: string) {
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Authorization', 'JWT ' + token);
        this.requestOptions.headers = this.headers;
        this.requestOptions.withCredentials = true;
    }*/

    /*public refresh() {
      // )

      this.http.post(this.globalConstants.endpointUrl + "api/config/refresh/", {
        // token: localStorage.getItem('token')
        refresh: localStorage.getItem('refresh')
     }).
      subscribe((res: any) => {
        // let body = JSON.parse(res._body);

    //  localStorage.setItem('token',res.token);
    this.userService.setToken(res.access);
    this.updateHeaders(res.access);
      }, (err) => {
          this.router.navigateByUrl('/logout');
      });
}*/
}
