import * as _ from 'lodash';
import * as CUSTOMER_ACTIONS from 'app/store/actions/customer.actions';
import { Customer } from 'app/shared/model/customer.list.model';
import { encryptStorage } from 'app/core/services/local-storage.service';

export interface CustomerSlice {
    listOfCustomers?: Customer[];
    selectedCustomer?: Customer;
}

const initialState: CustomerSlice = {
    listOfCustomers: [],
    selectedCustomer: {},
};

try {
    initialState.selectedCustomer = encryptStorage.getItem('selectedCustomer');
    initialState.listOfCustomers = encryptStorage.getItem('listOfCustomers');
} catch {}

export function reducer(state = initialState, action: CUSTOMER_ACTIONS.Actions): CustomerSlice {
    switch (action.type) {
        case CUSTOMER_ACTIONS.SetCustomer: {
            initialState.selectedCustomer = action.customers;
            // localStorage.setItem('selectedCustomer', JSON.stringify(initialState.selectedCustomer));
            encryptStorage.setItem('selectedCustomer', JSON.stringify(initialState.selectedCustomer));
            return initialState;
        }
        case CUSTOMER_ACTIONS.SetListOfCustomers: {
            initialState.listOfCustomers = action.listOfCustomers;
            // localStorage.setItem('listOfCustomers', JSON.stringify(initialState.listOfCustomers));
            encryptStorage.setItem('listOfCustomers', JSON.stringify(initialState.listOfCustomers));
            return initialState;
        }
        default:
            return initialState;
    }
}
