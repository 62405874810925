import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
declare var $: any;
@Component({
    selector: 'cosgrid-configuration-header',
    templateUrl: './configuration-header.component.html',
    styleUrls: ['./configuration-header.component.scss'],
})
export class ConfigurationHeaderComponent implements OnInit {
    constructor() {}

    @Input() title: string;
    @Input() content: string;
    @Input() isSettings = false;
    @Input() buttonToggleText: string;
    @Input() buttonState: boolean;
    @Output() reload = new EventEmitter<string>();
    @Output() toggleConfig = new EventEmitter<any>();
    @Output() settings = new EventEmitter<any>();

    ngOnInit() {
        $('[data-toggle=popover]').popover();
    }

    reloadFunc() {
        this.reload.emit();
    }
    toggleConf() {
        this.toggleConfig.emit();
    }
    emitSettingsFunction() {
        this.settings.emit();
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            this.buttonState = changes.buttonState.currentValue;
        } catch (err) {}
    }
}
