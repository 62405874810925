import { Injectable } from '@angular/core';
import { CosgridAppStore } from 'app/store/app.store';
import { Store } from '@ngrx/store';
import * as NetworkActions from 'app/store/actions/network.actions';
import { Network } from 'app/shared/model/network';

@Injectable({
    providedIn: 'root',
})
export class NetworkListService {
    constructor(private store: Store<CosgridAppStore>) {}
    dispatchAllNetworksToStore(listOfNetworks: Network[]) {
        this.store.dispatch(new NetworkActions.SetNetworks(listOfNetworks));
    }

    dispatchSelectedNetworkToStore(selectedNetwork: Network) {
        this.store.dispatch(new NetworkActions.SetSelectedNetwork(selectedNetwork));
    }
}
