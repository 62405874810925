import { ValidateUniqueName } from 'app/shared/validators/uniqueConfigNameValidator';
import {
    Component,
    OnInit,
    forwardRef,
    AfterViewInit,
    OnDestroy,
    Self,
    Optional,
    Injector,
    Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl, Validators, NgControl } from '@angular/forms';
import { NAME } from 'app/configs/constants';
import { Subscription } from 'rxjs';
import { hasRequiredField } from 'app/shared/services/misc';
import { GlobalService } from 'app/shared/services/core';

@Component({
    selector: 'config-name',
    templateUrl: './config-name.component.html',
    styleUrls: ['./config-name.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConfigNameComponent),
            multi: true,
        },
    ],
})
export class ConfigNameComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @Input() className: string = '';
    @Input() needLabel: boolean = true;
    @Input() placeholder: string = 'Enter Name';
    @Input() description: string;
    name = new UntypedFormControl(['', [Validators.pattern(NAME)]]);
    onChange: Function;
    onTouched: Function;

    valueChangeSub: Subscription;

    formControl: UntypedFormControl;

    //current formcontrol name is binded to the attribute name to get the elements easily with cypress
    currentFormControlName;

    isNgModel: boolean = false;
    isRequired: boolean = false;

    // variables for config unique name validation
    subscription: Subscription;
    uniqueNameRegex = /$unmatchable/; // since the validator looks for words that matches the regex and returns not unique, if it cant match anything it will not return error ; this regex is a fallback is we didnt get the response
    constructor(
        @Self()
        @Optional()
        private injector: Injector,
        private globalSevrice: GlobalService,
    ) {
        this.subscription = this.globalSevrice.currentConfig.subscribe((res) => {
            this.uniqueNameRegex = this.generateRegexFromArray(res);
            this.updateValidation();
        });
    }

    ngAfterViewInit() {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.currentFormControlName = ngControl.name; // for cypress testing purposes
            this.name = ngControl.control as UntypedFormControl;
            this.updateValidation();
        } else {
            this.isNgModel = true;
            this.subscribeToValueChanges();
            // Component is missing form control binding so we have to use ngmodel
        }
    }

    ngOnDestroy() {
        if (this.isNgModel) {
            this.valueChangeSub.unsubscribe();
        }
        // this.globalSevrice.clearCurrentConfig()
        this.subscription.unsubscribe();
    }

    updateValidation() {
        /*
    if the user tries to edit the configuration, we have to remove the validation for that
    particaular config name because the user cannot save without editing the name since we
    have validation for unique name which will also match the cuurent name of the config.
    */
        let controlValue = this.name.value;
        if (!Array.isArray(controlValue) && controlValue) {
            let temp: any = this.uniqueNameRegex.toString();
            this.uniqueNameRegex = temp.replace(controlValue, controlValue + 'editxy_987');
        }

        if (hasRequiredField(this.name)) {
            this.isRequired = true;
            this.name.setValidators([
                Validators.required,
                Validators.pattern(NAME),
                ValidateUniqueName(this.uniqueNameRegex),
            ]);
        } else {
            this.isRequired = false;
            this.name.setValidators([Validators.pattern(NAME), ValidateUniqueName(this.uniqueNameRegex)]);
        }
        this.name.updateValueAndValidity();
    }

    subscribeToValueChanges() {
        this.valueChangeSub = this.name.valueChanges.subscribe((res) => {
            this.onChange(res);
        });
    }

    writeValue(value: string): void {
        if (value !== undefined) {
            this.name.setValue(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // utill functions
    generateRegexFromArray(array: Array<any>) {
        if (array.length) {
            let names = array.toString().replace(/,/g, '|');
            return new RegExp(/$unmatchable/);
        }
        return /$unmatchable/; // since the validator looks for words that matches the regex and returns not unique, if it cant match anything it will not return error ; this regex is a fallback is we didnt get the response
    }
}
