import { SettingAction, SETTING_ACTION } from './../actions/settings.action';
import { ITenantSettings } from 'app/shared/model/settings.model';

export interface ISettings {
    setting: ITenantSettings;
}

export const initialState: ISettings = {
    setting: {
        commercialDashboard: false,
        netflow: false,
        vpnStatus: false,
        wan3: false,
        vpnDashboard: false,
        devReg_autoApprove: true
    },
};

export function reducer(state = initialState, action: SettingAction) {
    switch (action.type) {
        case SETTING_ACTION:
            return {
                ...state,
                setting: action.payload,
            };
        default:
            return state;
    }
}
