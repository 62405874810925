import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IRowConfig, tableProps, legend } from 'app/shared/components/table/utills/defaultProps';
import { AlarmstatusService } from 'app/modules/sd-wan-gateway/sub-modules/status/services/alarmstatus.service';

@Component({
    selector: 'cosgrid-wanalarmstatus',
    templateUrl: './wanalarmstatus.component.html',
    styleUrls: ['./wanalarmstatus.component.scss'],
})
export class WanalarmstatusComponent implements OnInit {
    alertData: any[] = [];
    filteredRows: any[] = [];
    isSelected: boolean = false;
    $sub: any;
    button: String[];
    loading: boolean = true;
    temp;

    rowProps: Array<IRowConfig> = [
        { header: 'State', key: 'state', type: 'icon', style: { 'padding-left': '30px' } },
        { header: 'Interface', key: 'interface' },
        { header: 'Time', key: 'time' },
    ];
    tableProps: tableProps = {
        sort: false,
        download: true,
        refresh: true,
        search: true,
    };

    selectedFilter = 'All';
    legends: Array<legend> = [
        { iconName: 'positive', label: 'OK', key: 'Ok' },
        { iconName: 'negative', label: 'Alerting', key: 'Alerting' },
    ];

    constructor(
        public dialog: MatDialog,
        private alarmstatusservice: AlarmstatusService, //for getting interface list for buttons
    ) {}

    ngOnInit() {
        this.getAlerts();
        this.getInterfaces();
    }

    ngOnDestroy() {
        this.$sub.unsubscribe();
    }

    getAlerts() {
        this.loading = true;
        this.$sub = this.alarmstatusservice.getWanStatus().subscribe(
            (configuration: any) => {
                this.alertData = configuration;
                this.filterData(this.selectedFilter);

                this.loading = false; // to load the "no availale info " only when data table UI is loaded
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    filterData(val) {
        this.selectedFilter = val;
        if (val === 'All') {
            this.filteredRows = this.alertData;
        } else {
            let filter = this.temp[val];
            this.filteredRows = this.alertData.filter((inp) => inp.interface === filter);
        }
    }

    getInterfaces() {
        this.alarmstatusservice.getInterfaceList().subscribe((data: any) => {
            this.temp = data.wan_alarms_interfaces;
            this.button = Object.keys(data.wan_alarms_interfaces);
        });
    }
}
