import { Component, OnInit, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'cossgrid-switch',
    template: `
        <div>
            <mat-slide-toggle [(ngModel)]="status" disalbed="disabled"></mat-slide-toggle>
            <!--<ui-switch [(ngModel)]="status" disabled="disabled" color="blue"></ui-switch>-->
        </div>
    `,
})
export class SwitchComponent implements OnInit {
    @Input() status: boolean = false;
    @Input() disabled: boolean = false;
    constructor() {}

    ngOnInit() {}
}
