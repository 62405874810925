import { encryptStorage } from 'app/core/services/local-storage.service';
import { Template } from 'app/shared/model/template.model';

export interface TemplateSlice {
    selectedTemplate: Template;
    templates: Template[];
}
export const allTemplates: Template = {
    name: '',
    id: '',
};

export const initialTemplateState: TemplateSlice = {
    selectedTemplate: encryptStorage.getItem('template') ? encryptStorage.getItem('template') : allTemplates,
    templates: [],
};
