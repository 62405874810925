import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/configs/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomerListService } from 'app/shared/services/comp';

@Injectable({
    providedIn: 'root',
})
export class DashboardConfService {
    constructor(
        private globalConstants: GlobalConstants,
        private http: HttpClient,
        private customerListService: CustomerListService,
    ) {}

    getDashboardConf() {
        const httpParams = this.getTenantParams();

        return this.http.get(this.globalConstants.TENANT_PREFERNCE + '?' + httpParams);
    }

    getCommercialViewData() {
        const httpParams = this.getTenantParams();
        return this.http.get(this.globalConstants.TENANT_COMMERCIAL_DATA + '?' + httpParams);
    }

    postCommercialViewData(data) {
        const httpParams = this.getTenantParams();
        return this.http.post(this.globalConstants.TENANT_COMMERCIAL_DATA + '?' + httpParams, data);
    }

    getTenantParams() {
        return new HttpParams().set('tid', this.customerListService.getTenantId());
    }
}
