<span [ngSwitch]="icon">
    <i *ngSwitchCase="'positive'" style="color: #22aa00;" [matTooltip]="tooltip" class="fa fa-square"></i>
    <i *ngSwitchCase="'negative'" style="color: red;" class="fa fa-square"></i>
    <i *ngSwitchCase="'intermediate'" style="color: orange;" class="fa fa-square"></i>
    <i *ngSwitchCase="'online'" style="color: rgb(97, 106, 232);" class="fa fa-square"></i>
    <i *ngSwitchCase="'offline'" style="color: red;" class="fa fa-square"></i>
    <i *ngSwitchCase="'connected'" style="color: #22aa00;" class="fa fa-square"></i>

    <!-- Utill ICONS -->
    <mat-icon *ngSwitchCase="'tick'" [matTooltip]="tooltip" svgIcon="accept"></mat-icon>
    <mat-icon *ngSwitchCase="'wrong'" [matTooltip]="tooltip" svgIcon="deny"></mat-icon>
    <mat-icon *ngSwitchCase="'block'" [matTooltip]="tooltip" svgIcon="reject"></mat-icon>
    <mat-icon *ngSwitchCase="'applicable'" [matTooltip]="tooltip" svgIcon="correct"></mat-icon>
    <mat-icon *ngSwitchCase="'not_applicable'" [matTooltip]="tooltip" svgIcon="wrong"></mat-icon>
    <mat-icon *ngSwitchCase="'arrow-in'" [matTooltip]="tooltip" svgIcon="arrow-in"></mat-icon>
    <mat-icon *ngSwitchCase="'arrow-out'" [matTooltip]="tooltip" svgIcon="arrow-out"></mat-icon>
    <mat-icon *ngSwitchCase="'enabled'" [matTooltip]="tooltip" svgIcon="enabled"></mat-icon>
    <mat-icon *ngSwitchCase="'disabled'" [matTooltip]="tooltip" svgIcon="disabled"></mat-icon>


    <i *ngSwitchCase="'registered'" style="color: orange;" class="fas fa-check"></i>
</span>