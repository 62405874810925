import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'cosgrid-no-conf',
    templateUrl: './no-conf.component.html',
    styleUrls: ['./no-conf.component.scss'],
})
export class NoConfComponent implements OnInit {
    @Input() data;
    @Input() loading = false;
    @Input() message;
    @Input() topic: string;
    @Input() isStatus = false;
    @Input() image;
    @Input() type: 'configuration' | 'status' | 'networks' | 'user' | 'configTemp' = 'configuration';
    dataLength: number;
    constructor() { }

    ngOnInit() {
        this.dataLength = this.computeLength(this.data);

        if (this.isStatus) {
            this.type = 'status';
        }
    }
    ngOnChanges(changes: SimpleChanges) {

        try {
            this.dataLength = this.computeLength(changes.data.currentValue);
        } catch (err) { }
    }
    computeLength = (data) => {
        try {
            return data.length;
        } catch (err) {
            return 0;
        }
    };
}
