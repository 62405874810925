import { Injectable } from '@angular/core'
import { GlobalConstants } from 'app/configs/constants'
import { HttpClient, HttpParams } from '@angular/common/http'
import { CustomerListService } from 'app/shared/services/comp'

@Injectable({
  providedIn: 'root',
})
export class TenantSettingsService {
  constructor(
    private http: HttpClient,
    private globalConstants: GlobalConstants,
    private customerService: CustomerListService,
  ) {}

  getTenantSettings(tid) {
    let params = new HttpParams().set('tid', this.customerService.getTenantId())
    return this.http.get(this.globalConstants.TENANT_PREFERNCE + '?' + params)
  }
  saveTenantSettings(data) {
    let params = new HttpParams().set('tid', this.customerService.getTenantId())
    return this.http.post(
      this.globalConstants.TENANT_PREFERNCE + '?' + params,
      data,
    )
  }
}
