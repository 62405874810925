import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayObjectKey',
})
export class ArrayObjectKeyPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (args === 'noKey') {
            return value;
        } else {
            return value[args];
        }
    }
}
