<div class="chatContainer">
  <div class="header">
    <h3>Live Chat Support</h3>
    <span (click)="closeChatBox()">
      <mat-icon class="closeButton">
        close
      </mat-icon>
    </span>
  </div>
  <div *ngIf="loading" style="min-height:82% ">
      <i class="fas fa-circle-notch fa-spin loading"></i>
    </div>
  <div *ngIf="!loading"  class="chatBox">
      <div *ngIf="!permissionGranted"  class="noPermission alert alert-danger
      alert-dismissible fade show"
      role="alert"
      >You have disabled Desktop Notifications,Enable Them in browser settings if needed.
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>

    <div class="messageContainer" [ngClass]="message.source" *ngFor="let message of channelInfo.messages" >
    <div  class="message"  >
      <div class="messageHeader">
        {{message.from}}
      </div>
      <div class="messageBody">
        {{message.body}} 
      </div>
      <div class="timestamp">
        {{message.timestamp | date:'h:mm a, d/M/yy'}}
      </div>
    </div>
    </div>
  </div>
  <div class="inputContainer">
    <input [(ngModel)]="inputMessage" (keyup.enter)="sendMessage()" type="text" class="form-control form-control-sm">
    <div (click)="sendMessage()" class="sendButton">
      <mat-icon>send</mat-icon>
    </div>
  </div>
</div>