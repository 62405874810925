import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import * as _ from 'lodash';
import { AlertService, SupportService } from 'app/shared/services/misc';
import { CustomerListService } from 'app/shared/services/comp';
import { skipWhile } from 'rxjs/operators';

@Component({
    selector: 'cosgrid-ticket-raise',
    templateUrl: './ticket-raise.component.html',
    styleUrls: ['./ticket-raise.component.scss'],
})
export class TicketRaiseComponent implements OnInit {
    currentTenantName: string = this.customerListService.getTenantName();
    loading: boolean = false;
    devices = [];
    product: string = ''
    ticketRaiseForm: UntypedFormGroup = this.fb.group({
        product: this.product,
        ticketId: Date.now().toString(36) + Math.random().toString(36).substr(2, 9),
        tenant_name: this.currentTenantName,
        issue_sub: ['', Validators.required],
        issue_in: [[], Validators.required],
        priority: ['', Validators.required],
        mode_contact: ['Email'],
        issue_desc: [''],
    });

    constructor(
        private fb: UntypedFormBuilder,
        private appStore: Store<CosgridAppStore>,
        private supportService: SupportService,
        private alert: AlertService,
        private customerListService: CustomerListService,
    ) { }

    ngOnInit() {

        this.appStore.select((state) => state.settingSlice).subscribe((value) => {
            console.log(value.setting.vpnDashboard);

            if (!value.setting.vpnDashboard)
                this.product = 'Reflex SD-WAN'
            else
                this.product = 'MicroZAccess'
            console.log(this.product, "product");

            this.ticketRaiseForm.get('product').setValue(this.product)
            console.log(this.ticketRaiseForm.value);

        })
        this.getAllDevices();
    }

    getAllDevices() {
        this.appStore
            .select((slices) => slices.deviceSlice.listOfDevices)
            .pipe(skipWhile((listOfDevices) => _.isEmpty(listOfDevices)))
            .subscribe((listOfDevices) => {
                if (listOfDevices) {
                    this.devices = listOfDevices;
                }
            });
    }
    validateForm() {
        if (!this.ticketRaiseForm.valid) {
            for (let i in this.ticketRaiseForm.controls) {
                this.ticketRaiseForm.controls[i].markAsTouched();
            }
            return false;
        } else {
            return true;
        }
    }

    submitForm() {
        if (this.validateForm()) {
            this.loading = true;
            console.log(this.ticketRaiseForm.value);

            this.supportService.postForm(this.ticketRaiseForm.value).subscribe(
                () => {
                    this.alert.swalSuccess('Your Ticket Has been Successfully submitted');
                    this.loading = false;
                    this.ticketRaiseForm.reset()
                },
                (err) => {
                    this.loading = false;
                },
            );
        }
    }
}
