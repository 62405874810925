<div class="tableHeading">
  <h3>Performance Alarms</h3>
</div>
<div class="float-right">
  <button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="filterMenu" class="btn btn-light" *ngIf="!loading">
    <i class="fas fa-filter"></i>
    &nbsp;{{selectedFilter}}
  </button>
  <mat-menu #filterMenu>
    <button mat-menu-item (click)="filterData('All')">All
    </button>
    <button *ngFor="let item of button" mat-menu-item (click)="filterData(item)">{{item}}
    </button>
  </mat-menu>
</div>
<cosgrid-table [nullMessage]="'No Performance Reachability History available yet!'" [rows]="filteredRows"
  [rowProps]="rowProps" [tableProps]="tableProps" [isConfig]="false" [refreshLoading]="loading"
  (refreshed)="getAlerts()" [legends]="legends"></cosgrid-table>