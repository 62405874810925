<div class="d-flex justify-content-between header p-4">
    <div class="tableHeading">
        <h3>{{ title }}</h3>
    </div>
    <div class="d-flex">
        <span *ngIf="isSettings">
            <!-- <span id="settings" > Settings</span>  -->
            <!-- <i (click)="emitSettingsFunction()" class="fa fa-cog mr-2" aria-hidden="true"></i> -->
            <button (click)="emitSettingsFunction()" class="btn btn-light">
                <i class="fa fa-cog mr-2"></i>
                Settings
            </button>
        </span>
        <h5>{{ buttonToggleText }}</h5>
        <mat-slide-toggle [(ngModel)]="buttonState" (change)="toggleConf()" *ngIf="buttonToggleText"></mat-slide-toggle>
        <span>
            <button (click)="reloadFunc()" class="btn btn-light">
                <i class="fa fa-sync mr-2"></i>
                Refresh
            </button>
        </span>
        <!-- <i (click)="reloadFunc()" aria-hidden="true" class="fa fa-refresh"></i> -->
        <!-- <i class="fa fa-question-circle-o " aria-hidden="true" data-container="body" [attr.title]="title"
            data-html="true" data-trigger="focus" tabindex="0" data-toggle="popover" data-placement="bottom"
            [attr.data-content]="content">
        </i> -->
    </div>
</div>
