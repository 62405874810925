<div class="mGraph-wrapper">
    <div class="mGraph" id="timeSeriesChart" echarts [options]="options"></div>
</div>
<table *ngIf="informationBox.max || informationBox.min || informationBox.current || informationBox.average">
    <tr style="margin-bottom: 10px;">
        <th></th>
        <th *ngIf="informationBox.max">Max</th>
        <th *ngIf="informationBox.min">Min</th>
        <th *ngIf="informationBox.average">average</th>
        <th *ngIf="informationBox.current">Current</th>
    </tr>
    <tr *ngFor="let item of names; let i=index" style="margin-bottom: 5px;">
        <td>{{item}}</td>
        <td *ngIf="informationBox.max">{{description[i]?.max}}</td>
        <td *ngIf="informationBox.min">{{description[i]?.min}}</td>
        <td *ngIf="informationBox.average">{{description[i]?.average}}</td>
        <td *ngIf="informationBox.current">{{description[i]?.current}}</td>
    </tr>
</table>