import { values } from 'lodash';
import { ChartserviceService } from 'app/modules/sd-wan-gateway/sub-modules/configure/services/chartservice.service';
import { Component, Input, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import { CustomerListService } from 'app/shared/services/comp';

@Component({
    selector: 'cosgrid-netflow-graphs',
    templateUrl: './netflow-graphs.component.html',
    styleUrls: ['./netflow-graphs.component.scss'],
})
export class NetflowGraphsComponent implements OnInit {
    currentUrl: any = {};
    @Input() netflowTypes = {};
    //  endPointUrls : any = {
    //     "top-sources" : {
    //       "device" : "https://poc.cosgridnetworks.in/api/v1/status/netflow/98b62727-cdae-4304-9af2-eb1266c67325/top-sources/",
    //       "protocols" : "https://poc.cosgridnetworks.in/api/v1/status/netflow/98b62727-cdae-4304-9af2-eb1266c67325/top-source-protocols/",
    //       "applications" : "https://poc.cosgridnetworks.in/api/v1/status/netflow/98b62727-cdae-4304-9af2-eb1266c67325/top-source-applications/",
    //       "ports" : "https://poc.cosgridnetworks.in/api/v1/status/netflow/98b62727-cdae-4304-9af2-eb1266c67325/top-source-ports/"
    //     },
    //     "top-destinations"  : {
    //       "device" : "https://poc.cosgridnetworks.in/api/v1/status/netflow/98b62727-cdae-4304-9af2-eb1266c67325/top-destinations/",
    //       "protocols" : "https://poc.cosgridnetworks.in/api/v1/status/netflow/98b62727-cdae-4304-9af2-eb1266c67325/top-destination-protocols/",
    //       "applications" : "https://poc.cosgridnetworks.in/api/v1/status/netflow/98b62727-cdae-4304-9af2-eb1266c67325/top-destination-applications/",
    //       "ports" : "https://poc.cosgridnetworks.in/api/v1/status/netflow/98b62727-cdae-4304-9af2-eb1266c67325/top-destination-ports/"
    //     },
    //  }
    isEmpty1: Boolean;
    isEmpty: Boolean;
    canvas: any;
    canvas1: any;
    ctx: any;
    ctx1: any;
    data: any;
    loading = false;
    loading1 = false;
    myChart;
    myChart1;
    renderTime = 100;
    graphConfData1Ip_addr = ''
    barChartGraph = {
        keys: [],
        values: []
    }
    pieChartGraph = {
        data: [],
        labels: []
    }
    graphConfData = {
        timeduration: 1440,
        top_count: 10,
        ip_addr: '',
        tenant_id: this.customerListService.getTenantId(),
    };

    graphConfData1 = {
        timeduration: 1440,
        top_count: 10,
        ip_addr: '',
        tenant_id: this.customerListService.getTenantId(),
    };
    userIps: Array<any> = [];

    sum: String = 'hii';
    flag: Boolean = false;
    flag1: Boolean = false;
    flag2: Boolean = false;
    flag3: Boolean = false;
    currentChartId = 1;

    constructor(private netflowService: ChartserviceService, private customerListService: CustomerListService) { }

    ngOnInit(): void {
        this.currentUrl = this.netflowTypes['type'];
        this.getTopDevices()
        this.getTopApplications()
        Chart.pluginService.register({
            beforeDraw: function (chart: any) {
                if (chart.config.options.elements.center && chart.config.type === 'doughnut') {
                    var ctx = chart.chart.ctx;
                    var centerConfig = chart.config.options.elements.center;
                    var fontStyle = centerConfig.fontStyle || 'Arial';
                    var txt = centerConfig.text;
                    var color = centerConfig.color || '#000';
                    var maxFontSize = centerConfig.maxFontSize || 25;
                    var sidePadding = centerConfig.sidePadding || 20;
                    var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
                    ctx.font = '17px ' + fontStyle;
                    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    var stringWidth = ctx.measureText(txt).width;
                    var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

                    // Find out how much the font can grow in width.
                    var widthRatio = elementWidth / stringWidth;
                    var newFontSize = Math.floor(30 * widthRatio);
                    var elementHeight = chart.innerRadius * 2;

                    // Pick a new font size so it will not be larger than the height of label.
                    var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                    var minFontSize = centerConfig.minFontSize;
                    var lineHeight = centerConfig.lineHeight || 25;
                    var wrapText = false;

                    if (minFontSize === undefined) {
                        minFontSize = 20;
                    }

                    if (minFontSize && fontSizeToUse < minFontSize) {
                        fontSizeToUse = minFontSize;
                        wrapText = true;
                    }

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                    var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                    ctx.font = fontSizeToUse + 'px ' + fontStyle;
                    ctx.fillStyle = color;

                    if (!wrapText) {
                        ctx.fillText(txt, centerX, centerY);
                        return;
                    }

                    var words = txt.split(' ');
                    var line = '';
                    var lines = [];

                    for (var n = 0; n < words.length; n++) {
                        var testLine = line + words[n] + ' ';
                        var metrics = ctx.measureText(testLine);
                        var testWidth = metrics.width;
                        if (testWidth > elementWidth && n > 0) {
                            lines.push(line);
                            line = words[n] + ' ';
                        } else {
                            line = testLine;
                        }
                    }

                    centerY -= (lines.length / 2) * lineHeight;

                    for (var n = 0; n < lines.length; n++) {
                        ctx.fillText(lines[n], centerX, centerY);
                        centerY += lineHeight;
                    }
                    //Draw text in center
                    ctx.fillText(line, centerX, centerY);
                }
            },
        });
    }
    changeChart(ev, type: string) {
        if (type === 'ip') {
            this.graphConfData.ip_addr = ev;
        }
        if (type === 'currentchart') {
            this.currentChartId = ev;
        }
        if (type === 'duration') {
            this.graphConfData.timeduration = ev;
        }
        if (this.currentChartId == 1) {
            this.getTopApplications();
        } else if (this.currentChartId == 2) {
            this.getTopProto();
        } else if (this.currentChartId == 3) {
            this.getTopPorts();
        }
    }

    changeChart1(val) {
        this.graphConfData1.timeduration = val;
        this.getTopDevices();
    }

    getTopDevices() {
        this.loading1 = true;
        this.isEmpty1 = false;
        const payload = {
            ip_addr: '',
            tenant_id: this.graphConfData1.tenant_id,
            timeduration: this.graphConfData1.timeduration,
            top_count: 10,
        };
        this.netflowService.getNetflowGraphs(this.netflowTypes['types'], payload).subscribe(
            (data: any) => {
                this.loading1 = false;
                if (data.length < 1) {
                    this.isEmpty1 = true;
                } else {
                    this.isEmpty1 = false;
                    setTimeout(() => {
                        this.renderTopDevicesChart(data, 'tenantGraph1');
                    }, this.renderTime);
                }
            },
            (err) => {
                this.loading1 = false;
                console.log(err);
            },
        );
    }

    renderTopDevicesChart(data: Array<any>, htmlId) {
        let keys = [];
        let values = [];
        const tooltips = [];
        data.sort(function (a, b) {
            return a['total']['value'] - b['total']['value'];
        });
        console.log(data);

        if (this.netflowTypes['types'] == 'top-destinations/') {
            keys = data.map((inp) => {
                let key = inp['key'];
                if (inp['address'] != 'unknown') {
                    let arr = inp['address'].split('-').reverse();
                    if (arr.length <= 1) {
                        arr = inp['address'].split('.').reverse();
                        key = arr.length >= 2 ? arr[1] + '.' + arr[0] : inp['key'];
                    } else {
                        key = arr[0];
                    }
                    key = key.slice(-13);
                }
                return key;
            });
        } else {
            keys = data.map((inp) => inp.key);
        }

        this.userIps = data.map((item) => item['key']);

        this.graphConfData.ip_addr = '';
        values = data.map((inp) => inp['total'].value / 1000000);


        this.barChartGraph = { keys, values }
        // const total = values.reduce((val, acu) => val + acu);
        // for (const i in data) {
        //     tooltips[i] = data[i].key + ' - ' + data[i].value;
        // }

        // if (this.myChart1) {
        //     this.myChart1.destroy(); // to destroy previously created chart
        // }
        // this.canvas1 = document.getElementById(htmlId);
        // this.ctx1 = this.canvas1.getContext('2d');
        // //@ts-ignore
        // this.myChart1 = new Chart(this.ctx1, {
        //     type: 'horizontalBar',
        //     data: {
        //         labels: keys,
        //         datasets: [
        //             {
        //                 label: 'Usage in MB',
        //                 data: values,
        //                 backgroundColor: [
        //                     '#0078be', '#00b3f0', '#6801af', '#bb64ff', '#ff560b', '#ffa608', '#298c00', '#a9ce21', '#58595b', '#939598'
        //                 ],
        //                 borderWidth: 1,
        //             },
        //         ],
        //     },
        //     options: {
        //         legend: {
        //             position: 'bottom',
        //             labels: {
        //                 fontSize: 12,
        //             },
        //             // @ts-ignore
        //             onClick: (e, legendItem, legend) => {
        //                 // Prevent the default action of hiding the dataset
        //                 e.stopPropagation();
        //             },
        //         },
        //         scales: {
        //             // @ts-ignore
        //             y: [
        //                 {
        //                     display: true,
        //                     scaleLabel: {
        //                         display: true,
        //                         labelString: 'Usage in MB', // Your X-axis title here
        //                     },
        //                 },
        //             ],
        //         },
        //         tooltips: {
        //             callbacks: {
        //                 label: (tooltipItems) => {
        //                     return tooltips[tooltipItems.index];
        //                 },
        //             },
        //             titleFontSize: 0,
        //             bodyFontSize: 12,
        //         },
        //         responsive: true,
        //         maintainAspectRatio: false,
        //     },
        // });
    }

    getTopApplications() {
        this.loading = true;
        this.isEmpty = false;
        const payload = {
            ip_addr: this.graphConfData.ip_addr,
            tenant_id: this.graphConfData.tenant_id,
            timeduration: this.graphConfData.timeduration,
            top_count: 10,
        };
        this.netflowService.getNetflowGraphs(this.netflowTypes['applications'], payload).subscribe(
            (data: any) => {
                this.loading = false;
                if (data.length < 1) {
                    this.isEmpty = true;
                } else {
                    this.isEmpty = false;
                    setTimeout(() => {
                        this.renderTopApplicationsChart(data, 'tenantGraph', 'port_name');
                    }, this.renderTime);
                }
            },
            (err) => {
                this.loading = false;
            },
        );
    }

    renderTopApplicationsChart(data: Array<any>, htmlId, key: string) {
        let values = [];
        let labels = [];
        let percentage = [];
        data.sort(function (a, b) {
            return b['total']['value'] - a['total']['value'];
        });

        data.forEach(data => {
            values.push({
                name: data[key],
                value: data['total']['value']
            })
            labels.push(data.port_name)
        })
        // keys = data.map((inp) => inp[key]);
        // values = data.map((inp) => inp['total']['value']);

        this.pieChartGraph = { data: values, labels: labels }

        // let total = values.reduce((val, acu) => val + acu);
        // percentage = values.map((inp) => ((inp / total) * 100).toPrecision(3));
        // for (const i in keys) {
        //     keys[i] = keys[i] + ' ' + percentage[i] + '%';
        // }
        // total /= 1000000;
        // total = Math.round(total);
        // this.sum = total.toString();
        // if (this.myChart) {
        //     this.myChart.destroy(); // to destroy previously created chart
        // }
        // this.canvas = document.getElementById(htmlId);
        // this.ctx = this.canvas.getContext('2d');

        // // @ts-ignore
        // this.myChart = new Chart(this.ctx, {
        //     type: 'doughnut',
        //     data: {
        //         labels: keys,
        //         datasets: [
        //             {
        //                 data: values,
        //                 backgroundColor: [
        //                     '#0078be', '#00b3f0', '#6801af', '#bb64ff', '#ff560b', '#ffa608', '#298c00', '#a9ce21', '#58595b', '#939598'
        //                 ],
        //                 borderWidth: 1,
        //             },
        //         ],
        //     },
        //     options: {
        //         cutoutPercentage: 60,
        //         legend: {
        //             position: 'right',
        //             labels: {
        //                 fontSize: 12,
        //             },
        //         },
        //         elements: {
        //             // @ts-ignore
        //             center: {
        //                 text: this.sum + ' MB',
        //             },
        //         },
        //         tooltips: {
        //             callbacks: {
        //                 label: (tooltipItems, data) => data.labels[tooltipItems.index] as string,
        //             },
        //             bodyFontSize: 12,
        //         },
        //         responsive: true,
        //         maintainAspectRatio: false,
        //     },
        // });
    }

    getTopProto() {
        this.loading = true;
        this.isEmpty = false;
        const payload = {
            ip_addr: this.graphConfData.ip_addr,
            tenant_id: this.graphConfData.tenant_id,
            timeduration: this.graphConfData.timeduration,
            top_count: 10,
        };
        this.netflowService.getNetflowGraphs(this.netflowTypes['protocols'], payload).subscribe(
            (data: any) => {
                this.loading = false;
                if (data.length < 1) {
                    this.isEmpty = true;
                } else {
                    this.isEmpty = false;
                    setTimeout(() => {
                        this.renderTopProtoChart(data, 'tenantGraph');
                    }, this.renderTime);
                }
            },
            (err) => {
                this.loading = false;
            },
        );
    }

    renderTopProtoChart(data: Array<any>, htmlId) {
        let labels = [];
        let values = [];
        let percentage = [];
        data.sort(function (a, b) {
            return b['total']['value'] - a['total']['value'];
        });

        data.forEach(data => {
            values.push({
                name: data.proto,
                value: data['total']['value']
            })
            labels.push(data.proto)
        })

        this.pieChartGraph = { data: values, labels: labels }
        // keys = data.map((inp) => inp.proto);
        // values = data.map((inp) => inp['total']['value']);

        // let total = values.reduce((val, acu) => val + acu);

        // percentage = values.map((inp) => ((inp / total) * 100).toPrecision(3));
        // for (const i in keys) {
        //     keys[i] = keys[i] + ' ' + percentage[i] + '%';
        // }
        // total /= 1000000;
        // total = Math.round(total);

        // if (this.myChart) {
        //     this.myChart.destroy(); // to destroy previously created chart
        // }

        // this.sum = total.toString();
        // this.canvas = document.getElementById(htmlId);
        // this.ctx = this.canvas.getContext('2d');

        // // @ts-ignore
        // this.myChart = new Chart(this.ctx, {
        //     type: 'doughnut',
        //     animation: {
        //         animateScale: true,
        //     },

        //     data: {
        //         labels: keys,
        //         datasets: [
        //             {
        //                 data: values,
        //                 backgroundColor: [
        //                     '#0078be', '#00b3f0', '#6801af', '#bb64ff', '#ff560b', '#ffa608', '#298c00', '#a9ce21', '#58595b', '#939598'
        //                 ],
        //                 borderWidth: 1,
        //             },
        //         ],
        //     },
        //     options: {
        //         cutoutPercentage: 60,
        //         elements: {
        //             // @ts-ignore
        //             center: {
        //                 text: this.sum + ' MB',
        //             },
        //         },
        //         legend: {
        //             position: 'right',
        //             labels: {
        //                 fontSize: 12,
        //             },
        //         },
        //         tooltips: {
        //             callbacks: {
        //                 label: (tooltipItems, data) => data.labels[tooltipItems.index] as string,
        //             },
        //             bodyFontSize: 12,
        //         },
        //         responsive: true,
        //         maintainAspectRatio: false,
        //     },
        // });
    }

    getTopPorts() {
        this.loading = true;
        this.isEmpty = false;
        const payload = {
            ip_addr: this.graphConfData.ip_addr,
            tenant_id: this.graphConfData.tenant_id,
            timeduration: this.graphConfData.timeduration,
            top_count: 10,
        };
        this.netflowService.getNetflowGraphs(this.netflowTypes['ports'], payload).subscribe(
            (data: any) => {
                this.loading = false;
                if (data.length < 1) {
                    this.isEmpty = true;
                } else {
                    this.isEmpty = false;
                    setTimeout(() => {
                        this.renderTopPortsChart(data, 'tenantGraph');
                    }, this.renderTime);
                }
            },
            (err) => {
                this.loading = false;
            },
        );
    }

    renderTopPortsChart(data: any, htmlId) {
        let keys = [];
        let values = [];
        let percentage = [];
        data.sort(function (a, b) {
            return b['total']['value'] - a['total']['value'];
        });
        keys = data.map((inp) => inp.key);
        values = data.map((inp) => inp['total']['value']);

        let total = values.reduce((val, acu) => val + acu);

        percentage = values.map((inp) => ((inp / total) * 100).toPrecision(3));
        for (const i in keys) {
            keys[i] = keys[i] + ' ' + percentage[i] + '%';
        }
        total /= 1000000;
        total = Math.round(total);

        if (this.myChart) {
            this.myChart.destroy(); // to destroy previously created chart
        }

        this.sum = total.toString();
        this.canvas = document.getElementById(htmlId);
        this.ctx = this.canvas.getContext('2d');

        // @ts-ignore
        this.myChart = new Chart(this.ctx, {
            type: 'doughnut',
            animation: {
                animateScale: true,
            },

            data: {
                labels: keys,
                datasets: [
                    {
                        data: values,
                        backgroundColor: [
                            '#0078be', '#00b3f0', '#6801af', '#bb64ff', '#ff560b', '#ffa608', '#298c00', '#a9ce21', '#58595b', '#939598'
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                cutoutPercentage: 60,
                elements: {
                    // @ts-ignore
                    center: {
                        text: this.sum + ' MB',
                    },
                },
                legend: {
                    position: 'right',
                    labels: {
                        fontSize: 12,
                    },
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItems, data) => data.labels[tooltipItems.index] as string,
                    },
                    bodyFontSize: 12,
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });
    }
}
