import { Component } from '@angular/core';

@Component({
    selector: 'cosgrid-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent {
    constructor() { }
}
