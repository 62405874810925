import * as _ from 'lodash';
import * as DEVICE_ACTIONS from 'app/store/actions/device.actions';
import { DeviceList } from 'app/shared/model/device.list.model';
import { encryptStorage } from 'app/core/services/local-storage.service';

export interface DeviceSlice {
    listOfDevices?: DeviceList[];
    selectedDevice?: DeviceList;
}

const initialState: DeviceSlice = {
    listOfDevices: [],
    selectedDevice: {},
};

try {
    initialState.selectedDevice = encryptStorage.getItem('selectedDevice');

    initialState.listOfDevices = encryptStorage.getItem('listOfDevices');
} catch {}

export function reducer(state = initialState, action: DEVICE_ACTIONS.Actions): DeviceSlice {
    switch (action.type) {
        case DEVICE_ACTIONS.SetDevice: {
            initialState.selectedDevice = action.devices;

            // localStorage.setItem('selectedDevice', JSON.stringify(initialState.selectedDevice));
            encryptStorage.setItem('selectedDevice', JSON.stringify(initialState.selectedDevice));

            return initialState;
        }
        case DEVICE_ACTIONS.SetListOfDevices: {
            initialState.listOfDevices = action.listOfDevices;
            // localStorage.setItem('listOfDevices', JSON.stringify(initialState.listOfDevices));
            encryptStorage.setItem('listOfDevices', JSON.stringify(initialState.listOfDevices));

            return initialState;
        }
        default:
            return initialState;
    }
}
