import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, AfterViewInit } from '@angular/core';
import { AlertService } from 'app/shared/services/misc';
import { ValidatePort } from 'app/shared/validators/portValidator';
import { IPV4 } from 'app/configs/constants';
declare var $: any;

@Component({
    selector: 'cosgrid-ip-multi',
    templateUrl: './ip-multi.component.html',
    styleUrls: ['./ip-multi.component.scss'],
})
export class IpMultiComponent implements OnInit, AfterViewInit {
    @Output() updateDataAndValidity = new EventEmitter<DataAndValidity>(); // pushing the data and validity status eveerytime a change is detected
    @Input() setValidator: boolean; // setting whether it is required or not
    @Input() isSubnet = true;
    @Input() triggerTouched: EventEmitter<boolean>; // if add button is pressed in the parent componenet..if inp-multi is required inp multi should display red border
    @Input() editData: string[] = []; // the editdata for the componenet is pushed in this
    @Input() isPort: boolean; // this componenet is used for both ports and ips , so this is used as a flag to toggle validation
    @Input() noPatternValidation: boolean; // dont do any pattern validation, user can enter anything
    @Input() uniqueName: string; // if more then one ip-multi comopnenets are used in a same parent component ..paste event gets triggered in both even if it happens in only one componenet, so a unique id is used as id to listen for events
    @Input() inpDisabled = false;
    @Input() isDisabled = false;
    @Input() width = '10';
    @Input() placeholder: string = '';

    URLRegex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/);

    IPV4MASKRegex = new RegExp(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/);
    PORTRegex = new RegExp(/^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/);

    highlightInvalidBlocks = true;
    theme = 'material';
    mode = 'ipv4';
    separatorKeysCodes = [ENTER, COMMA, SPACE];
    requiredValidator: boolean;

    DataAndValidity: DataAndValidity = {
        data: [],
        validityStatus: false,
    };

    form = this.fb.group({
        data: [[], [Validators.pattern(IPV4)]],
    });

    constructor(private alert: AlertService, private fb: UntypedFormBuilder) { }

    touchRef() {
        this.form.controls.data.markAsTouched();
    }

    ngOnInit() {
        if (this.isDisabled) {
            this.form.controls.data.disable();
        }
        this.updatePatternValidation();
        this.DataAndValidity.data = this.editData; // if the user edits the componenet, autopopulate the data
        this.form.valueChanges.subscribe((res) => {
            this.updateRequiredValiator(); // update the required validator to update the values used in second condition
        });
        this.updateRequiredValiator();
        if (this.triggerTouched) {
            this.triggerTouched.subscribe(() => {
                this.updateRequiredValiator();
                this.touchRef();
            });
        }
    }

    updatePatternValidation() {
        if (this.isPort) {
            this.form.controls.data.clearValidators();
            this.form.controls.data.updateValueAndValidity();
            this.form.controls.data.setValidators(ValidatePort);
            this.form.controls.data.updateValueAndValidity();
        } else if (!this.isSubnet) {
            this.form.controls.data.clearValidators();
            this.form.controls.data.updateValueAndValidity();
            this.form.controls.data.setValidators(Validators.pattern(IPV4));
            this.form.controls.data.updateValueAndValidity();
        } else if (this.noPatternValidation) {
            this.form.controls.data.clearValidators();
            this.form.controls.data.updateValueAndValidity();
        } else {
            this.form.controls.data.clearValidators();
            this.form.controls.data.updateValueAndValidity();
            this.form.controls.data.setValidators(Validators.pattern(this.IPV4MASKRegex));
            this.form.controls.data.updateValueAndValidity();
        }
    }

    updateRequiredValiator() {
        if (this.setValidator) {
            this.requiredValidator = this.DataAndValidity.data.length < 1 && this.form.controls.data.valid; // set the required validator to false if the length of the ipArray is more than 0 or the entered ip is valid ..the second condition is added because the first condition`s validation triggers only if the ip is pushed in iparray
        } else {
            this.requiredValidator = false; // always true if validators is not required
        }
    }

    ngAfterViewInit() {
        $('#' + this.uniqueName).on('paste', (e) => {
            e.preventDefault();
            const pastedData = e.originalEvent.clipboardData.getData('text');

            this.sanitizeInput(pastedData); // if ips are pasted on bulk sanitize the input as a array and push it coz angular material by default wont handle this
        });
    }

    onPaste(e) {
        e.preventDefault();
        const pastedData = e.originalEvent.clipboardData.getData('text');

        this.sanitizeInput(pastedData); // if ips are pasted on bulk sanitize the input as a array and push it coz angular material by default wont handle this
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            this.setValidator = changes.setValidator.currentValue; // if the validator changes in the parent componenet update the validator
        } catch (error) { }
        try {
            this.requiredValidator = changes.requiredValidator.currentValue;
        } catch (err) { }
        try {
            this.DataAndValidity.data = changes.editData.currentValue;
        } catch (error) { }
        try {
            this.isSubnet = changes.isSubnet.currentValue;
            this.updatePatternValidation();
        } catch (err) { }
        try {
            this.noPatternValidation = changes.noPatternValidation.currentValue; // if the pattern validator changes
            this.updatePatternValidation();
        } catch (err) { }

        try {
            this.inpDisabled = changes.inpDisabled.currentValue;
        } catch (err) { }
    }

    clearAll() {
        this.DataAndValidity.data = [];
    }

    add_item(event: MatChipInputEvent) {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            if (this.isPort) {
                if (!ValidatePort(this.form.get('data'))) {
                    this.DataAndValidity.data.push(value);
                    this.updateRequiredValiator();
                    this.updateDataAndValidity.emit(this.DataAndValidity);
                } else {
                    this.alert.snack('Please Enter a valid port or port range', 'OK', '2000');
                    this.form.controls.data.setValue('');
                }
            } else if (this.noPatternValidation) {
                this.DataAndValidity.data.push(value);
                this.updateRequiredValiator();
                this.updateDataAndValidity.emit(this.DataAndValidity);
            } else {
                if (this.form.valid) {
                    this.DataAndValidity.data.push(value);
                    this.updateRequiredValiator();
                    this.updateDataAndValidity.emit(this.DataAndValidity);
                } else {
                    this.alert.snack('Please enter a valid IP', 'OK', '2000');
                    this.form.controls.data.setValue('');
                }
            }
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove_item(ev: any) {
        const index = this.DataAndValidity.data.indexOf(ev);
        if (index >= 0) {
            this.DataAndValidity.data.splice(index, 1);
            this.updateRequiredValiator();
            this.updateDataAndValidity.emit(this.DataAndValidity);
        }
        if (this.DataAndValidity.data.length < 1) {
            this.form.controls.data.setValue('');
        }
    }

    sanitizeInput(text: string) {
        // *********TODO: use single replace for all specifiers using array****/
        text = text.replace(';', ',');
        text = text.replace(' ', ',');
        text = text.replace(/\n/gi, ',');

        let finalArray = text.split(',');

        finalArray = finalArray.map((item) => item.trim()).filter((item) => item != '');
        // this.DataAndValidity.data = JSON.parse(JSON.stringify([...this.DataAndValidity.data, ...finalArray]))
        const arrayLength = finalArray.length;
        let wrongIps = 0;
        for (let i = 0; i < arrayLength; i++) {
            if (this.IPV4MASKRegex.test(finalArray[i])) {
                this.DataAndValidity.data.push(finalArray[i]);
            } else {
                wrongIps++;
            }
        }
        // document.getElementById('input').blur() // for some reason it shows all the pasted text in input box until its blurred
        if (wrongIps > 1) {
            this.alert.snack(
                `You Have Pasted ${wrongIps} Invalid IPs and ${finalArray.length - wrongIps} Valid IPs`,
                'OK',
            );
        } else {
            this.alert.snack(`You Have Pasted ${finalArray.length} ${finalArray.length === 1 ? 'IP' : 'IPs'}`, 'OK');
        }
        this.updateDataAndValidity.emit(this.DataAndValidity);
        this.updateRequiredValiator();
    }
}

interface DataAndValidity {
    data: string[];
    validityStatus: boolean;
}
