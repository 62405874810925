<div *ngIf="show" class=" {{ className}}" style="width: 100%; height: 100%;">
    <div *ngIf="tableLoading">
        <div *ngIf="toolboxAvailable" class="d-flex justify-content-between" style="margin-right: 1%;">
            <div *ngIf="searchLoading" style="width: 25%;">
                <div class="loader-line"></div>
            </div>
            <div *ngIf="noOfToolboxOptions > 0" class="d-flex justify-content-end">
                <div *ngFor="let item of [].constructor(noOfToolboxOptions); let i = index" class="loader-line mr-1"
                    style="width: 2rem !important">
                </div>
            </div>
        </div>
        <div class="loader-line " style="height: 60px ;"></div>
        <div class="loader-line"></div>
        <div class="loader-line"></div>
        <div class="loader-line"></div>
        <div class="loader-line"></div>
        <div class="loader-line"></div>
    </div>

    <div *ngIf="cardLoading" style="width: 98%; height: 98%;">
        <div *ngIf="plainCard" style="width: 98%; height: 98%;">
            <div class="card-loader" style="width: 100%; height: 100%;"></div>
        </div>
        <div *ngIf="!plainCard">
            <div *ngIf="cardHeader" class="mb-2">
                <div class="loader-line " style="height: 60px ;"></div>
            </div>
            <div class="row" *ngFor="let item of [].constructor(cardRows)">
                <div class="col" *ngFor="let item of [].constructor(cardCols)">
                    <div class="loader-line" style="height: 20px ;border-radius: 50px"></div>
                </div>
            </div>
        </div>
    </div>
</div>