import { IavailableDashboard } from "app/shared/model/availableDashboard.model";
import { AVAILABLE_DASHBOARD_ACTION, availableDashboardAction } from "app/store/actions/availableDashboard.actions";

export interface AvailableDashboard {
    dashboardSettings: IavailableDashboard
}

export const initialState: AvailableDashboard = {
    dashboardSettings: {
        dashboardFromPreference: false,
        currentDashboard: '',
        dashboardIcon: ''
    }

}

export function reducer(state = initialState, action: availableDashboardAction) {
    switch (action.type) {
        case AVAILABLE_DASHBOARD_ACTION:
            return {
                ...state,
                dashboardSettings: action.payload
            };
        default:
            return state
    }
}