import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedCommonModule } from 'app/shared/shared.module';
import { AnnouncementsComponent } from 'app/core/components/announcements/announcements.component';
import { HeaderComponent } from 'app/core/components/header/header.component';
import { MenuComponent } from 'app/core/components/menu/menu.component';
import { SearchFeaturesComponent } from 'app/core/components/header/components/search-features/search-features.component';
import { ProfileComponent } from 'app/modules/organization/organization.all';
import { SupportComponent } from 'app/core/components/header/components/support/support.component';
import { ChangePasswordComponent } from 'app/modules/settings/components/profile/change-password/change-password.component';
import { EditProfileComponent } from 'app/modules/settings/components/profile/edit-profile/edit-profile.component';
import { TicketRaiseComponent } from 'app/core/components/header/components/support/components/ticket-raise/ticket-raise.component';
import { TrackTicketStatusComponent } from 'app/core/components/header/components/support/components/track-ticket-status/track-ticket-status.component';
import { EditViewIssueComponent } from 'app/core/components/header/components/support/components/track-ticket-status/edit-view-issue/edit-view-issue.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { SuperAdminAuthGuard } from 'app/modules/super-admin/guards/super-admin.guard';
import { JoyrideModule } from 'ngx-joyride';
import { TenantSettingsComponent } from '../modules/settings/components/tenant-settings/tenant-settings.component';
import { SelectTenantComponent } from './components/header/components/select-tenant/select-tenant.component';
import { SelectNetworkComponent } from './components/header/components/select-network/select-network.component';
import { GetMicroZaccessComponent } from './components/get-micro-zaccess/get-micro-zaccess.component';

@NgModule({
    declarations: [
        AnnouncementsComponent,
        HeaderComponent,

        MenuComponent,
        SearchFeaturesComponent,
        ProfileComponent,
        ChangePasswordComponent,
        EditProfileComponent,
        SupportComponent,
        TicketRaiseComponent,
        TrackTicketStatusComponent,
        EditViewIssueComponent,
        TenantSettingsComponent,
        SelectTenantComponent,
        SelectNetworkComponent,
        GetMicroZaccessComponent,
    ],
    imports: [CommonModule, SharedCommonModule, RouterModule, JoyrideModule.forChild()],
    exports: [
        AnnouncementsComponent,
        HeaderComponent,
        MenuComponent,
        SearchFeaturesComponent,
        ProfileComponent,
        ChangePasswordComponent,
        EditProfileComponent,
        SupportComponent,
        TicketRaiseComponent,
        TrackTicketStatusComponent,
        TenantSettingsComponent,
        EditViewIssueComponent,
    ],
    providers: [AuthGuard, SuperAdminAuthGuard],
})
export class CoreModule {}
