import * as _ from 'lodash';
import * as DASHBOARD from 'app/store/actions/dashboard.actions';
import { DashboardSlice, initialDashboardState } from 'app/store/state/dashboard.state';

export function reducer(state = initialDashboardState, action: DASHBOARD.Actions): DashboardSlice {
    switch (action.type) {
        case DASHBOARD.SetFilter: {
            return {
                deviceFilter: action.filter,
            };
        }
        default:
            return state;
    }
}
